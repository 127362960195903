import * as React from 'react';
import styled from 'styled-components';
import { Formik, Field, Form, FormikHelpers as FormikActions, useFormikContext } from 'formik';
import * as R from 'ramda';

import { colors } from '../shared/themeStyles';
import Button from '../components/form/Button';

import { SteelValuesSFE } from '../types/Calculation';

const ContentWrapper = styled.div`
  flex-basis: 32.6%; /* 313/960 */
  padding: 1.5rem 1rem;
  background-color: ${colors.backgroundDarker};
`;

const SectionTitle = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const ProductTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 2.5rem;
  width: auto;
  text-align: center;
  border-bottom: 2px solid lightgray;
`;

const ButtonWithMargin = styled(Button)`
  margin-bottom: 1rem;
`;

const SubText = styled.span`
  text-transform: uppercase;
  display: block;
  font-size: 9px;
  margin-bottom: 1rem;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  > label {
    font-size: 14px;
    text-align: right;
    margin-right: 1rem;
  }

  > input {
    width: 60px;
    height: 32px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }

  > span {
    margin-left: 5px;
    color: ${colors.accentDark};
  }
`;

const PrimaryInputWrapper = styled(InputWrapper as any)`
  flex: 1 0 100%;

  > label {
    flex-basis: 13%;
  }
`;

const SecondaryInputWrapper = styled(InputWrapper as any)`
  flex: 1 0 50%;

  > label {
    flex-basis: 26%;
  }
`;

const ModalCloseIcon=styled.span`
    cursor: pointer;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    float:right;
    opacity:0.5;
   margin: -15px 15px -15px 15px;
    &:hover,
    &:focus {
    opacity:0.75;
  }
`
const ModalHeader=styled.div`
    border-bottom: 1px solid #e5e5e5;
    padding: 5px;
`

const ModalBody=styled.div`
padding: 15px;
`
const ModalFooter=styled.div`
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
`
const ModalBox = styled.div`

position: fixed;
z-index: 1;
padding-top: 100px;
left: 0;
top: 0;
width: 100%; 
height: 100%; 
overflow: auto; 
background-color: rgb(0,0,0); 
background-color: rgba(0,0,0,0.4); 
transition: opacity .15s linear;
opacity: 1;
`;

const ModalContent = styled.div`
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  background-color: #fefefe;
  border: 1px solid #888;
  margin: auto;
  width: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
`;

const ModalFooterButton=styled.div`
    margin-left: 10px;
    float: right;
    width: 140px;
`

const SelectWrapper = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
    margin-right: 1rem;

  > select {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > input {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > button {
    margin: 0px auto;
    width: 130px;
  }
`;


interface FormValues extends SteelValuesSFE {
  save_name:string;
}

const steelValues: SteelValuesSFE = {
  Al: 0,
  C: 0,
  Co: 0,
  Cr: 0,
  Cu: 0,
  Mn: 0,
  Mo: 0,
  N: 0,
  Nb: 0,
  Ni: 0,
  P: 0,
  S: 0,
  Si: 0,
  Ti: 0,
  V: 0,
  W: 0
};

const initialValues: FormValues = {
  ...steelValues,
  save_name: ''
};

const primaryElements: string[] = ['C', 'Mn', 'Si', 'Cr', 'Ni'];

const secondaryElements: string[] = [
  'Mo',
  'V',
  'Co',
  'Al',
  'W',
  'Cu',
  'Nb',
  'Ti',
  'N',
  'P',
  'S'
];

const renderElementInput = (element: string) => (
  <>
    <label htmlFor={element}>{element}: </label>
    <Field
      id={element}
      name={element}
      type="number"
      min="0"
      step=".01"
      autoComplete="off"
    />{' '}
    <span>%</span>
  </>
);

const renderPrimaryElementInput = (element: string) => (
  <PrimaryInputWrapper key={element}>
    {renderElementInput(element)}
  </PrimaryInputWrapper>
);

const renderSecondaryElementInput = (element: string) => (
  <SecondaryInputWrapper key={element}>
    {renderElementInput(element)}
  </SecondaryInputWrapper>
);

interface Props {
  onSubmitForm: (steelValues: SteelValuesSFE) => Promise<any>;
  fetching: boolean;
}

interface State {
  displaySecondaryelements: boolean;
  valuesToLoad:FormValues;
  showSaveModal: boolean;
  showRemoveModal: boolean;
}

class ChemicalComposition extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {valuesToLoad:initialValues,showSaveModal:false, showRemoveModal:false, displaySecondaryelements: false };
  }

  
  removeStorage = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    localStorage.clear();
  };

  StoreDataArea = () => {

    const { values } = useFormikContext();

    // const setValuesToLoad = (event: React.MouseEvent<HTMLElement>) => {
    //   this.setState({valuesToLoad:savedValues})
      
    // };

  return(
    <>
<SelectWrapper>
     {this.getStorageList()}
     </SelectWrapper>  
     <SelectWrapper>
<ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>{
                  event.preventDefault();
                  this.setState({showRemoveModal: true}) 

                }
                } secondary>
                Remove all
              </ButtonWithMargin>
              </SelectWrapper>

<SelectWrapper>
                  
                  <Field
                    id="save_name"
                    name="save_name"
                    type="text"
                    autoComplete="off"
                    />
                </SelectWrapper>
                <SelectWrapper>
<ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>{
                  event.preventDefault()
                  localStorage.setItem('sfe_'+ (values as FormValues).save_name, JSON.stringify(values))
                  this.setState({showSaveModal: true})
        }
                } secondary>
                Save
              </ButtonWithMargin>
              </SelectWrapper>
              </>
  )
}


  toggleSecondaryElementsDisplay = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({
      displaySecondaryelements: !this.state.displaySecondaryelements
    });
  };

  resetInputs = (event: React.MouseEvent<HTMLElement>) => {
    window.location.reload();
  };

  handleChange = (e: { currentTarget: { value: FormValues; }; }) => {
    
    const anytype = 'sfe_' + e.currentTarget.value; 
    console.log(anytype);
    
    const theValue: string | null  = localStorage.getItem(anytype);
    let parsedValue = null;

    if (theValue !== null) {
    parsedValue= JSON.parse(theValue) as FormValues;
    }
    console.log(parsedValue);
    if (parsedValue !== null) {
      this.setState({valuesToLoad:parsedValue});
      }
  }
  
  getStorageList = () => {
  const storageList= [];
  const keys = Object.keys(localStorage);
  let i = keys.length;
  const substring = 'sfe_';
  
  while ( i-- ) {
    if(keys[i].includes(substring)){
    storageList.push( keys[i].replace('sfe_', '') );
    }
  }

  const selectList = storageList.map((ele, index) => <option key={index} value={ele}>{ele}</option>)  

  
  return (<>
  <Field as="select" name="simulations" onChange={ 

    this.handleChange}> <option selected disabled>Select</option> {selectList} </Field>
  </>
  )
   
}


hideSaveModal= (event: React.MouseEvent<HTMLElement>) => {
  this.setState({showSaveModal:false})
}
hideRemoveModal= (event: React.MouseEvent<HTMLElement>) => {
  this.setState({showRemoveModal:false})
}

  render() {
    const {  valuesToLoad,displaySecondaryelements, showSaveModal, showRemoveModal  } = this.state;
    const { onSubmitForm, fetching } = this.props;

    return (
      <ContentWrapper>
        <ProductTitle>Stacking Fault Energy</ProductTitle>
        <SectionTitle>Chemical Composition</SectionTitle>
        <SubText>Weight percentages</SubText>
        <Formik
                     enableReinitialize={true}
                     initialValues={valuesToLoad}
          onSubmit={(
            values: SteelValuesSFE,
            { setSubmitting }: FormikActions<SteelValuesSFE>
          ) => {
            // any null values (empty strings) will be converted to 0
            const nullsToZero = (val: any) => (!val ? 0 : val);
            // @ts-ignore
            let formattedValues:SteelValuesArbCR = R.map(nullsToZero, values);
            formattedValues = R.omit(['save_name' ], formattedValues);

            onSubmitForm(formattedValues).then(() => {
              setSubmitting(false);
            });
          }}
          render={() => (
            <Form>
              <InputsWrapper>
                {primaryElements.map(ele => renderPrimaryElementInput(ele))}
              </InputsWrapper>
              <ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.toggleSecondaryElementsDisplay(event)
                }
                secondary
              >
                {displaySecondaryelements
                  ? 'Hide Elements'
                  : 'View All Elements'}
              </ButtonWithMargin>
              {displaySecondaryelements && (
                <InputsWrapper>
                  {secondaryElements.map(ele =>
                    renderSecondaryElementInput(ele)
                  )}
                </InputsWrapper>
              )}
<this.StoreDataArea />
              <Button disabled={fetching}>
                {fetching ? 'Loading...' : 'Simulate'}
              </Button>
              <InputsWrapper className="mb-5 mt-2">
<ButtonWithMargin 
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.resetInputs(event)
                } secondary>
                Clear inputs
              </ButtonWithMargin>

              </InputsWrapper>
            </Form>
          )}
        />
        
<ModalBox style={showSaveModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>Your simulation was saved<ModalCloseIcon onClick={this.hideSaveModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>Your simulation has been saved to your browser cache. Please note that it has not been saved to central database and that it will me removed upon browser cache clean. </ModalBody>
    <ModalFooter>
      <Button secondary={true} width={'100px'} float={'right'} onClick={this.hideSaveModal}>Close</Button>
    </ModalFooter>              
  </ModalContent>
</ModalBox>
<ModalBox style={showRemoveModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>Do you want to remove all saved simulations?<ModalCloseIcon onClick={this.hideRemoveModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>Do you want to remove all locally saved simulations from you browser storage? </ModalBody>
    <ModalFooter>
    <ModalFooterButton>
      <Button secondary={true} width={'120px'} float={'right'} onClick={this.hideRemoveModal}>Cancel</Button>
      </ModalFooterButton>
      
      <Button  secondary={true} width={'120px'} float={'right'} onClick={
        (event: React.MouseEvent<HTMLElement>) =>{
          event.preventDefault();
          const keys = Object.keys(localStorage);
  let i = keys.length;
  const substring = 'sfe_';
  
  while ( i-- ) {
    if(keys[i].includes(substring)){
    localStorage.removeItem( keys[i]);
    }
  }
          this.setState({showRemoveModal:false})
        }
        }>Remove</Button>            
      
    </ModalFooter>              
  </ModalContent>
</ModalBox>
      </ContentWrapper>
    );
  }
}

export default ChemicalComposition;
