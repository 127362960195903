import React, { useState } from "react";
import Spinner from 'react-spinkit';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';
import { SingleCalculationResultsCCTArbCR } from '../types/Calculation';

import {DataEditor, GridColumn, GridCellKind, Item, GridCell, EditableGridCell,HeaderClickedEventArgs} from "@glideapps/glide-data-grid";
import * as Plotly from 'plotly.js';



const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const InputsWrapper = styled.div`
  flex-wrap: wrap;
`;


const ModalCloseIcon=styled.span`
    cursor: pointer;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    float:right;
    opacity:0.5;
   margin: -15px 15px -15px 15px;
    &:hover,
    &:focus {
    opacity:0.75;
  }
`
const ModalHeader=styled.div`
    border-bottom: 1px solid #e5e5e5;
    padding: 5px;
`


const ModalBody=styled.div`
padding: 15px;
`
const ModalFooter=styled.div`
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
`



const ModalBox = styled.div`

position: fixed;
z-index: 1;
padding-top: 100px;
left: 0;
top: 0;
width: 100%; 
height: 100%; 
overflow: auto; 
background-color: rgb(0,0,0); 
background-color: rgba(0,0,0,0.4); 
transition: opacity .15s linear;
opacity: 1;
`;

const ModalContent = styled.div`
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  background-color: #fefefe;
  border: 1px solid #888;
  margin: auto;
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 64px;

  h3 {
    min-height: 14px;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 14px;
  }
`;

const SelectWrapper = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
    margin-right: 1rem;

  > select {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > input {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > button {
    margin: 0px auto;
    width: 130px;
  }
`;

const SectionTitle = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  width: 100%;
`;

const ResultStatus = styled.div`
  font-size: 40px;
`;



interface Props {
  fetching: boolean;
  steelValues:any;
  results: SingleCalculationResultsCCTArbCR[] | null;
}


const renameKeys = (obj:any, newKeys:any) => {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

const renderContent = (props: Props) => {

  let gridData:any[] = [];
  let dataGroups:any[] = [];
  const newKeys = { A: "A%", F: "F%", P: "P%", B: "B%", M: "M%" };

  if (props.results && !props.fetching) {
    let counter = 0;
    let group = 0;
    
    props.results.forEach((element,i) => {
      group = group +1;
      let compositionData =props.steelValues[i];
      
      
      delete compositionData.hardness_option;
      delete compositionData.crs;
      delete compositionData.at;
      delete compositionData.average_option;
      delete compositionData.crs_end;
      delete compositionData.crs_start;
      delete compositionData.hardenability_option;
      delete compositionData.weights;
      
      let tempGroup:any[] = [];
        element.Result.cooling_curves.forEach((curves,j) => {
          let ac1Obj ={"Ac1":Math.round(curves.Ac1*100)/100};
          let ac3Obj ={"Ac3":Math.round(curves.Ac3*100)/100};
          counter = counter +1;
          let cr = curves.CR;

          let idObj ={"Id":counter};
          let steelObj ={"Steel":group};
          let crObj = {"CR":cr};
          let times = curves.tempsObj;
          let hardObj = {"Hardness":Math.round(element.Result.hardness[j]*100)/100};
          let percentages = curves.perc;
          let renamedPercs = renameKeys(percentages, newKeys);

          let rowData = {...steelObj,...idObj, ...compositionData, ...crObj,...ac1Obj, ...ac3Obj, ...times, ...renamedPercs, ...hardObj };
          gridData.push(rowData);
          
          tempGroup.push(rowData);
        })


    dataGroups.push(tempGroup);
        
    })
    
    }

  const getGrid = () => {


  
  const columns: GridColumn[] = [
    {
      title: "Steel",
      id: "Steel",
      group: "Steel", 
    hasMenu: false,
    width: 100
  },
    {
      title: "Id",
      id: "Id",
      group: "Steel", 
    hasMenu: false,
    width: 100
  },
    {
      title: "Al",
      id: "Al",
      group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
      title: "B",
      id: "B",
      group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
      title: "C",
      id: "C",
      group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
      title: "Co",
      id: "Co",
      group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
    {
      title: "Cr",
      id: "Cr",
      group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
      title: "Cu",
      id: "Cu",
      group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
      title: "Mn",
      id: "Mn",
      group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
      title: "Mo",
      id: "Mo",
      group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title:"N",
    id: "N",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title:"Nb",
    id: "Nb",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title:"Ni",
    id: "Ni",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title:"P",
    id: "P",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title:"S",
    id: "S",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title:"Si",
    id: "Si",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title:"Ti",
    id: "Ti",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title: "V",
    id: "V",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title: "W",
    id: "W",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title: "CR",
    id: "CR",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title: "Aus_temp",
    id: "aus_temp",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title: "Aus_time",
    id: "aus_time",
    group: "Input (Temperature in °C and time in sec)", 
    hasMenu: false,
    width: 100
  },
  {
    title: "Ac1",
    id: "Ac1",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "Ac3",
    id: "Ac3",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "Fe_s",
    id: "F_s",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "Fe_f",
    id: "F_f",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "P_s",
    id: "P_s",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "P_f",
    id: "P_f",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "B_s",
    id: "B_s",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "B_f",
    id: "B_f",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "M_s",
    id: "M_s",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "A%",
    id: "A%",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "F%",
    id: "F%",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "P%",
    id: "P%",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "B%",
    id: "B%",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "M%",
    id: "M%",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  },
  {
    title: "Hardness (HV)",
    id: "Hardness",
    group: "Output (Temperatures in °C)",
    hasMenu: false,
    width: 100
  }
  ]
  
  
  
  
  const initialIndexes: string[] = ["Steel","Id","Al", "B", "C", "Co", "Cr", "Cu", "Mn", "Mo","N", "Nb", "Ni", "P", "S", "Si", "Ti", "V", "W","CR", "aus_temp", "aus_time", "Ac1", "Ac3", "Fe_s", "Fe_f", "P_s", "P_f",  "B_s", "B_f", "M_s", "A%", "F%",  "P%", "B%", "M%", "Hardness"];
  const [sortableCols, setSortableCols] = useState(columns);
  const [indexes] = useState(initialIndexes);
  let [isAcending] = useState<boolean>(true);
 
  const [data, setData] = useState<any>(gridData); 
  const [groupData] = useState<any>(dataGroups); 
  const [plotGroup, setPlotGroup] = useState<boolean>(false); 
  const [showModal, setshowModal] = useState<boolean>(false); 
  


  const getContent = React.useCallback((cell: Item): GridCell => {
  const [col, row] = cell;
  const dataRow = data[row];
  const d = dataRow[indexes[col]]
  
  return {
      kind: GridCellKind.Number,
      allowOverlay: true,
      readonly: false,
      displayData: d.toString(),
    data: d,
  };
  }, []);
  
  const hideModal= (event: React.MouseEvent<HTMLElement>) => {
    setshowModal(false)
  }

  const getCellContentMangled = React.useCallback(
    ([col, row]: Item): GridCell => {
      const remappedCol = columns.findIndex(
        (c) => c.title === sortableCols[col].title
      );
      if (remappedCol === -1) {
        
      }
      return getContent([remappedCol, row]);
    },
    [getContent, sortableCols]
  );

  const onCellEdited = React.useCallback(
    (cell: Item, newValue: EditableGridCell) => {
      if (newValue.kind !== GridCellKind.Number) {
        // we only have text cells, might as well just die here.
        return;
      }

      const [col, row] = cell;
      const key = indexes[col];
      data[row][key] = newValue.data;
      setData(data);
    },
    [data, indexes]
  );

  

  const onColMoved = React.useCallback(
    (startIndex: number, endIndex: number): void => {
      setSortableCols((old) => {
        const newCols = [...old];
        const [toMove] = newCols.splice(startIndex, 1);
        newCols.splice(endIndex, 0, toMove);
        return newCols;
      });
    },
    []
  );

  

const getColList = () => {

  const selectList = indexes.map((ele, index) => <option key={ele} value={ele}>{ele}</option>)  

  return (selectList
  )
   
}



const onHeaderClicked = React.useCallback((col: number, event: HeaderClickedEventArgs) => {
    
    let colNameToSort = indexes[col];
    if(isAcending){ 
    data.sort((a:any, b:any) => (a[colNameToSort] > b[colNameToSort]) ? 1 : -1);
    isAcending = false;
    }else{
      data.sort((a:any, b:any) => (a[colNameToSort] > b[colNameToSort]) ? -1 : 1);
      isAcending = true;
    }
    
    
}, []);
  


  return (<>


<InputsWrapper className="mb-5 mt-2">
<SelectWrapper>
<select name="xplot" id="xplot"> <option selected disabled>Select X</option> {getColList()} </select>
     </SelectWrapper>  
<SelectWrapper>
<select name="yplot" id="yplot"> <option selected disabled>Select Y</option> {getColList()} </select>
     </SelectWrapper>  
     <SelectWrapper>

    


     <button id="greybutton"
                onClick={(event: React.MouseEvent<HTMLElement>) =>{
                  event.preventDefault();
                  let x:string = (document.getElementById("xplot") as HTMLSelectElement).value;
                  let y:string = (document.getElementById("yplot") as HTMLSelectElement).value;

                  var xArray:[number] = data.map((d: any) => d[x]);
                  var yArray:[number] = data.map((d: any) => d[y]);
                  
                 
let plotdata: any[];

if(plotGroup){
plotdata = [];

groupData.forEach((element:any, i:any) => {
  let xArray:[number] = element.map((d: any) => d[x]);
  let yArray:[number] = element.map((d: any) => d[y]);
  let index = i +1;
  let trace = {
    x: xArray,
    y: yArray,
    type: 'scatter',
    name: 'Steel '+index
  };
plotdata.push(trace);
});


}else{

plotdata = [{
  x:xArray,
  y:yArray,
  mode:"markers"
}];
}
// Define Layout
var layout = {
  xaxis: { title: x},
  yaxis: { title: y},  
  title: x+" vs. "+y,
  
};

// Display using Plotly
  Plotly.newPlot("myDiv", plotdata, layout);

                  setshowModal( true) 

                }
                } >
                Plot
              </button>
              </SelectWrapper>



              </InputsWrapper>
              <InputsWrapper className="mt-3">
              <input
              type="checkbox" 
              name="subscribe" 
              id="subscribe"
              
              onChange={() => setPlotGroup( !plotGroup )}
              checked={plotGroup}
        />
        <label className="mt-3">Plot steel as group</label>
        </InputsWrapper>

  <DataEditor freezeColumns={2}  onCellEdited={onCellEdited} getCellContent={getCellContentMangled} onHeaderClicked={onHeaderClicked}
  onColumnMoved={onColMoved} getCellsForSelection={true} onPaste={true} columns={sortableCols} keybindings={{search: true}} rows={data.length} />
       


  <ContentWrapper>
  <ModalBox style={showModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>CCT Data Plot<ModalCloseIcon onClick={hideModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>
    <div id="myDiv"></div>
     </ModalBody>
    <ModalFooter>
    <button id="greybutton"  onClick={hideModal}>Close</button>
    </ModalFooter>              
  </ModalContent>
</ModalBox>
    </ContentWrapper>      
  </>

  
  )
   
  }
  
  
  if (props.results && !props.fetching) {
     
    return (
      <>
      <div className="row">
        <div className="col-md-12 col-sm-12"><SectionTitle>Simulation Output</SectionTitle></div>
  </div>

  <div className="row">

  {getGrid()}

  
    {/* <div className="col-md-12 col-sm-12 mt-md-3">
     
     </div> */}
   

   </div>

   

        
        <div>
         <div className="mt-md-3">
        
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        
        <Placeholder>
          {props.fetching ? (
            <>
              <ResultStatus>
                <Spinner fadeIn="none" name="circle" />
              </ResultStatus>
              <h3>Simulating</h3>
            </>
          ) : (
            <>
              <ResultStatus>-</ResultStatus>
              <h3>Fill in your values</h3>
            </>
          )}
        </Placeholder>
      </>
    );
  }
};

const BatchCalculationResultCCTArbCR = (props: Props) => {
  return <ContentWrapper>{renderContent(props)}</ContentWrapper>;
};

export default BatchCalculationResultCCTArbCR;
