import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import ChemicalCompositionSFE from '../components/ChemicalCompositionSFE';
import SingleCalculationResultSFE from '../components/SingleCalculationResultsSFE';
import { initFetchSingleCalculation } from 'src/redux/modules/calculationSFE';
import {
  SteelValuesSFE,
  SingleCalculationResultsSFE
} from 'src/types/Calculation';


interface Props {
  fetching: boolean;
  singleCalcResults: SingleCalculationResultsSFE | null;
  fetchSingleCalculation: (steelValues: SteelValuesSFE) => Promise<any>;
}

class SingleCalculationSFE extends React.Component<Props, never> {
  public render() {
    const { fetchSingleCalculation, singleCalcResults, fetching } = this.props;

    return (
      <div className="row">
      <div className="col-md-4">
   <div className="card mb-4 box-shadow cardgrey">
    <div className="card-body">
        <ChemicalCompositionSFE
          onSubmitForm={fetchSingleCalculation}
          fetching={fetching}
        />
          </div>
    </div>
    </div>
    <div className="col-md-8">
     <div className="card mb-4 box-shadow cardgrey">
      <div className="card-body">
        <SingleCalculationResultSFE
          fetching={fetching}
          results={singleCalcResults}
        />
         </div>
      </div>
    </div>
    </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculationSFE;
  return { singleCalcResults, fetching };
};

// dispatch updates the state of an app
const mapDispatchToProps = (dispatch: any) => ({
  fetchSingleCalculation: (steelValues: SteelValuesSFE) =>
    dispatch(initFetchSingleCalculation(steelValues)) // initFetchSingleCalculation as an action creator
});

const SingleCalculationContainerSFE = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCalculationSFE);

export default SingleCalculationContainerSFE;
