import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Route } from 'react-router-dom';

import AuthCallback from './components/AuthCallback';
import { RootState } from './redux/modules';
import auth0Client from './Auth';
import PrivateRouteCopy from './components/PrivateRouteCopy';
import SingleCalculationPage from './containers/SingleCalculationPage';
import DashboardPage from './containers/DashboardPage';
import ApiDashboard from './containers/ApiDashboard';
import SingleCalculationPageDI from './containers/SingleCalculationPageDI';
import SingleCalculationPageCCTArbCR from './containers/SingleCalculationPageCCTArbCR';
import FerriticoFree from './containers/FerriticoFree';
import SingleCalculationPageHACCTArbCR from './containers/SingleCalculationPageHACCTArbCR';
import BatchCalculationPageCCTArbCR from './containers/BatchCalculationPageCCTArbCR';
import SingleCalculationPageGrainSize from './containers/SingleCalculationPageGrainSize';
import SingleCalculationPageTempering from './containers/SingleCalculationPageTempering';
import SingleCalculationPageTTT from './containers/SingleCalculationPageTTT';
import SingleCalculationPageEquilibrium from './containers/SingleCalculationPageEquilibrium';
import SingleCalculationPageCorrosion from './containers/SingleCalculationPageCorrosion';
import SingleCalculationPageSFE from './containers/SingleCalculationPageSFE';
import SingleCalculationPageEmbrittlement from './containers/SingleCalculationPageEmbrittlement';
import SingleHardenabilityPage from './containers/SingleHardenabilityPage';
import SingleThermalPage from './containers/SingleThermalPage';

import PlottingPage from './containers/PlottingPage';

const AppWrapper = styled.div`
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
`;

interface RouteType {
  component: React.ComponentType<any>;
  exact: boolean;
  path: string;
}

const routes: RouteType[] = [
  {
    component: DashboardPage,
    exact: true,
    path: '/dashboard'
  },
  {
    component: SingleCalculationPageTTT,
    exact: true,
    path: '/ttt'
  },
  {
    component: SingleCalculationPageEquilibrium,
    exact: true,
    path: '/equilibrium'
  },
  {
    component: SingleCalculationPageCCTArbCR,
    exact: true,
    path: '/cct'
  },
  {
    component: FerriticoFree,
    exact: true,
    path: '/free'
  },
  {
    component: ApiDashboard,
    exact: true,
    path: '/dashapi'
  },
  {
    component: SingleCalculationPageHACCTArbCR,
    exact: true,
    path: '/hacct'
  },
  {
    component: BatchCalculationPageCCTArbCR,
    exact: true,
    path: '/batchcct'
  },
  {
    component: SingleCalculationPageGrainSize,
    exact: true,
    path: '/grainsize'
  },
  {
    component: SingleCalculationPageCorrosion,
    exact: true,
    path: '/corr'
  },
  {
    component: SingleCalculationPage,
    exact: true,
    path: '/msfr'
  },
  {
    component: SingleHardenabilityPage,
    exact: true,
    path: '/hardenability'
  },
  {
    component: SingleThermalPage,
    exact: true,
    path: '/thermal'
  },
  {
    component: SingleCalculationPageDI,
    exact: true,
    path: '/di'
  },
  {
    component: DashboardPage,
    exact: true,
    path: '/'
  },
  {
    component: SingleCalculationPageTempering,
    exact: true,
    path: '/tempering'
  },
  {
    component: SingleCalculationPageSFE,
    exact: true,
    path: '/sfe'
  },
  {
    component: SingleCalculationPageEmbrittlement,
    exact: true,
    path: '/embrittlement'
  },
  {
    component: PlottingPage,
    exact: true,
    path: '/plot'
  }
];

interface Props extends RouteComponentProps<any> {}

interface State {
  checkingSession: boolean;
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checkingSession: true
    };
  }

  async componentDidMount() {
    if (this.props.location.pathname === '/auth-callback') {
      this.setState({ checkingSession: false });
      return;
    }
    try {
      await auth0Client.silentAuth();
    } catch (err) {
      if (err.error === 'login_required') {
        this.setState({ checkingSession: false });
        return;
      }
    }

    this.setState({ checkingSession: false });
  }
  public render() {
    const { checkingSession } = this.state;
    const user = auth0Client.getProfile();

    return (
      <AppWrapper>
        <Route
          path="/auth-callback"
          render={props => {
            return <AuthCallback />;
          }}
        />
        {routes.map((route: RouteType) => (
          <PrivateRouteCopy
            user={user}
            key={route.path}
            {...route}
            checkingSession={checkingSession}
          />
        ))}
      </AppWrapper>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => ({});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App); // AppContainer is wrapping App

const AppContainerWithRouter = withRouter(AppContainer);

export default AppContainerWithRouter;
