import { createStore, compose } from 'redux';
import { install } from 'redux-loop';

import { rootReducer } from './modules/index';

const configureStore = (initialState?: any) => {
  const enhancer = compose(install());

  const store = createStore(rootReducer, initialState!, enhancer);
  return store;
};

export default configureStore;
