import * as React from 'react';
import Spinner from 'react-spinkit';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';
import { SingleCalculationResultsCorrosion } from '../types/Calculation';



const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ResultWrapper = styled.div`
  flex: 1 0 50%;
  margin-bottom: 0.5rem;
  max-width: 49%;
  background-color: ${colors.backgroundDarker};
  padding: 1rem 0.7rem 0.7rem 0.7rem;
`;

const ResultContainer = styled.div`
  text-transform: uppercase;
  padding: 0rem 1rem 0.5rem 1rem;

  > h3 {
    min-height: 14px;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 14px;
  }
`;

const Result = styled.div`
  display: block;
  text-transform: none;
`;

const ResultNumber = styled.span`
  font-size: 40px;
  margin-right: 0.5rem;
  text-transform: uppercase;
`;

function round(num: number, precision: number) {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(
    precision
  );
}

const renderResultValue = (
  fetching: boolean,
  resultValue: number | null,
  unit: string = 'mV',
  decimals: number = 3,
  hysterisis: string =''
) => {
  let renderedValue = ' - ';

  if (typeof resultValue === 'number') {
    renderedValue = round(resultValue, decimals);
  }

  return (
    <Result>
      <ResultNumber>
        {fetching ? <Spinner fadeIn="none" name="circle" /> : renderedValue}
      </ResultNumber>
      <span>mV</span>
      <Result>
        {fetching ? <Spinner fadeIn="none" name="circle" /> : hysterisis}
        <span> hysteresis</span>
    </Result>
    </Result>
  );
};

interface Props {
  fetching: boolean;
  steelValues:any;
  results: SingleCalculationResultsCorrosion | null;
}


const renderContent = (props: Props) => {
  const { fetching, results } = props;

    return (
      <>
      <div className="row">
        <div className="col-md-6 col-sm-12"><Header>Result         
  </Header></div>
  </div>
  <ContentWrapper>
      <ResultsWrapper>
        <ResultWrapper>
        {results && results.Result.Result.Epit ? (
          <ResultContainer>
            <h3>{'  '}</h3>
            <h3>Epit</h3>
            {renderResultValue(fetching, results && results.Result.Result.Epit, ' mV', 3, results.Result.Result.Hys_formation)}
          </ResultContainer>
          ) : null}
          {/* <NoteContainer>Model root mean square error: </NoteContainer> */}
        </ResultWrapper>
      </ResultsWrapper>
    </ContentWrapper>
      </>
    );
  
};

const SingleCalculationResultCorrosion = (props: Props) => {
  return <ContentWrapper>{renderContent(props)}</ContentWrapper>;
};

export default SingleCalculationResultCorrosion;
