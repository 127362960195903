import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import ChemicalCompositionEmbrittlement from '../components/ChemicalCompositionEmbrittlement';
import SingleCalculationResultEmbrittlement from '../components/SingleCalculationResultsEmbrittlement';
import { initFetchSingleCalculation } from 'src/redux/modules/calculationEmbrittlement';
import {
  SteelValuesEmbrittlement,
  SingleCalculationResultsEmbrittlement
} from 'src/types/Calculation';



interface Props {
  fetching: boolean;
  singleCalcResults: SingleCalculationResultsEmbrittlement | null;
  fetchSingleCalculation: (
    steelValues: SteelValuesEmbrittlement
  ) => Promise<any>;
}

class SingleCalculationsEmbrittlement extends React.Component<Props, never> {
  public render() {
    const { fetchSingleCalculation, singleCalcResults, fetching } = this.props;
    
    return (
      <div className="row">
      <div className="col-md-4">
   <div className="card mb-4 box-shadow cardgrey">
    <div className="card-body">
        <ChemicalCompositionEmbrittlement
          onSubmitForm={fetchSingleCalculation}
          fetching={fetching}
        />
         </div>
    </div>
    </div>
    <div className="col-md-8">
     <div className="card mb-4 box-shadow cardgrey">
      <div className="card-body">
        <SingleCalculationResultEmbrittlement
          fetching={fetching}
          results={singleCalcResults}
        />
       </div>
      </div>
    </div>
    </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculationEmbrittlement; // Bad type
  return { singleCalcResults, fetching };
};

// dispatch updates the state of an app
const mapDispatchToProps = (dispatch: any) => ({
  fetchSingleCalculation: (steelValues: SteelValuesEmbrittlement) =>
    dispatch(initFetchSingleCalculation(steelValues)) // initFetchSingleCalculation as an action creator
});

const SingleCalculationContainerEmbrittlement = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCalculationsEmbrittlement);

export default SingleCalculationContainerEmbrittlement;
