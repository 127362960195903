import { combineReducers, LiftedLoopReducer } from 'redux-loop';

import { calculation, CalculationState } from './calculation';


import {
  calculation as calculationDIMT,
  CalculationState as CalculationStateDIMT
} from './calculationDIMT';
import {
  calculation as calculationCCTArbCR,
  CalculationState as CalculationStateCCtArbCR
} from './calculationCCTArbCR';
import {
  calculation as calculationHACCTArbCR,
  CalculationState as CalculationStateHACCtArbCR
} from './calculationHACCTArbCR';
import {
  calculation as calculationCCTArbCRFree,
  CalculationState as CalculationStateCCtArbCRFree
} from './calculationCCTArbCRFree';
import {
  calculation as batchCalculationCCTArbCR,
  CalculationState as BatchCalculationStateCCtArbCR
} from './calculationBatchCCTArbCR';
import {
  calculation as calculationGrainSize,
  CalculationState as CalculationStateGrainSize
} from './calculationGrainSize';
import {
  calculation as calculationTempering,
  CalculationState as CalculationStateTempering
} from './calculationTempering';
import {
  calculation as calculationTTT,
  CalculationState as CalculationStateTTT
} from './calculationTTT';
import {
  calculation as calculationEquilibrium,
  CalculationState as CalculationStateEquilibrium
} from './calculationEquilibrium';
import {
  calculation as calculationCorrosion,
  CalculationState as CalculationStateCorrosion
} from './calculationCorrosion';
import {
  calculation as calculationEmbrittlement,
  CalculationState as CalculationStateEmbrittlement
} from './calculationEmbrittlement';
import {
  calculation as calculationHardenability,
  CalculationState as CalculationStateHardenability
} from './calculationHardenability';
import {
  calculation as calculationThermal,
  CalculationState as CalculationStateThermal
} from './calculationThermal';
import {
  calculation as calculationSFE,
  CalculationState as CalculationStateSFE
} from './calculationSFE';

export interface RootState {
  calculation: CalculationState;
  calculationDIMT: CalculationStateDIMT;
  calculationCCTArbCR: CalculationStateCCtArbCR;
  calculationHACCTArbCR: CalculationStateHACCtArbCR;
  calculationCCTArbCRFree: CalculationStateCCtArbCRFree;
  batchCalculationCCTArbCR: BatchCalculationStateCCtArbCR;
  calculationEmbrittlement: CalculationStateEmbrittlement;
  calculationGrainSize: CalculationStateGrainSize;
  calculationHardenability: CalculationStateHardenability;
  calculationThermal: CalculationStateThermal;
  calculationSFE: CalculationStateSFE;
  calculationTempering: CalculationStateTempering;
  calculationTTT: CalculationStateTTT;
  calculationEquilibrium: CalculationStateEquilibrium;
  calculationCorrosion: CalculationStateCorrosion;
}

/*
Redux protip: the reducer will grow as your app will become bigger. 
You can split a big reducer into separate functions and combine them with combineReducers.
*/
export const rootReducer: LiftedLoopReducer<any, any> = combineReducers<
  RootState
>({
  calculation,
  calculationCCTArbCR,
  calculationHACCTArbCR,
  calculationCCTArbCRFree,
  batchCalculationCCTArbCR,
  calculationCorrosion,
  calculationDIMT,
  calculationEmbrittlement,
  calculationEquilibrium,
  calculationGrainSize,
  calculationHardenability,
  calculationSFE,
  calculationTTT,
  calculationTempering,
  calculationThermal
}); // calculation is the reducer
