import * as React from 'react';
import { Route, RouteProps} from 'react-router-dom';

import LoginPage from './LoginPage';
import Header from './Header';
import Footer from './Footer';
import auth0Client from '../Auth';
import Jumbotron from './Jumbotron';
import { User, UserRoles } from 'src/types/User';


export interface Props extends RouteProps {
  component: React.ComponentType<any>;
  exact: boolean;
  path: string;
  checkingSession: boolean;
  user: User;

}



/*
 * PrivateRoute prompts user login if user has been fetched but not found.
 * If user is fetched succesfully, we can render the Route + component given as prop.
 */

class PrivateRouteCopy extends React.Component<Props> {
  render() {
    const {
      component: Component,
      exact,
      path,
      checkingSession,
      user
    } = this.props;

    
    return (
      <Route
        exact={exact}
        path={path}
        render={() => {
          if (checkingSession) {
            return <div />;
          }

          if (!auth0Client.isAuthenticated()) {
            return <LoginPage />;
          }


          let missingRoles: boolean = true;
          const testpath:String = path; 
          console.log(testpath);
          const modulePath:UserRoles = path as UserRoles;
          console.log(modulePath);
          
          if(user.roles.length){
            if(user.roles.includes(modulePath) || user.roles.includes('user')){
              missingRoles = false;
            }
          }else{
            missingRoles = true;
          } 

          if (missingRoles) {
            
            if(testpath ==='/free'){
              console.log('mot free');
              return (

                <>
                
                    <Header logout={auth0Client.signOut} user={user} />
                    <Jumbotron logout={auth0Client.signOut} user={user} />
                    
                    <div className="album py-5 bg-light">
          <div className="container">
           <div>
           <Component />
           </div>
         </div>
           </div>
                    
                    <Footer logout={auth0Client.signOut} user={user} />
                  </>
              );
            }else{
              return (
                <>
                  <Header logout={auth0Client.signOut} user={user} />
                  <Jumbotron logout={auth0Client.signOut} user={user} />
                  <div className="album py-5 bg-light">
                  <div className="container mt-5 mb-5">
  <div className="row justify-content-center">
    <div className="col-md-6">
         <h5 className="m-auto pb-5" >Unfortunately, it appears that there is no active license associated with {user.email} for the selected simulation module. For prompt assistance on upgrading your account please don't hesitate to reach out to our team at contact@ferritico.com. </h5>
         <h5 className="m-auto pb-5">While waiting for your account upgrade, explore the Free version of Ferritico</h5>
         
         <div className="m-auto col-md-6">
       <a href="/free" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
        <div className="card-body btn-dashboard">
        Ferritico Free
        </div>
      </a>
      </div>
      </div>
         </div>
       </div>
         </div>
                  <Footer logout={auth0Client.signOut} user={user} />
                </>
      
              );
            }
            
          }

          return (
            <>
            
                <Header logout={auth0Client.signOut} user={user} />
                <Jumbotron logout={auth0Client.signOut} user={user} />
                
                <div className="album py-5 bg-light">
      <div className="container">
       <div>
       <Component />
       </div>
     </div>
       </div>
                
                <Footer logout={auth0Client.signOut} user={user} />
              </>
          );
        }}
      />
    );
  }
}

export default PrivateRouteCopy;
