import {  Field, Form, Formik, FormikHelpers as FormikActions, useFormikContext } from 'formik';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import Button from './form/Button';
import { colors } from '../shared/themeStyles';
import { SteelValuesHAArbCR } from '../types/Calculation';

const ContentWrapper = styled.div`
  flex-basis: 32.6%; /* 313/960 */
  padding: 1.5rem 1rem;
  background-color: ${colors.backgroundDarker};
`;

const ModalCloseIcon=styled.span`
    cursor: pointer;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    float:right;
    opacity:0.5;
   margin: -15px 15px -15px 15px;
    &:hover,
    &:focus {
    opacity:0.75;
  }
`
const ModalHeader=styled.div`
    border-bottom: 1px solid #e5e5e5;
    padding: 5px;
`

const ModalBody=styled.div`
padding: 15px;
`
const ModalFooter=styled.div`
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
`
const ModalFooterButton=styled.div`
    margin-left: 10px;
    float: right;
    width: 140px;
`
const ModalBox = styled.div`

position: fixed;
z-index: 1;
padding-top: 100px;
left: 0;
top: 0;
width: 100%; 
height: 100%; 
overflow: auto; 
background-color: rgb(0,0,0); 
background-color: rgba(0,0,0,0.4); 
transition: opacity .15s linear;
opacity: 1;
`;

const ModalContent = styled.div`
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  background-color: #fefefe;
  border: 1px solid #888;
  margin: auto;
  width: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
`;

const SectionTitle = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  width: 100%;
`;

const ProductTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2.5rem;
  width: auto;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 2px solid lightgray;
`;

const ErrorMessage = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  color: red;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

const ButtonWithMargin = styled(Button)`
  margin-bottom: 1rem;
`;

const SubText = styled.span`
  text-transform: uppercase;
  display: block;
  font-size: 9px;
  margin-bottom: 1rem;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  > label {
    font-size: 14px;
    text-align: right;
    margin-right: 1rem;
  }

  > input {
    width: 60px;
    height: 32px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }

  > input[type='range'] {
    width: 60%;
    margin-right: 10px;
  }

  > span {
    margin-left: 5px;
    color: ${colors.accentDark};
  }
`;
const SelectWrapper = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
    margin-right: 1rem;

  > select {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > input {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > button {
    margin: 0px auto;
    width: 130px;
  }
`;

const PrimaryInputWrapper = styled(InputWrapper as any)`
  flex: 1 0 100%;

  > label {
    flex-basis: 13%;
  }
`;

const Width100InputWrapper = styled(InputWrapper as any)`
width: 100%;  
flex: 1 0 100%;

> input {
  width: 100% !important;
}
`;

const SecondaryInputWrapper = styled(InputWrapper as any)`
  flex: 1 0 50%;

  > label {
    flex-basis: 26%;
  }
`;

interface FormValues extends SteelValuesHAArbCR {
  aus_temp: number;
  aus_time: number;
  crs: number;
  crs_start: number;
  crs_end: number;
  crs_explicit:string;
  is_aus_time:boolean;
  save_name:string;
}

const steelValues: SteelValuesHAArbCR = {
  C: 0,
  Cr: 0,
  Cu: 0,
  Mg:0,
  Mn: 0,
  Mo: 0,
  N: 0,
  Nb: 0,
  Ni: 0,
  Si: 0,
  Ti: 0,
  V: 0,
};

const formInputValueRanges = {
  C: [0.001, 15],
  Cr: [0, 20],
  Cu: [0, 15],
  Mn: [0, 15],
  Mo: [0, 15],
  Mg: [0, 15],
  N: [0, 15],
  Nb: [0, 15],
  Ni: [0, 15],
  Si: [0, 15],
  Ti: [0, 15],
  V: [0, 15],
  aus_temp: [800, 1200],
  aus_time: [1, 10000],
  crs: [0.001, 100],
  crs_end: [0.001, 1000],
  crs_explicit: [false, false],
  crs_start: [0.001, 1000],
  is_aus_time: [false, false],
  save_name: [false, false]
  
};

const idealRange={
  C: [0, 0.9],
  Cr: [0, 2],
  Cu: [0, 0.5],
  Mn: [0, 1.5],
  Mg: [0, 1.5],
  Mo: [0, 1],
  N: [0, 0.1],
  Nb: [0, 0.05],
  Ni: [0, 2],
  Si: [0, 0.7],
  Ti: [0, 0.2],
  V: [0, 0.1],
}

const initialValues: FormValues = {
  ...steelValues,
  aus_temp: 900,
  aus_time: 3600,
  crs: 10,
  crs_end: 100,
  crs_explicit: '1, 2, 4, 8, 15, 20',
  crs_start: 0.1,
  is_aus_time:false,
  save_name: ''
};


type InputRange = [number, number];

const primaryElements: string[] = ['C', 'Mn', 'Si', 'Cr', 'Ni', 'Mo'];

const secondaryElements: string[] = [
  'V',
  'Cu',
  'Nb',
  'Ti',
  'Mg',
  'N'
];

const getCompositionValues = (
  values: FormValues
): { [name: string]: number } => {
  return R.omit(['aus_temp','aus_time', 'crs', 'crs_start', 'crs_end', 'crs_explicit', 'is_aus_time', 'save_name'], values);
};



const validateTotalLimit = (values: FormValues) => {
  const valuesExceedLimit = R.pipe(
    getCompositionValues,
    R.values,
    R.sum,
    R.flip(R.gt)(100)
  )(values);

  if (valuesExceedLimit) {
    return {
      composition: 'Chemical composition does not sum up to 100%, please adjust'
    };
  } else {
    return {};
  }
};



const makeElementInputRangeError = ([name, value]: [string, number]) => {
  return [
    name,
    `"${name}" needs a value between ${formInputValueRanges[name][0]} and ${formInputValueRanges[name][1]}`
  ];
};

const isWithinRange = (value: number, [minValue, maxValue]: InputRange) => {
  return minValue === maxValue || value >= minValue && value <= maxValue;
};



const isElementInputValid = ([name, value]: [string, number]) =>  {
  return isWithinRange(value, formInputValueRanges[name]);
};

const validateInputRangeErrors = (values: FormValues) => {
  return R.pipe(
    R.toPairs,
    R.reject<[string, any], 'array'>(isElementInputValid),
    R.map(makeElementInputRangeError),
    R.fromPairs
  )(values);
};

const validate = (values: FormValues) => {
  const compositionErrors = validateTotalLimit(values);
  const inputRangeErrors = validateInputRangeErrors(values);

  return {
    ...compositionErrors,
    ...inputRangeErrors
  };
};

const renderElementInput = (element: string) => (
  <>
    <label htmlFor={element}>{element}: </label>
    <Field
      id={element}
      name={element}
      type="number"
      min="0"
      step={primaryElements.indexOf(element) === -1 ?'0.00001':'0.00001'}
      title={'Ideal Range: '+idealRange[element][0].toString()+' - '+idealRange[element][1].toString()}
      autoComplete="off"
    />{' '}
    <span>%</span>
  </>
);

const renderPrimaryElementInput = (element: string) => (
  <PrimaryInputWrapper key={element}>
    {renderElementInput(element)}
  </PrimaryInputWrapper>
);



const renderSecondaryElementInput = (element: string) => (
  <SecondaryInputWrapper key={element}>
    {renderElementInput(element)}
  </SecondaryInputWrapper>
);

interface Props {
  onSubmitForm: (steelValues: SteelValuesHAArbCR) => Promise<any>;
  fetching: boolean;
  parentCallback: any;
}

interface State {
  displaySecondaryelements: boolean;
  error: string | null;
  showModal: boolean;
  showCrsModal: boolean;
  showSaveModal: boolean;
  showRemoveModal: boolean;
  displayCrInputField: boolean;
  displayAusTimeInputField: boolean;
  valuesToLoad:FormValues;
}

class ChemicalComposition extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { valuesToLoad:initialValues, displaySecondaryelements: false, error: null, showModal: false, showCrsModal: false,showSaveModal:false, showRemoveModal:false, displayCrInputField: false, displayAusTimeInputField: false };
  }

  removeStorage = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    localStorage.clear();
  };

  StoreDataArea = () => {

    const { values } = useFormikContext();

    // const setValuesToLoad = (event: React.MouseEvent<HTMLElement>) => {
    //   this.setState({valuesToLoad:savedValues})
      
    // };

  return(
    <>
<SelectWrapper>
     {this.getStorageList()}
     </SelectWrapper>  
     <SelectWrapper>
<ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>{
                  event.preventDefault();
                  this.setState({showRemoveModal: true}) 

                }
                } secondary>
                Remove all
              </ButtonWithMargin>
              </SelectWrapper>

<SelectWrapper>
                  
                  <Field
                    id="save_name"
                    name="save_name"
                    type="text"
                    autoComplete="off"
                    />
                </SelectWrapper>
                <SelectWrapper>
<ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>{
                  event.preventDefault()
                  
                  localStorage.setItem('hacct_'+ (values as FormValues).save_name, JSON.stringify(values))
                
                  this.setState({showSaveModal: true})
        }
                } secondary>
                Save
              </ButtonWithMargin>
              </SelectWrapper>
              </>
  )
}

  toggleSecondaryElementsDisplay = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({
      displaySecondaryelements: !this.state.displaySecondaryelements
    });
  };

  resetInputs = (event: React.MouseEvent<HTMLElement>) => {
    window.location.reload();
  };

  handleChange = (e: { currentTarget: { value: FormValues; }; }) => {


    const anytype = 'hacct_' + e.currentTarget.value; 
    
    const theValue: string | null  = localStorage.getItem(anytype);
    let parsedValue = null;

    if (theValue !== null) {
      parsedValue= JSON.parse(theValue) as FormValues;
    }
    if (parsedValue !== null) {
      this.setState({valuesToLoad:parsedValue});
    }
  }
  
  getStorageList = () => {
  const storageList= [];
  const keys = Object.keys(localStorage);
  let i = keys.length;
  const substring = 'hacct_';
  
  while ( i-- ) {
    if(keys[i].includes(substring)){
    storageList.push( keys[i].replace('hacct_', '') );
    }
  };

  const selectList = storageList.map((ele, index) => <option key={index} value={ele}>{ele}</option>)  

  
  return (<>
  <Field as="select" name="simulations" onChange={ 

    this.handleChange}> <option selected disabled>Select</option> {selectList} </Field>
  </>
  )
   
}
 
   
  hideModal= (event: React.MouseEvent<HTMLElement>) => {
    this.setState({showModal:false})
  }
  hideCrsModal= (event: React.MouseEvent<HTMLElement>) => {
    this.setState({showCrsModal:false})
  }
  hideSaveModal= (event: React.MouseEvent<HTMLElement>) => {
    this.setState({showSaveModal:false})
  }
  hideRemoveModal= (event: React.MouseEvent<HTMLElement>) => {
    this.setState({showRemoveModal:false})
  }


  render() {
    const {valuesToLoad, displaySecondaryelements,showModal, showCrsModal, showSaveModal, showRemoveModal} = this.state;
    const { onSubmitForm, fetching } = this.props;
    
    return (
      <ContentWrapper>

        <ProductTitle>High Alloy CCT</ProductTitle>
        <SectionTitle>Chemical Composition (Please use Low Alloy CCT module when total alloying is less than 5%)</SectionTitle>
        <SubText>Weight percentages</SubText>
        <Formik
        enableReinitialize={true}
          initialValues={valuesToLoad}
          validate={validate}
          onSubmit={(
            values: SteelValuesHAArbCR,
            { setSubmitting }: FormikActions<SteelValuesHAArbCR>
          ) => {

            // any null values (empty strings) will be converted to 0
            const nullsToZero = (val: any) => (!val ? 0 : val);
            // @ts-ignore
            let formattedValues:SteelValuesArbCR = R.map(nullsToZero, values);
            formattedValues = R.omit(['save_name' ], formattedValues);
            
            let filteredObject;
/*             const getCrsValue = (key: any) => (key==='crs' ? val);
            const getCrsProp = R.map((object:any, value:any) => [object.get(value)]);
 */
            
            
            const keycrsExpl = 'crs_explicit';
            const keycrs = 'crs';
            
            const avgclosed = 'average_option'
            
            formattedValues[avgclosed] = 'avg_all';
            
            if(this.state.displayCrInputField === false){  
              
              filteredObject = R.omit(['crs_explicit' , 'is_aus_time'], formattedValues);
              formattedValues = filteredObject;
            }else{
              if(formattedValues[keycrsExpl] === 0){
                this.setState({showCrsModal: true})
                formattedValues[keycrsExpl] = [1, 2, 4, 8, 15, 20];
              }
              formattedValues[keycrs] = formattedValues[keycrsExpl].split(',').map(Number);
              

              filteredObject = R.omit(['crs_start', 'crs_end','crs_explicit', 'is_aus_time' ], formattedValues);
              formattedValues = filteredObject;
              
              
              
            }
            
            if(this.state.displayAusTimeInputField === false){
              filteredObject = R.omit(['aus_time' ], formattedValues);
              formattedValues = filteredObject;
            }

            const subset = primaryElements.concat(secondaryElements).reduce((o, k) =>{ o[k] = formattedValues[k]; return o; }, {});
            const lowalloycomp=Object.keys(subset).reduce((sum,key)=>sum+ parseFloat(subset[key]),0);
            if(lowalloycomp < 5){
              this.setState({showModal: true})
              
            }
            this.props.parentCallback(formattedValues);  
            onSubmitForm(formattedValues).then(() => {
              setSubmitting(false);
            });
          
          }}
          render={(props: any,) => (
            <Form>
              <InputsWrapper>
                {primaryElements.map(ele => renderPrimaryElementInput(ele))}
              </InputsWrapper>
              <ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.toggleSecondaryElementsDisplay(event)
                }
                secondary
              >
                {displaySecondaryelements
                  ? 'Hide Elements'
                  : 'View All Elements'}
              </ButtonWithMargin>
              {displaySecondaryelements && (
                <InputsWrapper>
                  {secondaryElements.map(ele =>
                    renderSecondaryElementInput(ele)
                  )}
                </InputsWrapper>
              )}
              <SectionTitle>Austentization temperature</SectionTitle>
              <InputsWrapper>
                <PrimaryInputWrapper>
                  <Field
                    id="aus_temp"
                    name="aus_temp"
                    type="number"
                    step="1"
                    autoComplete="off"
                  />{' '}
                  <span>°C</span>
                </PrimaryInputWrapper>
              </InputsWrapper>
              
              <SectionTitle>Austentization time</SectionTitle>              
               <InputsWrapper>
              <label id="label_time">
                <Field
                    id="is_aus_time"
                    name="is_aus_time"
                    type="checkbox"
                    checked={this.state.displayAusTimeInputField }
                    value={this.state.displayAusTimeInputField }
                    onChange={() => this.setState({ displayAusTimeInputField: !this.state.displayAusTimeInputField })}
                  />
Include austenitization time?
</label> 
</InputsWrapper>


  
              {this.state.displayAusTimeInputField?
              <InputsWrapper className="mt-2">
                <PrimaryInputWrapper>
                  <Field
                    id="aus_time"
                    name="aus_time"
                    type="number"
                    step="1"
                    autoComplete="off"
                    
                  />{' '}
                  <span>Seconds</span>
                </PrimaryInputWrapper>
              </InputsWrapper>:''
          }
              
              

              <>
  <ToggleButtonGroup id="crtype" type="checkbox" className="mb-3 mt-4 col-12" defaultValue={[1]} >
    <ToggleButton id="tbg-check-1" className="btn-switch" size="sm"  type="checkbox" value={1} onClick={ () => this.setState({ displayCrInputField: false }) }>
      Interval
    </ToggleButton>
    <ToggleButton id="tbg-check-2" className="btn-switch" size="sm" type="checkbox" value={2}  onClick={ () => this.setState({ displayCrInputField: true })} >
      Explicit CRs
    </ToggleButton>
  </ToggleButtonGroup >  
    
      {this.state.displayCrInputField?
      <InputsWrapper>
        <PrimaryInputWrapper>
                <SectionTitle>Cooling rates in °C/sec or time to room temperature (comma separated e.g. 2,5,10)</SectionTitle>
                
                  </PrimaryInputWrapper>
        <Width100InputWrapper>
                
                  <Field
                    id="crs_explicit"
                    name="crs_explicit"  
                  />
                  </Width100InputWrapper>
                  {/* <Width80InputWrapper>
          <label>
                <Field
                    id="is_time"
                    name="is_time"
                    type="checkbox"
                    value="false"
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      this.toggleIsTime(event)
                    }
                  />
Time
</label> 

</Width80InputWrapper> */}



      </InputsWrapper>:
              <>
              <InputsWrapper>
                <SectionTitle>Cooling rates range (°C / s)</SectionTitle>
                <SecondaryInputWrapper>
                  <label>Fastest:</label>
                  <Field
                    id="crs_end"
                    name="crs_end"
                    step="1"
                    autoComplete="off"
                  />

                </SecondaryInputWrapper>
                <SecondaryInputWrapper>
                  <label>Slowest:</label>
                  
                  <Field
                    id="crs_start"
                    name="crs_start"
                    step="1"
                    autoComplete="off"
                    />
                </SecondaryInputWrapper>
              </InputsWrapper>
              <InputsWrapper>
                <SectionTitle>Amount of cooling rates</SectionTitle>
                <PrimaryInputWrapper>
                  <Field
                    id="crs"
                    name="crs"
                    type="range"
                    step="1"
                    min="1"
                    max="100"
                    autoComplete="off"
                  />
                <span>{props.values.crs}</span>  
                </PrimaryInputWrapper>
              </InputsWrapper>
              </>
              
     }
              </>
      


   



              <this.StoreDataArea />



              <InputsWrapper className="mb-2 mt-5">
              <Button   disabled={fetching}>
                {fetching ? 'Loading...' : 'Simulate'}
              </Button>
              
              {props.errors &&
                R.values(props.errors).map((error: string) => (
                  <ErrorMessage key={error}>{error}</ErrorMessage>
                ))}
                </InputsWrapper>

                <InputsWrapper className="mb-5 mt-2">
<ButtonWithMargin 
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.resetInputs(event)
                } secondary>
                Clear inputs
              </ButtonWithMargin>

              </InputsWrapper>

            </Form>
          )}

        />

<ModalBox style={showModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>You are moving in to low alloy domain now.<ModalCloseIcon onClick={this.hideModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>Please note that this version of CCT module provides best results in the high alloy domain. </ModalBody>
    <ModalFooter>
      <Button secondary={true} width={'100px'} float={'right'} onClick={this.hideModal}>Close</Button>
    </ModalFooter>              
  </ModalContent>
</ModalBox>

<ModalBox style={showCrsModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>Please specify your cooling rates.<ModalCloseIcon onClick={this.hideCrsModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>Please specify your cooling rates in the input field. </ModalBody>
    <ModalFooter>
      <Button secondary={true} width={'100px'} float={'right'} onClick={this.hideCrsModal}>Close</Button>
    </ModalFooter>              
  </ModalContent>
</ModalBox>

<ModalBox style={showSaveModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>Your simulation was saved<ModalCloseIcon onClick={this.hideSaveModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>Your simulation has been saved to your browser cache. Please note that it has not been saved to central database and that it will me removed upon browser cache clean. </ModalBody>
    <ModalFooter>
      <Button secondary={true} width={'100px'} float={'right'} onClick={this.hideSaveModal}>Close</Button>
    </ModalFooter>              
  </ModalContent>
</ModalBox>
<ModalBox style={showRemoveModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>Do you want to remove all saved simulations?<ModalCloseIcon onClick={this.hideRemoveModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>Do you want to remove all locally saved simulations from you browser storage? </ModalBody>
    <ModalFooter>
    <ModalFooterButton>
      <Button secondary={true} width={'120px'} float={'right'} onClick={this.hideRemoveModal}>Cancel</Button>
      </ModalFooterButton>
      
      <Button  secondary={true} width={'120px'} float={'right'} onClick={
        (event: React.MouseEvent<HTMLElement>) =>{
          event.preventDefault();
          const keys = Object.keys(localStorage);
  let i = keys.length;
  const substring = 'hacct_';
  
  while ( i-- ) {
    if(keys[i].includes(substring)){
    localStorage.removeItem( keys[i]);
    }
  }
          this.setState({showRemoveModal:false})
        }
        }>Remove</Button>            
      
    </ModalFooter>              
  </ModalContent>
</ModalBox>

      </ContentWrapper>
      
    );
  }
}

export default ChemicalComposition;
