import React, {Component} from 'react'
import {Button, Collapse} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus,faImage, faTemperatureLow, faChartLine, faTable, faChartPie } from '@fortawesome/free-solid-svg-icons'

class CollapseDiv extends Component <{ divcontent?: string, icon?: string  }>{

  
    state={ 
        open:false
       }

       toggleSecondaryElementsDisplay = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        this.setState({
          open: !this.state.open
        });
      };

    render(){
      return(
   
        <div className= "container" id="divcontainer">
           <Button id="btn_div_display" onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.toggleSecondaryElementsDisplay(event)
                }>
                  
                {this.props.icon === 'staticImg'?
                  <FontAwesomeIcon icon={faImage} color="orange"className="colIcon"/>:
                  ''
              }
                {this.props.icon === 'diagram'?
                  <FontAwesomeIcon icon={faChartLine} color="orange" className="colIcon"/>:
                  ''
              }
                {this.props.icon === 'temp'?
                  <FontAwesomeIcon icon={faTemperatureLow} color="orange" className="colIcon"/>:
                  ''
              }
                {this.props.icon === 'pie'?
                  <FontAwesomeIcon icon={faChartPie} color="orange" className="colIcon"/>:
                  ''
              }
                {this.props.icon === 'table'?
                  <FontAwesomeIcon icon={faTable} color="orange" className="colIcon"/>:
                  ''
              }
                
               
               <span className="">      
               {this.props.divcontent}
               </span>
               {this.state.open
                  ? <FontAwesomeIcon icon={faMinus} color="orange" className="colswitch"/>
                  : <FontAwesomeIcon icon={faPlus} color="orange" className="colswitch"/>}
              
           </Button>

           <Collapse in={this.state.open}>
               <div className="collapsemin">
               {this.props.children}
               </div>
           </Collapse>
        </div>
        
        );
       }
}

export default CollapseDiv