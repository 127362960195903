import * as React from 'react';

import { User } from '../types/User';



interface TopBarProps {
  logout: () => void;
  user: User | null;
  children?: any;
}

const Jumbotron: React.SFC<TopBarProps> = (props: TopBarProps) => {
  const { user } = props;

  return (
    <section className="jumbotron text-center mb-0 bg-white">
     <div className="container">
      <h1 className="jumbotron-heading">Welcome</h1>
      <p className="text-muted">
      {user && <h6 className="text-muted">
         {user.email}
         </h6>}
      </p>
     </div>
    </section>
   );
};

export default Jumbotron;
