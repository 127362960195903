import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import ChemicalComposition from '../components/ChemicalComposition';
import SingleCalculationResultPlot from '../components/SingleCalculationResultsPlot';
import { initFetchSingleCalculation } from 'src/redux/modules/calculation';
import { SteelValues, SingleCalculationResults } from 'src/types/Calculation';



interface Props {
  fetching: boolean;
  singleCalcResults: SingleCalculationResults | null;
  fetchSingleCalculation: (steelValues: SteelValues) => Promise<any>;
}

class Plotting extends React.Component<Props, never> {
  public render() {
    const { fetchSingleCalculation, singleCalcResults, fetching } = this.props;

    return (
      <div className="row">
      <div className="col-md-4">
   <div className="card mb-4 box-shadow cardgrey">
    <div className="card-body">
        <ChemicalComposition
          onSubmitForm={fetchSingleCalculation}
          fetching={fetching}
        />
             </div>
    </div>
    </div>
    <div className="col-md-8">
     <div className="card mb-4 box-shadow cardgrey">
      <div className="card-body">
        <SingleCalculationResultPlot
          fetching={fetching}
          results={singleCalcResults}
        />
      </div>
      </div>
    </div>
    </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculation;
  return { singleCalcResults, fetching };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSingleCalculation: (steelValues: SteelValues) =>
    dispatch(initFetchSingleCalculation(steelValues))
});

const SingleCalulationContainer = connect(mapStateToProps, mapDispatchToProps)(
  Plotting
);

export default SingleCalulationContainer;
