import './bootstrap.css';
import '@glideapps/glide-data-grid/dist/index.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactGA from 'react-ga';

// @ts-ignore
import { hotjar } from 'react-hotjar';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import configureStore from './redux/configureStore';
import App from './App';

const HOTJAR_HJID = 1087421;
const HOTJAR_HJSV = 6;

hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);

const store = configureStore();
ReactGA.initialize('UA-129074046-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
