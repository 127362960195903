import * as React from 'react';
import Spinner from 'react-spinkit';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';
import { SingleCalculationResultsThermal } from '../types/Calculation';
import Button from './form/Button';
import CollapseDiv from './Collapse';

const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
`;


const Placeholder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 64px;

  h3 {
    min-height: 14px;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 14px;
  }
`;

const ResultStatus = styled.div`
  font-size: 40px;
`;

const ResultsTable = styled.div`
  margin-bottom: 64px;
`;

const ResultsTableHead = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
`;

const ResultsTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const ResultsTableCol = styled.div`
  flex: 1;
`;

interface Props {
  fetching: boolean;
  steelValues:any;
  results: SingleCalculationResultsThermal | null;
}

const renderHardness = ( hardness: any, index: number) => {
  const hrc = hardness[index];
  const hardnessUnit = hrc.toString().replace('m', '');

  return `${parseFloat(hardnessUnit).toFixed(2)} `;
};

const renderContent = (props: Props) => {
  function exportToTxt(){
    if (props.results && !props.fetching) {
      
    //const cond_temp= props.results.Result.cond_temp
    const thermal_cond= props.results.Result.thermal_cond
    const liq_temp= props.results.Result.liq_temp
    const sol_temp= props.results.Result.sol_temp
    const dens_temp= props.results.Result.dens_temp
    const aus_dens = props.results.Result.aus_dens
    const bainsdens = props.results.Result.bain_dens
    const fer_dens= props.results.Result.fer_dens
    const pearl_dens= props.results.Result.pearl_dens
    const mart_dens= props.results.Result.mart_dens
    const capacity_temp= props.results.Result.capacity_temp
    const capacity_aus= props.results.Result.capacity_aus
    const capacity_fer= props.results.Result.capacity_fer
    const capacity_pearl= props.results.Result.capacity_pearl
    const capacity_bain= props.results.Result.capacity_bain
    const capacity_mart= props.results.Result.capacity_mart

      let data ='Composition: \n'+JSON.stringify(props.steelValues).slice(1,-1);
      data = data +'\n\n\n\nThermal Conductivity\nTemperature (°C)  \tThermal Conductivity (W/(m⋅K))\n';
  
 
      dens_temp.forEach((element,i) => {
        data = data + parseFloat(element).toFixed(2) + ' \t'+ parseFloat(thermal_cond[i]).toFixed(2)+'\n'
      }); 
      data = data +'\n\n\n\n\nLiquidus temp (°C)  \tSolidus temp (°C)\n';
      
        data = data + liq_temp.toFixed(2) + ' \t \t \t'+ sol_temp.toFixed(2)+'\t'+'\n'
       
      data = data +'\n\n\n\nDensity (Kg/mt³)\nTemp (°C) \tAustenite \tFerrite \tPearlite \tBainite \tMartensite\n';
      dens_temp.forEach((element,i) => {
        data = data + parseFloat(element).toFixed(2) + ' \t'+ parseFloat(aus_dens[i]).toFixed(2)+'\t'+ parseFloat(fer_dens[i]).toFixed(2)+'\t'+parseFloat(pearl_dens[i]).toFixed(2)+'\t'+parseFloat(bainsdens[i]).toFixed(2)+'\t'+parseFloat(mart_dens[i]).toFixed(2)+'\t'+'\n'
      }); 
      data = data +'\n\n\n\nSpecific heat capacity (J/Kg.°C)\nTemp (°C) \t  \tAustenite \tFerrite \tPearlite \tBainite \tMartensite\n';
      capacity_temp.forEach((element,i) => {
        data = data + parseFloat(element).toFixed(2) + ' \t'+ parseFloat(capacity_aus[i]).toFixed(2)+'\t'+ parseFloat(capacity_fer[i]).toFixed(2)+'\t'+parseFloat(capacity_pearl[i]).toFixed(2)+'\t'+parseFloat(capacity_bain[i]).toFixed(2)+'\t'+parseFloat(capacity_mart[i]).toFixed(2)+'\t'+'\n'
      }); 
  



      const file = new Blob([data], { type: 'data:application/vnd.ms-word;charset=utf-8' });

      if ((window.navigator as any).msSaveOrOpenBlob){ // IE10+
        (window.navigator as any).msSaveOrOpenBlob(file, 'my_data.doc');
    }
  else { // Others
      const link = document.createElement('a');
      const url = URL.createObjectURL(file);
      link.href = url;
      link.download = 'my_data.doc';
      document.body.appendChild(link);
      link.click();
      setTimeout(e => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);  
      }, 0); 
      }
  }
}
  if (props.results && !props.fetching) {
    const data =JSON.stringify(props.steelValues).slice(1,-1);
    const strarray = data.split(',');

    //const cond_temp= props.results.Result.cond_temp
    const thermal_cond= props.results.Result.thermal_cond
    const liq_temp= props.results.Result.liq_temp
    const sol_temp= props.results.Result.sol_temp
    const dens_temp= props.results.Result.dens_temp
    const aus_dens = props.results.Result.aus_dens
    const bainsdens = props.results.Result.bain_dens
    const fer_dens= props.results.Result.fer_dens
    const pearl_dens= props.results.Result.pearl_dens
    const mart_dens= props.results.Result.mart_dens
    const capacity_temp= props.results.Result.capacity_temp
    const capacity_aus= props.results.Result.capacity_aus
    const capacity_fer= props.results.Result.capacity_fer
    const capacity_pearl= props.results.Result.capacity_pearl
    const capacity_bain= props.results.Result.capacity_bain
    const capacity_mart= props.results.Result.capacity_mart

    console.log(strarray)
    return (
      <>

<div className="row">
        <div className="col-md-12 col-sm-12"><Header>Input         
  </Header></div>
  </div>

<div className="row">
  
  <div className="col-md-12 col-sm-12 mt-md-3 compoverflow">
   <ResultsTable id="inputcomp">
     <ResultsTableHead className="inputcomptable">
       <ResultsTableCol className="inputcompcell">Al</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">B</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">C</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Co</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Cr</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Cu</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Mn</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Mo</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">N</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Nb</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Ni</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">P</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">S</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Si</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Ti</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">V</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">W</ResultsTableCol>
     </ResultsTableHead>
     <ResultsTableRow>
     {
     strarray.map((dataInput: any) => (
        
       dataInput.split(':')[0] !=='"At"' && dataInput.split(':')[0] !=='"As"' && dataInput.split(':')[0] !=='"Zr"' && dataInput.split(':')[0] !=='"Sn"' && dataInput.split(':')[0] !=='"crs"' && dataInput.split(':')[0] !=='"crs_end"' && dataInput.split(':')[0] !=='"crs_start"' && dataInput.split(':')[0] !=='"is_time"' && dataInput.split(':')[0] !=='"average_option"' && dataInput.split(':')[0] !=='"depths"' && dataInput.split(':')[0] !=='"hardenability_option"' && isNaN(dataInput) && dataInput.slice(-1) !==']' && dataInput.includes(':')?
         <ResultsTableCol>
           {dataInput.split(':')[1]}
         </ResultsTableCol>
         :
         ''
       
     ))}
     </ResultsTableRow>
   </ResultsTable>
   </div>
 
 </div>

      <div className="row">
        <div className="col-md-6 col-sm-12"><Header>Results         
  </Header></div>
  <div className="col-md-6 col-sm-12">
  <Button width="45%" float="right" height="2rem" download={true} onClick={exportToTxt}>Export</Button>
  </div>
  </div>


  <div>
         <div className="mt-md-3">
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableCol>Liquidus temp (°C) </ResultsTableCol>
              <ResultsTableCol>Solidus temp (°C)</ResultsTableCol>
            </ResultsTableHead>
            
              
              <ResultsTableRow>
                <ResultsTableCol>
                  {liq_temp.toFixed(2)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {sol_temp.toFixed(2)}
                </ResultsTableCol>
              </ResultsTableRow>
              
          </ResultsTable>
          </div>
        </div>



        <CollapseDiv divcontent="Thermal Conductivity">

        <div>
         <div className="mt-md-3">
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableCol>Temperature °C</ResultsTableCol>
              <ResultsTableCol>Thermal Conductivity (W/(m⋅K))</ResultsTableCol>
            </ResultsTableHead>
            {dens_temp.map((ct: any, index: number) => (
              
              <ResultsTableRow>
                <ResultsTableCol>
                  {parseFloat(ct).toFixed(2)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(thermal_cond, index || 0)}
                </ResultsTableCol>
              </ResultsTableRow>
               
            ))}
          </ResultsTable>
          </div>
        </div>
        </CollapseDiv>

        <CollapseDiv divcontent="Density (Kg/mt³)">
        
        <div>
         <div className="mt-md-3">
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableCol>Temperature °C</ResultsTableCol>
              <ResultsTableCol>Austenite</ResultsTableCol>
              <ResultsTableCol>Ferrite</ResultsTableCol>
              <ResultsTableCol>Pearlite</ResultsTableCol>
              <ResultsTableCol>Bainite</ResultsTableCol>
              <ResultsTableCol>Martensite</ResultsTableCol>
            </ResultsTableHead>
            {dens_temp.map((ct: any, index: number) => (
              
              <ResultsTableRow>
                <ResultsTableCol>
                  {parseFloat(ct).toFixed(2)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(aus_dens, index || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(fer_dens, index || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(pearl_dens, index || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(bainsdens, index || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(mart_dens, index || 0)}
                </ResultsTableCol>
              </ResultsTableRow>
              
            ))}
          </ResultsTable>
          </div>
        </div>
        </CollapseDiv>


        <CollapseDiv divcontent="Specific heat capacity (J/Kg.°C)   ">
        
        <div>
         <div className="mt-md-3">
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableCol>Temperature °C </ResultsTableCol>
              <ResultsTableCol>Austenite</ResultsTableCol>
              <ResultsTableCol>Ferrite </ResultsTableCol>
              <ResultsTableCol>Pearlite </ResultsTableCol>
              <ResultsTableCol>Bainite </ResultsTableCol>
              <ResultsTableCol>Martensite </ResultsTableCol>
            </ResultsTableHead>
            {capacity_temp.map((ct: any, index: number) => (
              
              <ResultsTableRow>
                <ResultsTableCol>
                  {parseFloat(ct).toFixed(2)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(capacity_aus, index || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(capacity_fer, index || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(capacity_pearl, index || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(capacity_bain, index || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(capacity_mart, index || 0)}
                </ResultsTableCol>
              </ResultsTableRow>
              
            ))}
          </ResultsTable>
          </div>
        </div>
        </CollapseDiv>







       





      </>
    );
  } else {
    return (
      <>
        <Header>Results</Header>
        <Placeholder>
          {props.fetching ? (
            <>
              <ResultStatus>
                <Spinner fadeIn="none" name="circle" />
              </ResultStatus>
              <h3>Simulating</h3>
            </>
          ) : (
            <>
              <ResultStatus>-</ResultStatus>
              <h3>Fill in your values</h3>
            </>
          )}
        </Placeholder>
      </>
    );
  }
};

const SingleCalculationResultThermal = (props: Props) => {
  return <ContentWrapper>{renderContent(props)}</ContentWrapper>;
};

export default SingleCalculationResultThermal;
