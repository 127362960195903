import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import ChemicalCompositionEquilibrium from '../components/ChemicalCompositionEquilibrium';
import SingleCalculationResultEquilibriumPlot from '../components/SingleCalculationResultsEquilibriumPlot';
import { initFetchSingleCalculation } from 'src/redux/modules/calculationEquilibrium';
import {
  SteelValuesEquilibrium,
  SingleCalculationResultsEquilibrium
} from 'src/types/Calculation';


interface Props {
  steelValues:SteelValuesEquilibrium;
  fetching: boolean;
  singleCalcResults: SingleCalculationResultsEquilibrium | null;
  fetchSingleCalculation: (steelValues: SteelValuesEquilibrium) => Promise<any>;
}

class SingleCalculationEquilibrium extends React.Component<Props> {
  state = {steelValues:[]}  
  callbackFunction = (childData:SteelValuesEquilibrium) => {
    this.setState({steelValues: childData})
  }
  
  public render() {
    const { fetchSingleCalculation, singleCalcResults, fetching } = this.props;
    
    return (
      
  <div className="row">
      <div className="col-md-4">
   <div className="card mb-4 box-shadow cardgrey">
    <div className="card-body">
        <ChemicalCompositionEquilibrium
          onSubmitForm={fetchSingleCalculation}
          parentCallback = {this.callbackFunction}
          fetching={fetching}
        />
         </div>
    </div>
    </div>
    <div className="col-md-8">
     <div className="card mb-4 box-shadow cardgrey">
      <div className="card-body">
        <SingleCalculationResultEquilibriumPlot
          fetching={fetching}
          steelValues={this.state.steelValues}
          results={singleCalcResults}
        />
       </div>
      </div>
    </div>
  </div>
  );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculationEquilibrium; // Bad type
  return { singleCalcResults, fetching };
};

// dispatch updates the state of an app
const mapDispatchToProps = (dispatch: any) => ({
  fetchSingleCalculation: (steelValues: SteelValuesEquilibrium) =>
    dispatch(initFetchSingleCalculation(steelValues)) // initFetchSingleCalculation as an action creator
});

const SingleCalculationContainerEquilibrium = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCalculationEquilibrium);

export default SingleCalculationContainerEquilibrium;
