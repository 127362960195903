import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import GridViewCCT from '../components/CCTGridView';
import BatchCalculationResultCCTArbCRPlot from '../components/BatchCalculationResultsCCTArbCRPlot';
import { initFetchBatchCalculation } from 'src/redux/modules/calculationBatchCCTArbCR';
import {
  SteelValuesArbCR,
  SingleCalculationResultsCCTArbCR
} from 'src/types/Calculation';


interface Props {
  steelValues:SteelValuesArbCR;
  fetching: boolean;
  singleCalcResults: SingleCalculationResultsCCTArbCR[] | null;
  fetchBatchCalculation: (steelValues: SteelValuesArbCR[]) => Promise<any>;
}

class BatchCalculationCCTArbCR extends React.Component<Props> {
  state = {steelValues:[]}  
  callbackFunction = (childData:SteelValuesArbCR[]) => {
    this.setState({steelValues: childData})
  }
  
  public render() {
    const { fetchBatchCalculation,singleCalcResults,  fetching } = this.props;
    
    return (
      <>
  <div className="row">
  <GridViewCCT
          onSubmitForm={fetchBatchCalculation}
          parentCallback = {this.callbackFunction}
          fetching={fetching}
        />
  </div>

  <div className="row">
      
    <div className="col-md-12">
     <div className="card mb-4 box-shadow cardgrey">
      <div className="card-body">
      <BatchCalculationResultCCTArbCRPlot
          fetching={fetching}
          steelValues={this.state.steelValues}
          results={singleCalcResults}
        />
       </div>
      </div>
    </div>
  </div>
  </>
  );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.batchCalculationCCTArbCR; // Bad type
  return { singleCalcResults, fetching };
};

// dispatch updates the state of an app
const mapDispatchToProps = (dispatch: any) => ({
  fetchBatchCalculation: (steelValues: SteelValuesArbCR[]) =>
    dispatch(initFetchBatchCalculation(steelValues)) // initFetchSingleCalculation as an action creator
});

const BatchCalculationContainerCCTArbCR = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchCalculationCCTArbCR);

export default BatchCalculationContainerCCTArbCR;
