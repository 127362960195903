import * as React from 'react';

import { User } from '../types/User';




interface TopBarProps {
  logout: () => void;
  user: User | null;
  children?: any;
}

const Footer: React.SFC<TopBarProps> = (props: TopBarProps) => {
  const { } = props;
  const logo: string = require("../images/Ferritico_wordmark_final.svg").default;
  return (
    <footer className="text-muted bg-dark py-3 text-center text-lg-start">
     <div className="container p-4">
     
    <div className="row">
    <div className="col-lg-12 col-md-12 mb-4 mb-md-0">
     <a href="#">
     {/* <img src="/static/media/Ferritico_wordmark_final.accc3842.svg" width="160 px" /> */}
     <img src={logo} alt="logo" width="160px" />
     </a>
     </div>
    </div>

    <div className="row  mt-5">
    
     <div className="col-lg-4 col-md-4 mb-4 mb-md-0">
      <p >
       <a href="mailto:contact@ferritico.com" className="text-muted">Support</a>
      </p>
     </div>
    
     <div className="col-lg-4 col-md-4 mb-4 mb-md-0">
      <p >
      Phone: +46 (0)8 121 530 03
      </p>
     </div>
     <div className="col-lg-4 col-md-4 mb-4 mb-md-0">
      <p >
      © 2021-2022 All rights reserved by Ferritico.
      </p>
     </div>
    </div>

     </div>     
    </footer>
   );
};

export default Footer;
