export const colors = {
  accent: '#011e44',
  accentDark: '#000c17',
  background: '#f7f7f7',
  backgroundDark: '#d8d8d8',
  backgroundDarker: '#e8e0e3',
  buttonBackground: '#1890ff',
  white: '#fff'
};

export const breakpoints = {
  xs: 0,
  // tslint:disable-next-line:object-literal-sort-keys
  sm: 750,
  md: 970,
  lg: 1170
};
