import React, {Component} from 'react'
import styled from 'styled-components';
import {  Collapse} from 'react-bootstrap'

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
`;

class CollapseDivDash extends Component <{ divcontent?: string }>{


  

    state={ 
        open:false
       }

       

       toggleSecondaryElementsDisplay = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        this.setState({
          open: !this.state.open
        });
      };

    render(){
      return(
        <div className= "container" id="divdashcontainer">
           <div id="dash_btn_div_display" onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.toggleSecondaryElementsDisplay(event)
                }>
               <Header>{this.props.divcontent}</Header>

              
           </div>

           <Collapse className="mt-5" in={this.state.open}>
               <div>
               {this.props.children}
               </div>
           </Collapse>
        </div>
        );
       }
}

export default CollapseDivDash