import * as React from 'react';
import Spinner from 'react-spinkit';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';
import { SingleCalculationResultsHardenability } from '../types/Calculation';
import Button from './form/Button';

const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 64px;

  h3 {
    min-height: 14px;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 14px;
  }
`;

const ResultStatus = styled.div`
  font-size: 40px;
`;

const ResultsTable = styled.div`
  margin-bottom: 64px;
`;

const ResultsTableHead = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
`;

const ResultsTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const ResultsTableCol = styled.div`
  flex: 1;
`;

interface Props {
  fetching: boolean;
  steelValues:any;
  results: SingleCalculationResultsHardenability | null;
}

const renderHardness = ( hardness: any, index: number) => {
  const hrc = hardness[index];
  const hardnessUnit = hrc.toString().replace('m', '');

  return `${parseFloat(hardnessUnit).toFixed(2)} `;
};

const renderContent = (props: Props) => {
  function exportToTxt(){
    if (props.results && !props.fetching) {
      const depthsFile = props.results.Result.hardenability.jominy_m
    const hardnessFile = props.results.Result.hardenability.hardness
      let data ='Composition: \n'+JSON.stringify(props.steelValues).slice(1,-1);
      data = data.split('depths')[0] +'\n\n\n\nHardenability\nDepth(M)  \tHardness (HV)\n';
      depthsFile.forEach((element,i) => {
        data = data + parseFloat(element).toFixed(2) + ' \t'+ parseFloat(hardnessFile[i]).toFixed(2)+'\t'+'\n'
      }); 
  
      const file = new Blob([data], { type: 'data:application/vnd.ms-word;charset=utf-8' });

      if ((window.navigator as any).msSaveOrOpenBlob){ // IE10+
        (window.navigator as any).msSaveOrOpenBlob(file, 'my_data.doc');
    }
  else { // Others
      const link = document.createElement('a');
      const url = URL.createObjectURL(file);
      link.href = url;
      link.download = 'my_data.doc';
      document.body.appendChild(link);
      link.click();
      setTimeout(e => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);  
      }, 0); 
      }
  }
}
  if (props.results && !props.fetching) {
    const data =JSON.stringify(props.steelValues).slice(1,-1);
    const strarray = data.split(',');
    const depths = props.results.Result.hardenability.jominy_m
    const hardness = props.results.Result.hardenability.hardness
    const sum = hardness.reduce((a, b) => a + b, 0);
    const avg = (sum / hardness.length) || 0;
    console.log(sum)
    console.log(avg)
    return (
      <>

<div className="row">
        <div className="col-md-12 col-sm-12"><Header>Input         
  </Header></div>
  </div>

<div className="row">
  
  <div className="col-md-12 col-sm-12 mt-md-3 compoverflow">
   <ResultsTable id="inputcomp">
     <ResultsTableHead className="inputcomptable">
       <ResultsTableCol className="inputcompcell">Al</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">B</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">C</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Co</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Cr</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Cu</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Mn</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Mo</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">N</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Nb</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Ni</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">P</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">S</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Si</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Ti</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">V</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">W</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Aus temp</ResultsTableCol>
       {
       strarray.map((dataInput: any) => (
          
         dataInput.split(':')[0] ==='"aus_time"' ?
         <ResultsTableCol className="inputcompcell">Aus time</ResultsTableCol>
           :
           ''
         
       ))}
     </ResultsTableHead>
     <ResultsTableRow>
     {
     strarray.map((dataInput: any) => (
        
       dataInput.split(':')[0] !=='"At"' && dataInput.split(':')[0] !=='"crs"' && dataInput.split(':')[0] !=='"crs_end"' && dataInput.split(':')[0] !=='"crs_start"' && dataInput.split(':')[0] !=='"is_time"' && dataInput.split(':')[0] !=='"average_option"' && dataInput.split(':')[0] !=='"depths"' && dataInput.split(':')[0] !=='"hardenability_option"' && isNaN(dataInput) && dataInput.slice(-1) !==']' && dataInput.includes(':')?
         <ResultsTableCol>
           {dataInput.split(':')[1]}
         </ResultsTableCol>
         :
         ''
       
     ))}
     </ResultsTableRow>
   </ResultsTable>
   </div>
 
 </div>

      <div className="row">
        <div className="col-md-6 col-sm-12"><Header>Results         
  </Header></div>
  <div className="col-md-6 col-sm-12">
  <Button width="45%" float="right" height="2rem" download={true} onClick={exportToTxt}>Export</Button>
  </div>
  </div>
        <div>
          <h5 className="mt-md-4 mb-4">Average: {avg.toFixed(2)} HV</h5>
         <div className="mt-md-3">
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableCol className="lowercase">DEPTH (m)</ResultsTableCol>
              <ResultsTableCol>Hardness (HV)</ResultsTableCol>
            </ResultsTableHead>
            {depths.map((depthsValue: any, index: number) => (
              
              <ResultsTableRow>
                <ResultsTableCol>
                  {parseFloat(depthsValue).toFixed(4)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(hardness, index || 0)}
                </ResultsTableCol>
              </ResultsTableRow>
            ))}
          </ResultsTable>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Header>Results</Header>
        <Placeholder>
          {props.fetching ? (
            <>
              <ResultStatus>
                <Spinner fadeIn="none" name="circle" />
              </ResultStatus>
              <h3>Simulating</h3>
            </>
          ) : (
            <>
              <ResultStatus>-</ResultStatus>
              <h3>Fill in your values</h3>
            </>
          )}
        </Placeholder>
      </>
    );
  }
};

const SingleCalculationResultHardenability = (props: Props) => {
  return <ContentWrapper>{renderContent(props)}</ContentWrapper>;
};

export default SingleCalculationResultHardenability;
