import * as React from 'react';
import styled from 'styled-components';
import { Formik, Field, Form, FormikHelpers as FormikActions } from 'formik';
import * as R from 'ramda';

import { colors } from '../shared/themeStyles';
import Button from '../components/form/Button';

import { SteelValues } from '../types/Calculation';

const ContentWrapper = styled.div`
  flex-basis: 32.6%; /* 313/960 */
  padding: 1.5rem 1rem;
  background-color: ${colors.backgroundDarker};
`;

const SectionTitle = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const ButtonWithMargin = styled(Button)`
  margin-bottom: 1rem;
`;

const SubText = styled.span`
  text-transform: uppercase;
  display: block;
  font-size: 9px;
  margin-bottom: 1rem;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  > label {
    font-size: 14px;
    text-align: right;
    margin-right: 1rem;
  }

  > input {
    width: 60px;
    height: 32px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }

  > span {
    margin-left: 5px;
    color: ${colors.accentDark};
  }
`;

const PrimaryInputWrapper = styled(InputWrapper as any)`
  flex: 1 0 100%;

  > label {
    flex-basis: 13%;
  }
`;

const SecondaryInputWrapper = styled(InputWrapper as any)`
  flex: 1 0 50%;

  > label {
    flex-basis: 26%;
  }
`;

const initialValues: SteelValues = {
  Al: 0,
  At: 0,
  B: 0,
  C: 0,
  Co: 0,
  Cr: 0,
  Cu: 0,
  MT: 0,
  Mn: 0,
  Mo: 0,
  N: 0,
  Nb: 0,
  Ni: 0,
  P: 0,
  S: 0,
  Si: 0,
  T_TH: 0,
  T_TP: 0,
  Ti: 0,
  V: 0,
  W: 0
};

const primaryElements: string[] = ['C', 'Mn', 'Si', 'Cr', 'Ni'];

const secondaryElements: string[] = [
  'Mo',
  'V',
  'Co',
  'Al',
  'W',
  'Cu',
  'Nb',
  'Ti',
  'N',
  'B',
  'P',
  'S'
];

const renderElementInput = (element: string) => (
  <>
    <label htmlFor={element}>{element}: </label>
    <Field
      id={element}
      name={element}
      type="number"
      min="0"
      step=".01"
      autoComplete="off"
    />{' '}
    <span>%</span>
  </>
);

const renderPrimaryElementInput = (element: string) => (
  <PrimaryInputWrapper key={element}>
    {renderElementInput(element)}
  </PrimaryInputWrapper>
);

const renderSecondaryElementInput = (element: string) => (
  <SecondaryInputWrapper key={element}>
    {renderElementInput(element)}
  </SecondaryInputWrapper>
);

interface Props {
  onSubmitForm: (steelValues: SteelValues) => Promise<any>;
  fetching: boolean;
}

interface State {
  displaySecondaryelements: boolean;
}

class ChemicalComposition extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { displaySecondaryelements: false };
  }

  toggleSecondaryElementsDisplay = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({
      displaySecondaryelements: !this.state.displaySecondaryelements
    });
  };

  render() {
    const { displaySecondaryelements } = this.state;
    const { onSubmitForm, fetching } = this.props;

    return (
      <ContentWrapper>
        <SectionTitle>Chemical Composition</SectionTitle>
        <SubText>Weight percentages</SubText>
        <Formik
          initialValues={initialValues}
          onSubmit={(
            values: SteelValues,
            { setSubmitting }: FormikActions<SteelValues>
          ) => {
            // any null values (empty strings) will be converted to 0
            const nullsToZero = (val: any) => (!val ? 0 : val);
            // @ts-ignore
            const formattedValues = R.map(nullsToZero, values);

            onSubmitForm(formattedValues as any).then(() => {
              setSubmitting(false);
            });
          }}
          render={() => (
            <Form>
              <InputsWrapper>
                {primaryElements.map(ele => renderPrimaryElementInput(ele))}
              </InputsWrapper>
              <ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.toggleSecondaryElementsDisplay(event)
                }
                secondary
              >
                {displaySecondaryelements
                  ? 'Hide Elements'
                  : 'View All Elements'}
              </ButtonWithMargin>
              {displaySecondaryelements && (
                <InputsWrapper>
                  {secondaryElements.map(ele =>
                    renderSecondaryElementInput(ele)
                  )}
                </InputsWrapper>
              )}
              <SectionTitle>Annealing Temperature</SectionTitle>
              <InputsWrapper>
                <PrimaryInputWrapper>
                  <Field
                    id="At"
                    name="At"
                    type="number"
                    step="10"
                    autoComplete="off"
                  />{' '}
                  <span>°C</span>
                </PrimaryInputWrapper>
              </InputsWrapper>
              <SectionTitle>Quenching Temperature</SectionTitle>
              <InputsWrapper>
                <PrimaryInputWrapper>
                  <Field
                    id="MT"
                    name="MT"
                    type="number"
                    step="10"
                    autoComplete="off"
                  />{' '}
                  <span>°C</span>
                </PrimaryInputWrapper>
              </InputsWrapper>
              <SectionTitle>Tempering Temperature & Time</SectionTitle>
              <InputsWrapper>
                <SecondaryInputWrapper>
                  <Field
                    id="T_TP"
                    name="T_TP"
                    type="number"
                    step="10"
                    autoComplete="off"
                  />{' '}
                  <span>°C</span>
                </SecondaryInputWrapper>
                <SecondaryInputWrapper>
                  <Field
                    id="T_TH"
                    name="T_TH"
                    type="number"
                    step=".01"
                    autoComplete="off"
                  />{' '}
                  <span>hrs</span>
                </SecondaryInputWrapper>
              </InputsWrapper>
              <Button disabled={fetching}>
                {fetching ? 'Loading...' : 'Simulate'}
              </Button>
            </Form>
          )}
        />
      </ContentWrapper>
    );
  }
}

export default ChemicalComposition;
