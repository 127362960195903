import * as auth0 from 'auth0-js';

import { User } from './types/User';
import config from './config';

class Auth {
  webAuth: auth0.WebAuth;
  profile: User;
  idToken: string;
  accessToken: string;
  expiresAt: number;

  constructor() {
    this.webAuth = new auth0.WebAuth({
      clientID: 'cAMKBUEgzKhj6eboaGv9qEh6zw314He5',
      domain: 'ferritico.eu.auth0.com',
      redirectUri: `${config.REACT_APP_BASEURL}/auth-callback`,
      responseType: 'token id_token',
      scope: 'openid profile email email_verified'
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  getAccessToken() {
    return this.accessToken;
  }

  setSession(authResult: any) {
    this.idToken = authResult.idToken;
    this.accessToken = authResult.accessToken;

    this.profile = {
      email: authResult.idTokenPayload.email,
      emailVerified: authResult.idTokenPayload.email_verified,
      name: authResult.idTokenPayload.name,
      picture: authResult.idTokenPayload.picture,
      roles: authResult.idTokenPayload['https://ferritico.com/roles']
    };

    // set the time that the id token will expire at
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
  }

  handleAuthentication() {
    return new Promise<void>((resolve, reject) => {
      this.webAuth.parseHash((err, authResult) => {
        if (err) {
          return reject(err);
        }
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    });
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  signIn() {
    this.webAuth.authorize();
  }

  signOut() {
    this.webAuth.logout({
      clientID: 'cAMKBUEgzKhj6eboaGv9qEh6zw314He5',
      returnTo: `${config.REACT_APP_BASEURL}`
    });
  }

  silentAuth() {
    return new Promise<void>((resolve, reject) => {
      this.webAuth.checkSession({}, (err, authResult) => {
        if (err) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
