import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import CollapseDiv from '../components/Collapse';
import ChemicalCompositionTTT from '../components/ChemicalCompositionTTT';
import SingleCalculationResultTTTPlot from '../components/SingleCalculationResultsTTTPlot';
import { initFetchSingleCalculation } from 'src/redux/modules/calculationTTT';

import {
  SteelValuesTTT,
  SingleCalculationResultsTTT
} from 'src/types/Calculation';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

interface Props {
  steelValues:SteelValuesTTT;
  fetching: boolean;
  singleCalcResults: SingleCalculationResultsTTT | null;
  fetchSingleCalculation: (steelValues: SteelValuesTTT) => Promise<any>;
}

class SingleCalculationTTT extends React.Component<Props> {
  state = {steelValues:[]}  
  callbackFunction = (childData:SteelValuesTTT) => {
    this.setState({steelValues: childData})
  }
  
  public render() {
    const { fetchSingleCalculation, singleCalcResults, fetching } = this.props;
    
    return (
      
  <div className="row">
      <div className="col-md-4">
   <div className="card mb-4 box-shadow cardgrey">
    <div className="card-body">
        <ChemicalCompositionTTT
          onSubmitForm={fetchSingleCalculation}
          parentCallback = {this.callbackFunction}
          fetching={fetching}
        />
         </div>
    </div>
    </div>
    <div className="col-md-8">
     <div className="card mb-4 box-shadow cardgrey">
      <div className="card-body">
        <SingleCalculationResultTTTPlot
          fetching={fetching}
          steelValues={this.state.steelValues}
          results={singleCalcResults}
        />

<ContentWrapper id="tttdivs" className="hidedivs pt-0 mt-0">

<CollapseDiv divcontent="TTT Diagram" icon="diagram">         
      <div id="tttDiv"></div>
      </CollapseDiv>
      
      </ContentWrapper>      

       </div>
      </div>
    </div>

    


  </div>
  );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculationTTT; // Bad type
  return { singleCalcResults, fetching };
};

// dispatch updates the state of an app
const mapDispatchToProps = (dispatch: any) => ({
  fetchSingleCalculation: (steelValues: SteelValuesTTT) =>
    dispatch(initFetchSingleCalculation(steelValues)) // initFetchSingleCalculation as an action creator
});

const SingleCalculationContainerTTT = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCalculationTTT);

export default SingleCalculationContainerTTT;
