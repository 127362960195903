
import React from "react";
// import ReactDOMServer from "react-dom/server";
import Spinner from 'react-spinkit';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';
import { SingleCalculationResultsCCTArbCR } from '../types/Calculation';

import Button from '../components/form/Button';
import * as Plotly from 'plotly.js';
import CollapseDiv from './Collapse';

const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 64px;

  h3 {
    min-height: 14px;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 14px;
  }
`;

const ResultStatus = styled.div`
  font-size: 40px;
`;

const ResultsTable = styled.div`
  margin-bottom: 64px;
`;

const ResultsTableHead = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
`;

const ResultsTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const ResultsTableCol = styled.div`
  flex: 1;
`;

interface Props {
  fetching: boolean;
  isStaticPlot:boolean|false;
  steelValues:any;
  results: SingleCalculationResultsCCTArbCR | null;
}


const renderResponsePerc = (perc: string | number) => {
  const normalizedPercAmount = perc.toString().replace('%', '');

  return `${normalizedPercAmount} %`;
};

const renderResponseDegree = (perc: string ) => {
  const normalizedPercAmount = parseFloat(perc).toFixed(2);

  return `${normalizedPercAmount}`;
};





const renderContent = (props: Props) => {

  
  
  function exportToTxt(){
    if (props.results && !props.fetching) {
      let data ='Composition: \n'+JSON.stringify(props.steelValues).slice(1,-1);;
      data = data +'\n\n\n\nFractions\nCR  \tF%  \tP%  \tB%  \tM%  \tA%\n';
      const fractions = props.results.Result.cooling_curves.map(e => e.perc)
      const hardness = props.results.Result.hardness
      const CRs=props.results.Result.cooling_curves.map(e => e.CR)
      const temps=props.results.Result.cooling_curves.map(e => e.tempsObj)
      
      


      fractions.forEach((element,i) => {
        data = data + Math.round(CRs[i]*100)/100 + '\t'+ element.F.slice(0,-2)+'\t'+ element.P.slice(0,-2)+'\t'+ element.B.slice(0,-2)+'\t'+ element.M.slice(0,-2)+'\t'+ element.A.slice(0,-2)+'\n'
      });
      data = data+'\n\n\n Phase Transformation\n';
      data = data+'CR\tFe_s\tFe_f\tP_s\tP_f\tB_s\tB_f\tM_s\n'
      temps.forEach((element,i) => {
        data = data + Math.round(CRs[i]*100)/100 + '\t'+ Number(element.Fe_s).toFixed(1)+'\t'+ Number(element.Fe_f).toFixed(1)+'\t'+ Number(element.P_s).toFixed(1)+'\t'+ Number(element.P_f).toFixed(1)+'\t'+ Number(element.B_s).toFixed(1)+'\t'+ Number(element.B_f).toFixed(1)+'\t'+ Number(element.M_s).toFixed(1)+'\n'
      });
      data = data+'\n\n\n Hardness (HV)\n';
      hardness.forEach((element,i) => {
        data = data + Math.round(CRs[i]*100)/100 + '\t'+ Number(element).toFixed(1)+'\n'
      });
      const file = new Blob([data], { type: 'data:application/vnd.ms-word;charset=utf-8' });

      if ((window.navigator as any).msSaveOrOpenBlob){ // IE10+
        (window.navigator as any).msSaveOrOpenBlob(file, 'my_data.doc');
    }
  else { // Others
      const link = document.createElement('a');
      const url = URL.createObjectURL(file);
      link.href = url;
      link.download = 'my_data.doc';
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);  
      }, 0); 
      }
  }
}
  if (props.results && !props.fetching) {
    
  
    let divs = document.getElementById('cctdivs');
    if (divs !== null) {
    divs.style.display = "block";
    }

      const data =JSON.stringify(props.steelValues).slice(1,-1);
    const strarray = data.split(',');  
    
    // const element = document.getElementById('tableDiv');
    // const tableHtml = renderTable;
    // if(element != null){
    //   element.innerHTML +=tableHtml;
    // }
    
    return (
      <>
      <div className="row">
        <div className="col-md-12 col-sm-12"><Header>Input         
  </Header></div>
  </div>

  
 
  <div className="row">
  
    <div className="col-md-12 col-sm-12 mt-md-3 compoverflow">
     <ResultsTable id="inputcomp">
       <ResultsTableHead className="inputcomptable">
         <ResultsTableCol className="inputcompcell">Al</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">B</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">C</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Co</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Cr</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Cu</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Mn</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Mo</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">N</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Nb</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Ni</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">P</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">S</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Si</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Ti</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">V</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">W</ResultsTableCol>
         <ResultsTableCol className="inputcompcell">Aus temp</ResultsTableCol>
         {
       strarray.map((dataInput: any) => (
          
         dataInput.split(':')[0] ==='"aus_time"' ?
         <ResultsTableCol className="inputcompcell">Aus time</ResultsTableCol>
           :
           ''
         
       ))}

       </ResultsTableHead>
       <ResultsTableRow>
       {
       strarray.map((dataInput: any) => (
          
         dataInput.split(':')[0] !=='"At"' && dataInput.split(':')[0] !=='"crs"' && dataInput.split(':')[0] !=='"crs_end"' && dataInput.split(':')[0] !=='"crs_start"' && dataInput.split(':')[0] !=='"average_option"' && dataInput.split(':')[0] !=='"is_time"' && dataInput.slice(-1) !==']' && dataInput.includes(':')?
           <ResultsTableCol>
             {dataInput.split(':')[1]}
           </ResultsTableCol>
           :
           ''
         
       ))}
       </ResultsTableRow>
     </ResultsTable>
     </div>
   
   </div>
<div className="row">
   <div className="col-md-6 col-sm-12"><Header>Results         
  </Header></div>



  
  <div className="col-md-6 col-sm-12 md-3 mb-5">
  <Button width="45%" float="right" height="2rem" download={true} onClick={exportToTxt}>Export</Button>
  </div>
  </div>

  
  

{
        <Placeholder className="mb-0">
          {props.results.Result && props.results.Result.plot_image ? (
            <CollapseDiv divcontent="CCT and Hardness" icon="staticImg">
            <ResultsWrapper>
              <img
                style={{ width: '100%' }}
                src={`data:image/png;base64, ${props.results.Result.plot_image}`}
              />
            </ResultsWrapper>
            </CollapseDiv>
          ) : null}
        </Placeholder>
        
         }

<div className="collapse-adjust">
<CollapseDiv divcontent="Phase Fractions" icon="table">
        <div>
         <div className="mt-md-3 p-3">
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableCol>CR (°C / s)</ResultsTableCol>
              <ResultsTableCol>F</ResultsTableCol>
              <ResultsTableCol>P</ResultsTableCol>
              <ResultsTableCol>B</ResultsTableCol>
              <ResultsTableCol>M</ResultsTableCol>
              <ResultsTableCol>A</ResultsTableCol>
            </ResultsTableHead>
            {props.results.Result.cooling_curves.reverse().map((coolingCurve: any) => (
              <ResultsTableRow>
                <ResultsTableCol>
                  {parseFloat(coolingCurve.CR).toFixed(2)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponsePerc(coolingCurve.perc.F || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponsePerc(coolingCurve.perc.P || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponsePerc(coolingCurve.perc.B || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponsePerc(coolingCurve.perc.M || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponsePerc(coolingCurve.perc.A || 0)}
                </ResultsTableCol>
              </ResultsTableRow>
            ))}
          </ResultsTable>
          </div>
        </div>
        </CollapseDiv>
        </div>
      

        <div className="collapse-adjust mt60">
<CollapseDiv divcontent="Phase Transformations" icon="table">
        <div>
         <div className="mt-md-3 p-3">
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableCol>CR (°C / s)</ResultsTableCol>
              <ResultsTableCol>Fe_s</ResultsTableCol>
              <ResultsTableCol>Fe_f</ResultsTableCol>
              <ResultsTableCol>P_s</ResultsTableCol>
              <ResultsTableCol>P_f</ResultsTableCol>
              <ResultsTableCol>B_s</ResultsTableCol>
              <ResultsTableCol>B_f</ResultsTableCol>
              <ResultsTableCol>M_s</ResultsTableCol>
            </ResultsTableHead>
            {props.results.Result.cooling_curves.map((coolingCurve: any) => (
              <ResultsTableRow>
                <ResultsTableCol>
                  {parseFloat(coolingCurve.CR).toFixed(2)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponseDegree(coolingCurve.tempsObj.Fe_s || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponseDegree(coolingCurve.tempsObj.Fe_f || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponseDegree(coolingCurve.tempsObj.P_s || 0)}
                </ResultsTableCol>  
                <ResultsTableCol>
                  {renderResponseDegree(coolingCurve.tempsObj.P_f || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponseDegree(coolingCurve.tempsObj.B_s || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponseDegree(coolingCurve.tempsObj.B_f || 0)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderResponseDegree(coolingCurve.tempsObj.M_s || 0)}
                </ResultsTableCol>
              </ResultsTableRow>
            ))}
          </ResultsTable>
          </div>
        </div>
        </CollapseDiv>
        </div>




      </>
      
    );
  } else {
    
    let divs = document.getElementById('cctdivs');
    if (divs !== null) {
    divs.style.display = "none";
    
    }
    
    return (
      
      <>
        <Header>Results</Header>
        <Placeholder>
          {props.fetching ? (
            <>
              <ResultStatus>
                <Spinner fadeIn="none" name="circle" />
              </ResultStatus>
              <h3>Simulating</h3>
            </>
          ) : (
            <>
              <ResultStatus>-</ResultStatus>
              <h3>Fill in your values</h3>
            </>
          )}
          
        </Placeholder>  
      </>
    );

  }
};

  const renderPlotly = (props: Props) =>{

  

  var phase_colors = {Fe_s: "#1890ff", Fe_f: "#1890ff",  
  F_s: "#1890ff", F_f: "#1890ff",
                    P_s: "#7DD77B", P_f: "#7DD77B",  
                    B_s: "#DD5767", B_f: "#DD5767",  
                    M_s: "#001e44", M_f: "#001e44",  
                    aus_temp: "k",
                    Room: "C3"}

  let colors: any[] = [];
    
  if (props.results && !props.fetching) {
    
    
    const CRs4Plot=props.results.Result.cooling_curves
      const CrLabels=props.results.Result.cooling_curves.map(e => e.labels)
      const CRs=props.results.Result.cooling_curves.map(e => e.CR)
      const AC1=Math.round(props.results.Result.cooling_curves[0].Ac1*100)/100
      
      const AC3=Math.round(props.results.Result.cooling_curves[0].Ac3*100)/100
      // const AC1=Math.round(props.results.Result.Ac1*100)/100
      // const AC3=Math.round(props.results.Result.Ac3*100)/100
      // const echoData =props.results.Result.echo;
      const CrTemps=props.results.Result.cooling_curves.map(e => e.temps)
      const CrTimes=props.results.Result.cooling_curves.map(e => e.times)
      let fMax = 0;
      let pMax = 0;
      let bMax = 0;
      let mMax = 0;
      
      const CrHardness=props.results.Result.hardness
      
      CrLabels.forEach((element1:any, k:any) => {

        var y = CrTemps[k];

        if (element1 == "aus_temp"){
          colors.push(phase_colors[element1]);
        }
        else if (element1 == "Room"){
          colors.push(phase_colors[element1]);
        }
        else if (k < (CrLabels.length-1) && y == y[k+1]){
          colors.push(phase_colors[element1]);
        }
        else if (y == CrTemps[k-1]){
          colors.push(phase_colors[element1]);
        }
        else{
          if(element1 in ["Fe_f", "P_f", "B_f"]){
            colors.push(phase_colors[element1]);
          }else{
            colors.push(phase_colors[element1]);
          }
          
        }
      });


      let plotdata: any[];
      plotdata = [];  
      let fractiondata: any[];
      fractiondata = [];  
      
      let hardnessdata: any[];
      hardnessdata = [];  

      

      let hardnesstrace = {};
      hardnesstrace = {
        x: props.results.Result.cooling_curves.reverse().map(e => Math.round(e.CR*100)/100),
        y: CrHardness,
        type: 'scatter',
        showlegend: false,
        line: {
          color: '#1890ff',
          width: 3
        }
      };
      hardnessdata.push(hardnesstrace);

      var hardnesslayout = {
        xaxis: { title: "Cooling rate °C/sec"},
        yaxis: { title: "Hardness (HV)"},
        
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        // title: " Hardness ",
        
      };
    
      Plotly.newPlot("hardnessDiv", hardnessdata, hardnesslayout)



      let ausdata: any[] = [];
      let baindata: any[] = [];
      let ferdata: any[] = [];
      let peardata: any[] = [];
      let martdata: any[] = [];
       
      let crdata: any[] = [];
       
      
      
      props.results.Result.cooling_curves.map((coolingCurve: any) => {
        ausdata.push(Number(coolingCurve.perc.A.replace(' %', '')));
        ferdata.push(Number(coolingCurve.perc.F.replace(' %', '')));
        peardata.push(Number(coolingCurve.perc.P.replace(' %', '')));
        baindata.push(Number(coolingCurve.perc.B.replace(' %', '')));
        martdata.push(Number(coolingCurve.perc.M.replace(' %', '')));
        crdata.push(Math.round(coolingCurve.CR*100)/100);

      });



      let austrace = {};
        austrace = {
        x: crdata.map(String).map(i => 'CR ' + i),
        y: ausdata,
        type: 'bar',
        marker: {
          color: '#CF8BC4'
        },
        name: 'Austenite'
      };
      let fertrace = {};
        fertrace = {
        x: crdata.map(String).map(i => 'CR ' + i),
        y: ferdata,
        type: 'bar',
        marker: {
          color: phase_colors['Fe_s']
        },
        name: 'Ferrite'
      };
      let peartrace = {};
        peartrace = {
        x: crdata.map(String).map(i => 'CR ' + i),
        y: peardata,
        type: 'bar',
        marker: {
          color: phase_colors['P_s']
        },
        name: 'Pearlite'
      };
      let baintrace = {};
        baintrace = {
        x: crdata.map(String).map(i => 'CR ' + i),
        y: baindata,
        type: 'bar',
        marker: {
          color: phase_colors['B_s']
        },
        name: 'Bainite'
      };
      let marttrace = {};
        marttrace = {
        x: crdata.map(String).map(i => 'CR ' + i),
        y: martdata,
        type: 'bar',
        marker: {
          color: phase_colors['M_s']
        },
        name: 'Martensite'
      };


      var fractionslayout = {
        barmode: 'stack' as const,
        xaxis: { 
        tickangle: -45},
    yaxis: { title: "Fraction %", range: [0, 100]},
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        // title: " Hardness ",
        // bargap :0.05
      };
    
      fractiondata=[austrace, fertrace, peartrace, baintrace, marttrace];

      Plotly.newPlot("fractionsDiv", fractiondata, fractionslayout)











      CRs4Plot.forEach((elementsCR:any, i:any) => {
        plotdata = [];

        CrLabels.forEach((elements:any, j:any) => {
        
      
        let trace = {};
        let xArray:[number] = CrTimes[j];
        let yArray:[number] = CrTemps[j];

        elements.forEach((label:any, z:any) => {
          let labelString:String =  label.toString();
          let labelStringNext:String ='';
          if(z < elements.length-1 ){
            labelStringNext =  elements[z+1].toString();
          }

          
        if (label != "Room" && label != "aus_temp"){
        
          if (label == "P_f" && elements[z+1] == "Room"){

          }else if (label == "B_f" && elements[z+1] == "Room"){

          }else if (label == "Fe_f" && elements[z+1] == "Room"){

          }else if (labelString.charAt(labelString.length-1) == "f" && labelStringNext.charAt(labelStringNext.length-1) == "s"){
            
          }else{
        
          trace = {
          x: xArray.slice(z, z+2),
          y: yArray.slice(z, z+2),
          type: 'scatter',
          name: Math.round(CRs[j]*100)/100+':'+label,
          showlegend: false,
          line: {
            color: phase_colors[label],
            width: 3
          }
        };
        plotdata.push(trace);


        if (label == "P_s"){
          if(pMax < yArray[z]){
            pMax =yArray[z]
          }
          
        }else if (label == "F_s"){
          if(fMax < yArray[z]){
            fMax =yArray[z]
          }
        }else if (label == "M_s"){
          if(mMax < yArray[z]){
            mMax =yArray[z]
          }
          
        }else if (label == "B_s"){
          if(bMax < yArray[z]){
            bMax =yArray[z]
          }
          
        }

      }
        }

       
        
      });



        });
      

      });

      const ac1 = document.getElementById('ac1');
      if (ac1 !== null) {
      ac1.textContent = ""+AC1.toFixed(1);
      }
      const ac3 = document.getElementById('ac3');
      if (ac3 !== null) {
      ac3.textContent = ""+AC3.toFixed(1);
      }
      const fs = document.getElementById('fs');
      if (fs !== null) {
      fs.textContent = ""+fMax.toFixed(1);
      }
      const ps = document.getElementById('ps');
      if (ps !== null) {
      ps.textContent = ""+pMax.toFixed(1);
      }
      const bs = document.getElementById('bs');
      if (bs !== null) {
      bs.textContent = ""+bMax.toFixed(1);
      }
      const ms = document.getElementById('ms');
      if (ms !== null) {
      ms.textContent = ""+mMax.toFixed(1);
      }

      const inputdata =props.steelValues;

      var traceAT = {
        y: [inputdata.aus_temp,inputdata.aus_temp],
        x: [0, 100000],
        type: 'scatter',
        name: 'Aus_temp',
        line: {
          color: '#F9F871',
          dash: 'dot',
          width: 2
        }
      };
      plotdata.push(traceAT);

      var traceAc3 = {
        y: [AC3, AC3],
        x: [0, 100000],
        type: 'scatter',
        name: 'AC3',
        line: {
          color: '#87a1b1',
          dash: 'dot',
          width: 2
        }
      };
      
      plotdata.push(traceAc3);


      var traceAc1 = {
        y: [AC1, AC1],
        x: [0, 100000],
        type: 'scatter',
        name: 'AC1',
        line: {
          color: '#CF8BC4',
          dash: 'dot',
          width: 2
        }
      };
      plotdata.push(traceAc1);

      var traceFs = {
        y: [fMax, fMax],
        x: [0, 100000],
        type: 'scatter',
        name: 'Fs',
        line: {
          color: '#007C90',
          dash: 'dot',
          width: 2
        }
      };
      plotdata.push(traceFs);

      var tracePs = {
        y: [pMax, pMax],
        x: [0, 100000],
        type: 'scatter',
        name: 'Ps',
        line: {
          color: '#7DD77B',
          dash: 'dot',
          width: 2
        }
      };
      plotdata.push(tracePs);

      var traceBs = {
        y: [bMax, bMax],
        x: [0, 100000],
        type: 'scatter',
        name: 'Bs',
        line: {
          color: '#DD5767',
          dash: 'dot',
          width: 2
        }
      };
      plotdata.push(traceBs);
      
      var traceMs = {
        y: [mMax, mMax],
        x: [0, 100000],
        type: 'scatter',
        name: 'Ms',
        line: {
          color: '#001e44',
          dash: 'dot',
          width: 2
        }
      };
      plotdata.push(traceMs);


      

     
      
  var layout = {
    xaxis: { title: "Time sec",   type: "log" as const},
    yaxis: { title: "Temperature °C", range: [0, 1300]},
    // legend: {
    //   x: 1,
    //   xanchor: 'right' as const,
    //   y: 0.1
    // },
    showlegend: true,
		legend: {   
      y: -0.3,
      "orientation": "h" as const,
    },
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    // title: " CCT Diagram ",

  };
  
  Plotly.newPlot("myDiv", plotdata, layout)
}

}

const SingleCalculationResultCCTArbCR = (props: Props) => {
  let finalHtml = <ContentWrapper>{renderContent(props)}</ContentWrapper> ; 
  
  renderPlotly(props);
  return finalHtml;
};

export default SingleCalculationResultCCTArbCR;
