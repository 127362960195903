import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import ChemicalCompositionCCTArbCR from '../components/ChemicalCompositionCCTArbCR';
import SingleCalculationResultCCTArbCRPlot from '../components/SingleCalculationResultsCCTArbCRPlot';
import { initFetchSingleCalculation } from 'src/redux/modules/calculationCCTArbCR';
import CollapseDiv from '../components/Collapse';
import {
  SteelValuesArbCR,
  SingleCalculationResultsCCTArbCR
} from 'src/types/Calculation';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const ResultsTable = styled.div`
  margin-bottom: 64px;
`;

const ResultsTableHead = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
`;

const ResultsTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const ResultsTableCol = styled.div`
  flex: 1;
`;

interface Props {
  steelValues:SteelValuesArbCR;
  fetching: boolean;
  singleCalcResults: SingleCalculationResultsCCTArbCR | null;
  fetchSingleCalculation: (steelValues: SteelValuesArbCR) => Promise<any>;
}

class SingleCalculationCCTArbCR extends React.Component<Props> {
  state = {steelValues:[]}  
  callbackFunction = (childData:SteelValuesArbCR) => {
    this.setState({steelValues: childData})
  }
  
  public render() {
    const { fetchSingleCalculation, singleCalcResults, fetching } = this.props;
    
    return (
      <>

  <div className="row">
      <div className="col-md-4">
   <div className="card mb-4 box-shadow cardgrey">
    <div className="card-body">
        <ChemicalCompositionCCTArbCR
          onSubmitForm={fetchSingleCalculation}
          parentCallback = {this.callbackFunction}
          fetching={fetching}
        />
         </div>
    </div>
    </div>
    <div className="col-md-8">
     <div className="card mb-4 box-shadow cardgrey">
      
      <div className="card-body">

        <SingleCalculationResultCCTArbCRPlot
          fetching={fetching}
          steelValues={this.state.steelValues}
          results={singleCalcResults}
          isStaticPlot={false}
        />

        <ContentWrapper id="cctdivs" className="hidedivs pt-0 mt-0">
        
        
<CollapseDiv divcontent="Critical Temperatures" icon="temp">
        <div>
         <div className="mt-md-4 p-3">
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableCol className="btn-outline-danger text-center"><h5>AC1</h5></ResultsTableCol>
              <ResultsTableCol className="btn-outline-danger text-center"><h5>AC3</h5></ResultsTableCol>
              <ResultsTableCol className="btn-outline-danger text-center"><h5>F_s</h5></ResultsTableCol>
              <ResultsTableCol className="btn-outline-danger text-center"><h5>P_s</h5></ResultsTableCol>
              <ResultsTableCol className="btn-outline-danger text-center"><h5>B_s</h5></ResultsTableCol>
              <ResultsTableCol className="btn-outline-danger text-center"><h5>M_s</h5></ResultsTableCol>
            </ResultsTableHead>
            
              <ResultsTableRow>
                <ResultsTableCol id="ac1" className="text-center">
                
                </ResultsTableCol>
                <ResultsTableCol id="ac3" className="text-center">
                
                </ResultsTableCol>
                <ResultsTableCol id="fs" className="text-center">
                
                </ResultsTableCol>
                <ResultsTableCol id="ps" className="text-center">
                
                </ResultsTableCol>  
                <ResultsTableCol id="bs" className="text-center">
                
                </ResultsTableCol>
                <ResultsTableCol id="ms" className="text-center">
                
                </ResultsTableCol>
              </ResultsTableRow>

          </ResultsTable>
          </div>
        </div>
        </CollapseDiv>
        

     <CollapseDiv divcontent="CCT Diagram" icon="diagram">         
      <div id="myDiv"></div>
      </CollapseDiv>
      <CollapseDiv divcontent="Hardness" icon="diagram">
      <div id="hardnessDiv"></div>
      </CollapseDiv>
      <CollapseDiv divcontent="Fractions" icon="pie">
      <div id="fractionsDiv"></div>
      </CollapseDiv>
     
      <div id="tableDiv"></div>
      </ContentWrapper>
       </div>
      </div>
    </div>
  </div>
  </>
  );

  
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculationCCTArbCR; // Bad type
  return { singleCalcResults, fetching };
};

// dispatch updates the state of an app
const mapDispatchToProps = (dispatch: any) => ({
  fetchSingleCalculation: (steelValues: SteelValuesArbCR) =>
    dispatch(initFetchSingleCalculation(steelValues)) // initFetchSingleCalculation as an action creator
});

const SingleCalculationContainerCCTArbCR = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCalculationCCTArbCR);

export default SingleCalculationContainerCCTArbCR;
