import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import ChemicalCompositionDIMT from '../components/ChemicalCompositionDIMT';
import SingleCalculationResultDIMT from '../components/SingleCalculationResultsDIMT';
import { initFetchSingleCalculation } from 'src/redux/modules/calculationDIMT';
import {
  SteelValuesDIMT,
  SingleDIMTCalculationResults
} from 'src/types/Calculation';


interface Props {
  fetching: boolean;
  singleCalcResults: SingleDIMTCalculationResults | null;
  fetchSingleDIMTCalculation: (steelValues: SteelValuesDIMT) => Promise<any>;
}

class SingleDIMTCalculation extends React.Component<Props, never> {
  public render() {
    const {
      fetchSingleDIMTCalculation,
      singleCalcResults,
      fetching
    } = this.props;

    return (
      <div className="row">
      <div className="col-md-4">
   <div className="card mb-4 box-shadow cardgrey">
    <div className="card-body">
        <ChemicalCompositionDIMT
          onSubmitForm={fetchSingleDIMTCalculation}
          fetching={fetching}
        />
         </div>
    </div>
    </div>
    <div className="col-md-8">
     <div className="card mb-4 box-shadow cardgrey">
      <div className="card-body">
        <SingleCalculationResultDIMT
          fetching={fetching}
          results={singleCalcResults}
        />
        </div>
      </div>
    </div>
    </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculationDIMT; // Bad type
  return { singleCalcResults, fetching };
};

// dispatch updates the state of an app
const mapDispatchToProps = (dispatch: any) => ({
  fetchSingleDIMTCalculation: (steelValues: SteelValuesDIMT) =>
    dispatch(initFetchSingleCalculation(steelValues)) // initFetchSingleCalculation as an action creator
});

const SingleDIMTCalulationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleDIMTCalculation);

export default SingleDIMTCalulationContainer;
