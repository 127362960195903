import * as React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';

import { colors } from 'src/shared/themeStyles';
import { SingleCalculationResults } from '../types/Calculation';

const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ResultWrapper = styled.div`
  flex: 1 0 50%;
  margin-bottom: 0.5rem;
  max-width: 49%;
  background-color: ${colors.backgroundDarker};
  padding: 1rem 0.7rem 0.7rem 0.7rem;
`;

const ResultContainer = styled.div`
  text-transform: uppercase;
  padding: 0rem 1rem 0.5rem 1rem;

  > h3 {
    min-height: 14px;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 14px;
  }
`;

const Result = styled.div`
  display: block;
`;

const ResultNumber = styled.span`
  font-size: 40px;
  margin-right: 0.5rem;
`;

const NoteContainer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 10px;
  padding-top: 0.5rem;
`;

interface Props {
  fetching: boolean;
  results: SingleCalculationResults | null;
}

function round(num: number, precision: number) {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(
    precision
  );
}

const renderResultValue = (
  fetching: boolean,
  resultValue: number | null,
  unit: string = '',
  decimals: number = 0
) => {
  let renderedValue = ' - ';

  if (typeof resultValue === 'number') {
    renderedValue = round(resultValue, decimals);
  }

  return (
    <Result>
      <ResultNumber>
        {fetching ? <Spinner fadeIn="none" name="circle" /> : renderedValue}
      </ResultNumber>
      <span>{unit}</span>
    </Result>
  );
};

const SingleCalculationResult = (props: Props) => {
  const { fetching, results } = props;

  return (
    <ContentWrapper>
      <Header>Results</Header>
      <ResultsWrapper>
        <ResultWrapper>
          <ResultContainer>
            <h3>Martensite</h3>
            <h3>Start Temperature</h3>
            {renderResultValue(
              fetching,
              results && results.martenite_start_temp,
              '°C'
            )}
          </ResultContainer>
          <NoteContainer>Model root mean square error: 16 °C</NoteContainer>
        </ResultWrapper>
        <ResultWrapper>
          <ResultContainer>
            <h3>Bainite</h3>
            <h3>Start Temperature</h3>
            {renderResultValue(
              fetching,
              results && results.bainite_start_temp,
              '°C'
            )}
          </ResultContainer>
          <NoteContainer>Model root mean square error: 22 °C</NoteContainer>
        </ResultWrapper>
        <ResultWrapper>
          <ResultContainer>
            <h3>Austenite</h3>
            <h3>Start Temperature</h3>
            {renderResultValue(
              fetching,
              results && results.austenite_start_temp,
              '°C'
            )}
          </ResultContainer>
          <NoteContainer>Model root mean square error: 11 °C</NoteContainer>
        </ResultWrapper>
        <ResultWrapper>
          <ResultContainer>
            <h3>Austenite</h3>
            <h3>Finish Temperature</h3>
            {renderResultValue(
              fetching,
              results && results.austenite_finish_temp,
              '°C'
            )}
          </ResultContainer>
          <NoteContainer>Model root mean square error: 11 °C</NoteContainer>
        </ResultWrapper>
        <ResultWrapper>
          <ResultContainer>
            <h3>{'  '}</h3>
            <h3>Hardness</h3>
            {renderResultValue(fetching, results && results.hardness, 'HV')}
          </ResultContainer>
          <NoteContainer>Model root mean square error: 21 HV</NoteContainer>
        </ResultWrapper>
        <ResultWrapper>
          <ResultContainer>
            <h3>{'  '}</h3>
            <h3>Martensite Fraction</h3>
            {renderResultValue(
              fetching,
              results && results.martenite_fraction,
              '',
              2
            )}
          </ResultContainer>
          <NoteContainer>Model root mean square error: 0.04</NoteContainer>
        </ResultWrapper>
      </ResultsWrapper>
    </ContentWrapper>
  );
};

export default SingleCalculationResult;
