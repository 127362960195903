import { Field, Form, Formik, FormikHelpers as FormikActions , useFormikContext } from 'formik';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import Button from './form/Button';
import { colors } from '../shared/themeStyles';
import { SteelValuesThermal } from '../types/Calculation';

const ContentWrapper = styled.div`
  flex-basis: 32.6%; /* 313/960 */
  padding: 1.5rem 1rem;
  background-color: ${colors.backgroundDarker};
`;

const ModalCloseIcon=styled.span`
    cursor: pointer;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    float:right;
    opacity:0.5;
   margin: -15px 15px -15px 15px;
    &:hover,
    &:focus {
    opacity:0.75;
  }
`
const ModalHeader=styled.div`
    border-bottom: 1px solid #e5e5e5;
    padding: 5px;
`

const ModalBody=styled.div`
padding: 15px;
`

const ModalFooterButton=styled.div`
    margin-left: 10px;
    float: right;
    width: 140px;
`

const SelectWrapper = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
    margin-right: 1rem;

  > select {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > input {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > button {
    margin: 0px auto;
    width: 130px;
  }
`;

const ModalFooter=styled.div`
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
`
const ModalBox = styled.div`

position: fixed;
z-index: 1;
padding-top: 100px;
left: 0;
top: 0;
width: 100%; 
height: 100%; 
overflow: auto; 
background-color: rgb(0,0,0); 
background-color: rgba(0,0,0,0.4); 
transition: opacity .15s linear;
opacity: 1;
`;

const ModalContent = styled.div`
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  background-color: #fefefe;
  border: 1px solid #888;
  margin: auto;
  width: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
`;

const SectionTitle = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  width: 100%;
`;

const ProductTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2.5rem;
  width: auto;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 2px solid lightgray;
`;

const ErrorMessage = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  color: red;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

const ButtonWithMargin = styled(Button)`
  margin-bottom: 1rem;
`;

const SubText = styled.span`
  text-transform: uppercase;
  display: block;
  font-size: 9px;
  margin-bottom: 1rem;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  > label {
    font-size: 14px;
    text-align: right;
    margin-right: 1rem;
  }

  > input {
    width: 60px;
    height: 32px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }

  > input[type='range'] {
    width: 60%;
    margin-right: 10px;
  }

  > span {
    margin-left: 5px;
    color: ${colors.accentDark};
  }
`;

const PrimaryInputWrapper = styled(InputWrapper as any)`
  flex: 1 0 100%;

  > label {
    flex-basis: 13%;
  }
`;

const SecondaryInputWrapper = styled(InputWrapper as any)`
  flex: 1 0 50%;

  > label {
    flex-basis: 26%;
  }
`;

interface FormValues extends SteelValuesThermal {
  save_name:string;
}

const steelValues: SteelValuesThermal = {
  Al: 0,
  As: 0,
  At: 0,
  B: 0,
  C: 0,
  Co: 0,
  Cr: 0,
  Cu: 0,
  Mn: 0,
  Mo: 0,
  N: 0,
  Nb: 0,
  Ni: 0,
  P: 0,
  S: 0,
  Si: 0,
  Sn: 0,
  Ti: 0,
  V: 0,
  W: 0,
  Zr: 0
};

const formInputValueRanges = {
  Al: [0, 15],
  As: [0, 0],
  At: [0, 0],
  B: [0, 15],
  C: [0.001, 15],
  Co: [0, 15],
  Cr: [0, 20],
  Cu: [0, 15],
  Mn: [0, 15],
  Mo: [0, 15],
  N: [0, 15],
  Nb: [0, 15],
  Ni: [0, 15],
  P: [0, 15],
  S: [0, 15],
  Si: [0, 15],
  Sn: [0, 15],
  Ti: [0, 15],
  V: [0, 15],
  W: [0, 15],
  Zr: [0, 15],
  save_name: [false, false]
};

const idealRange={
  Al: [0, 0.1],
  As: [0, 1.53],
  At: [0, 1.53],
  B: [0, 0.2],
  C: [0, 0.9],
  Co: [0, 0.1],
  Cr: [0, 2],
  Cu: [0, 0.5],
  Mn: [0, 1.5],
  Mo: [0, 1],
  N: [0, 0.1],
  Nb: [0, 0.05],
  Ni: [0, 2],
  P: [0, 0.05],
  S: [0, 0.05],
  Si: [0, 0.7],
  Sn: [0, 0.7],
  Ti: [0, 0.2],
  V: [0, 0.1],
  W: [0, 0.005],
  Zr: [0, 0.005]
}

const initialValues: FormValues = {
  ...steelValues,
  save_name: ''
};

type InputRange = [number, number];

const primaryElements: string[] = ['C', 'Mn', 'Si', 'Cr', 'Ni', 'Mo'];

const secondaryElements: string[] = [
  'V',
  'Co',
  'Al',
  'W',
  'Cu',
  'Nb',
  'Ti',
  'N',
  'B',
  'P',
  'S'
];

const getCompositionValues = (
  values: SteelValuesThermal
): { [name: string]: number } => {
  return R.omit(['save_name'], values);
};

const validateTotalLimit = (values: SteelValuesThermal) => {
  const valuesExceedLimit = R.pipe(
    getCompositionValues,
    R.values,
    R.sum,
    R.flip(R.gt)(100)
  )(values);

  if (valuesExceedLimit) {
    return {
      composition: 'Chemical composition does not sum up to 100%, please adjust'
    };
  } else {
    return {};
  }
};



const makeElementInputRangeError = ([name, value]: [string, number]) => {
  return [
    name,
    `"${name}" needs a value between ${formInputValueRanges[name][0]} and ${formInputValueRanges[name][1]}`
  ];
};

const isWithinRange = (value: number, [minValue, maxValue]: InputRange) => {
  return minValue === maxValue || value >= minValue && value <= maxValue;
};

const isElementInputValid = ([name, value]: [string, number]) =>  {
  return isWithinRange(value, formInputValueRanges[name]);
};

const validateInputRangeErrors = (values: SteelValuesThermal) => {
  return R.pipe(
    R.toPairs,
    R.reject<[string, any], 'array'>(isElementInputValid),
    R.map(makeElementInputRangeError),
    R.fromPairs
  )(values);
};

const validate = (values: SteelValuesThermal) => {
  const compositionErrors = validateTotalLimit(values);
  const inputRangeErrors = validateInputRangeErrors(values);

  return {
    ...compositionErrors,
    ...inputRangeErrors
  };
};

const renderElementInput = (element: string) => (
  <>
    <label htmlFor={element}>{element}: </label>
    <Field
      id={element}
      name={element}
      type="number"
      min="0"
      step={primaryElements.indexOf(element) === -1 ?'0.00001':'0.00001'}
      title={'Ideal Range: '+idealRange[element][0].toString()+' - '+idealRange[element][1].toString()}
      autoComplete="off"
    />{' '}
    <span>%</span>
  </>
);

const renderPrimaryElementInput = (element: string) => (
  <PrimaryInputWrapper key={element}>
    {renderElementInput(element)}
  </PrimaryInputWrapper>
);

const renderSecondaryElementInput = (element: string) => (
  <SecondaryInputWrapper key={element}>
    {renderElementInput(element)}
  </SecondaryInputWrapper>
);

interface Props {
  onSubmitForm: (steelValues: SteelValuesThermal) => Promise<any>;
  fetching: boolean;
  parentCallback: any;
}

interface State {
  displaySecondaryelements: boolean;
  error: string | null;
  showModal: boolean;
  valuesToLoad:FormValues;
  showSaveModal: boolean;
  showRemoveModal: boolean;
}

class ChemicalComposition extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {valuesToLoad:initialValues,showSaveModal:false, showRemoveModal:false, displaySecondaryelements: false, error: null, showModal: false };
  }

  
  removeStorage = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    localStorage.clear();
  };

  StoreDataArea = () => {

    const { values } = useFormikContext();

    // const setValuesToLoad = (event: React.MouseEvent<HTMLElement>) => {
    //   this.setState({valuesToLoad:savedValues})
      
    // };

  return(
    <>
<SelectWrapper>
     {this.getStorageList()}
     </SelectWrapper>  
     <SelectWrapper>
<ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>{
                  event.preventDefault();
                  this.setState({showRemoveModal: true}) 

                }
                } secondary>
                Remove all
              </ButtonWithMargin>
              </SelectWrapper>

<SelectWrapper>
                  
                  <Field
                    id="save_name"
                    name="save_name"
                    type="text"
                    autoComplete="off"
                    />
                </SelectWrapper>
                <SelectWrapper>
<ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>{
                  event.preventDefault()
                  localStorage.setItem('therm_'+ (values as FormValues).save_name, JSON.stringify(values))
                  this.setState({showSaveModal: true})
        }
                } secondary>
                Save
              </ButtonWithMargin>
              </SelectWrapper>
              </>
  )
}


  toggleSecondaryElementsDisplay = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({
      displaySecondaryelements: !this.state.displaySecondaryelements
    });
  };

  
  resetInputs = (event: React.MouseEvent<HTMLElement>) => {
    window.location.reload();
  };

  handleChange = (e: { currentTarget: { value: FormValues; }; }) => {
    
    const anytype = 'therm_' + e.currentTarget.value; 
    console.log(anytype);
    
    const theValue: string | null  = localStorage.getItem(anytype);
    let parsedValue = null;

    if (theValue !== null) {
    parsedValue= JSON.parse(theValue) as FormValues;
    }
    console.log(parsedValue);
    if (parsedValue !== null) {
      this.setState({valuesToLoad:parsedValue});
      }
  }
  
  getStorageList = () => {
  const storageList= [];
  const keys = Object.keys(localStorage);
  let i = keys.length;
  const substring = 'therm_';
  
  while ( i-- ) {
    if(keys[i].includes(substring)){
    storageList.push( keys[i].replace('therm_', '') );
    }
  }

  const selectList = storageList.map((ele, index) => <option key={index} value={ele}>{ele}</option>)  

  
  return (<>
  <Field as="select" name="simulations" onChange={ 

    this.handleChange}> <option selected disabled>Select</option> {selectList} </Field>
  </>
  )
   
}


hideSaveModal= (event: React.MouseEvent<HTMLElement>) => {
  this.setState({showSaveModal:false})
}
hideRemoveModal= (event: React.MouseEvent<HTMLElement>) => {
  this.setState({showRemoveModal:false})
}

  hideModal= (event: React.MouseEvent<HTMLElement>) => {
    this.setState({showModal:false})
  }
  

  render() {
    const { valuesToLoad,displaySecondaryelements,showModal, showSaveModal, showRemoveModal} = this.state;
    const { onSubmitForm, fetching } = this.props;
    
    return (
      <ContentWrapper>

        <ProductTitle>Thermal properties</ProductTitle>
        <SectionTitle>Chemical Composition</SectionTitle>
        <SubText>Weight percentages</SubText>
        <Formik
          enableReinitialize={true}
          initialValues={valuesToLoad}
          validate={validate}
          onSubmit={(
            values: SteelValuesThermal,
            { setSubmitting }: FormikActions<SteelValuesThermal>
          ) => {
            // any null values (empty strings) will be converted to 0
            const nullsToZero = (val: any) => (!val ? 0 : val);
            // @ts-ignore
            let formattedValues:SteelValuesArbCR = R.map(nullsToZero, values);
            formattedValues = R.omit(['save_name' ], formattedValues);
            
            const subset = primaryElements.concat(secondaryElements).reduce((o, k) =>{ o[k] = formattedValues[k]; return o; }, {});
            const lowalloycomp=Object.keys(subset).reduce((sum,key)=>sum+ parseFloat(subset[key]),0);
            if(lowalloycomp >= 8){
              this.setState({showModal: true})
              
            }
            this.props.parentCallback(formattedValues);  
            onSubmitForm(formattedValues).then(() => {
              setSubmitting(false);
            });
          
          }}
          render={(props: any,) => (
            <Form>
              <InputsWrapper>
                {primaryElements.map(ele => renderPrimaryElementInput(ele))}
              </InputsWrapper>
              <ButtonWithMargin
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.toggleSecondaryElementsDisplay(event)
                }
                secondary
              >
                {displaySecondaryelements
                  ? 'Hide Elements'
                  : 'View All Elements'}
              </ButtonWithMargin>
              {displaySecondaryelements && (
                <InputsWrapper>
                  {secondaryElements.map(ele =>
                    renderSecondaryElementInput(ele)
                  )}
                </InputsWrapper>
              )}
           
              
              

              <>

    
    
              </>
      
              <this.StoreDataArea />

              
              <Button disabled={fetching}>
                {fetching ? 'Loading...' : 'Simulate'}
              </Button>

              {props.errors &&
                R.values(props.errors).map((error: string) => (
                  <ErrorMessage key={error}>{error}</ErrorMessage>
                ))}
          
          <InputsWrapper className="mb-5 mt-2">
<ButtonWithMargin 
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.resetInputs(event)
                } secondary>
                Clear inputs
              </ButtonWithMargin>

              </InputsWrapper>
            </Form>
          )}

        />

<ModalBox style={showModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>You are moving in to high alloy domain now.<ModalCloseIcon onClick={this.hideModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>Please note that this version of CCT module provides best results in the low alloy domain. </ModalBody>
    <ModalFooter>
      <Button secondary={true} width={'100px'} float={'right'} onClick={this.hideModal}>Close</Button>
    </ModalFooter>              
  </ModalContent>
</ModalBox>


<ModalBox style={showSaveModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>Your simulation was saved<ModalCloseIcon onClick={this.hideSaveModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>Your simulation has been saved to your browser cache. Please note that it has not been saved to central database and that it will me removed upon browser cache clean. </ModalBody>
    <ModalFooter>
      <Button secondary={true} width={'100px'} float={'right'} onClick={this.hideSaveModal}>Close</Button>
    </ModalFooter>              
  </ModalContent>
</ModalBox>
<ModalBox style={showRemoveModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>Do you want to remove all saved simulations?<ModalCloseIcon onClick={this.hideRemoveModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>Do you want to remove all locally saved simulations from you browser storage? </ModalBody>
    <ModalFooter>
    <ModalFooterButton>
      <Button secondary={true} width={'120px'} float={'right'} onClick={this.hideRemoveModal}>Cancel</Button>
      </ModalFooterButton>
      
      <Button  secondary={true} width={'120px'} float={'right'} onClick={
        (event: React.MouseEvent<HTMLElement>) =>{
          event.preventDefault();
          const keys = Object.keys(localStorage);
  let i = keys.length;
  const substring = 'therm_';
  
  while ( i-- ) {
    if(keys[i].includes(substring)){
    localStorage.removeItem( keys[i]);
    }
  }
          this.setState({showRemoveModal:false})
        }
        }>Remove</Button>            
      
    </ModalFooter>              
  </ModalContent>
</ModalBox>
      </ContentWrapper>
      
    );
  }
}

export default ChemicalComposition;
