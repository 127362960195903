import * as React from 'react';
import styled from 'styled-components';

import { colors } from 'src/shared/themeStyles';
import { SingleCalculationResults } from '../types/Calculation';


const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ResultWrapper = styled.div`
  flex: 1 0 50%;
  margin-bottom: 0.5rem;
  max-width: 90%;
  background-color: ${colors.backgroundDarker};
  padding: 1rem 0.7rem 0.7rem 0.7rem;
`;

const ResultContainer = styled.div`
  text-transform: uppercase;
  padding: 0rem 1rem 0.5rem 1rem;

  > h3 {
    min-height: 14px;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 14px;
  }
`;

interface Props {
  fetching: boolean;
  results: SingleCalculationResults | null;
}

const SingleCalculationResultPlot = (props: Props) => {
  return (
    <ContentWrapper>
      <Header>Results</Header>
      <ResultsWrapper>
        <ResultWrapper>
          <ResultContainer>
            
          </ResultContainer>
        </ResultWrapper>
      </ResultsWrapper>
    </ContentWrapper>
  );
};

export default SingleCalculationResultPlot;
