import { loop, Loop } from 'redux-loop';

import { HttpError } from '../utils/HttpError';
import { runLoopFetch } from '../utils/loop';
import {
  SingleCalculationResultsCCTArbCR,
  SteelValuesHAArbCR,
  JobId
} from '../../types/Calculation';
import auth0Client from 'src/Auth';

export const SINGLE_CALC_FETCH_START = 'SINGLE_CALC_HACCT_ARBCR_FETCH_START';
export const SINGLE_CALC_FETCH_SUCCESS = 'SINGLE_CALC_HACCT_ARBCR_FETCH_SUCCESS';
export const SINGLE_CALC_FETCH_FAILURE = 'SINGLE_CALC_HACCT_ARBCR_FETCH_FAILURE';
export const SINGLE_CALC_POLL_SUCCESS =
  'SINGLE_CALC_HACCT_ARBCR_POLL_SUCCESS';
export const SINGLE_CALC_POLL_FAILURE =
  'SINGLE_CALC_HACCT_ARBCR_POLL_FAILURE';

type CalculationAction =
  | { type: typeof SINGLE_CALC_FETCH_START; steelValues: SteelValuesHAArbCR }
  | {
      type: typeof SINGLE_CALC_FETCH_SUCCESS;
      payload: JobId;
    }
    | { type: typeof SINGLE_CALC_POLL_SUCCESS; 
      payload: SingleCalculationResultsCCTArbCR;}
  | { type: typeof SINGLE_CALC_FETCH_FAILURE; }
  | { type: typeof SINGLE_CALC_POLL_FAILURE };

export interface CalculationState {
  singleCalcResults: SingleCalculationResultsCCTArbCR | null;
  fetching: boolean;
}

const initialState: CalculationState = {
  fetching: false,
  singleCalcResults: null
};

const fetchSingleCalculation = async (
  steelValues: SteelValuesHAArbCR
): Promise<JobId> => {
  const res = await fetch(`/api/calculation/singleHACCTArbCR`, {
    body: JSON.stringify(steelValues),
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${auth0Client.getIdToken()}`,
      'content-type': 'application/json'
    },
    method: 'POST'
  });
  
  if (!res.ok) {
    console.log(steelValues);
    console.log(res);
    throw new HttpError({ status: res.status, message: res.statusText });
  }

  return res.json();
};


const wait = (ms:number) => new Promise(res => setTimeout(res, ms));

const fetchSingleResult = async (
  jobid: JobId, count: number
): Promise<SingleCalculationResultsCCTArbCR> => {
  
  const jid = Number(jobid.id);
  const res = await fetch(`/api/calculation/singleHACCTArbCRResult/`+jid, {
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${auth0Client.getIdToken()}`,
      'content-type': 'application/json'
    },
    method: 'GET'
  });

  if (!res.ok) {
    throw new HttpError({ status: res.status, message: res.statusText });
  }
  if (res.status !== 200) {
    if (count++ < 80) {
      await wait(3500);
      return fetchSingleResult(jobid, count);
    } else {
      throw new Error('max retries reached');
    };
  }
  
  return res.json();
};

/**
 * Redux-action creator.
 *
 * @param steelValues the alloy components etc.
 */
export const initFetchSingleCalculation = (steelValues: SteelValuesHAArbCR) => ({
  steelValues,
  type: SINGLE_CALC_FETCH_START
});

/**
 * Redux-reducer.
 */
export function calculation(
  state: CalculationState = initialState,
  action: CalculationAction
): CalculationState | Loop<CalculationState, any> {
  switch (action.type) {
    case SINGLE_CALC_FETCH_START:
      return loop(
        { ...state, fetching: true },
        runLoopFetch(
          fetchSingleCalculation,
          SINGLE_CALC_FETCH_SUCCESS,
          SINGLE_CALC_FETCH_FAILURE,
          [action.steelValues]
        )
      );

      case SINGLE_CALC_FETCH_FAILURE:
        return { ...state, fetching: false, singleCalcResults: null };
  
      case SINGLE_CALC_FETCH_SUCCESS:
        return loop(
          { ...state, fetching: true },
          runLoopFetch(
            fetchSingleResult,
            SINGLE_CALC_POLL_SUCCESS,
            SINGLE_CALC_POLL_FAILURE,
            [action.payload, 0]
          )
        );
  
      case SINGLE_CALC_POLL_SUCCESS:
        // What is in ...state? isn't it just fetching and singleCalcResults?
        return { ...state, fetching: false, singleCalcResults: action.payload };
  
      case SINGLE_CALC_POLL_FAILURE:
        return { ...state, fetching: false, singleCalcResults: null };
  
    default:
      return state;
  }
}
