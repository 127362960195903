import * as React from 'react';
import styled from 'styled-components';
import { colors } from 'src/shared/themeStyles';

// const StyledButton = styled.button<{ width?: string; height?: string }>`
//   border-radius: 3px;
//   cursor: pointer;
//   display: block;
//   font-size: 14px;
//   height: ${p => p.height || '2.5rem'};
//   transition: all 0.2s ease 0s;
//   width: ${p => p.width || '100%'};
//   padding: 0 1.5rem;
// `;

const PrimaryButton = styled.button<{ width?: string; height?: string }>`
  background-color: ${colors.buttonBackground};
  color: ${colors.white};
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: ${p => p.height || '2.5rem'};
  transition: all 0.2s ease 0s;
  width: ${p => p.width || '100%'};
  padding: 0 1.5rem;

  &:hover,
  &:focus {
    background: #3071a9;
  }
`;

const SecondaryButton = styled.button<{ light?: boolean,float?:string}>`
border-radius: 3px;
cursor: pointer;
display: block;
font-size: 14px;
height: 2.5rem;
transition: all 0.2s ease 0s;
width: '100%;
padding: 0 1.5rem;
background: transparent !important;
border: 1px solid rgb(216, 216, 216) !important;
// border: solid 1px ${colors.backgroundDark};
color: ${ p => p.light ? colors.white : colors.accent };
float: ${p => p.float || 'inherit'};
&:hover,
&:focus {
  box-shadow: 0 0 4px ${p => p.light ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.35)'};
}
`;

const DownloadButton = styled.button<{ float?: string}>`
  background-color: ${colors.buttonBackground};
  color: ${colors.white};
  border: 0;
  float: ${p => p.float || 'inherit'};

  &:hover,
  &:focus {
    background: #3071a9;
  }
`;


interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  float?: string;
  download?: boolean;
  height?: string;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  secondary?: boolean;
  width?: string;
  light?: boolean;
}

const Button: React.SFC<ButtonProps> = (props: ButtonProps) => {
  const { secondary, disabled, children,download } = props;
  
  return !secondary && !download
    ? <PrimaryButton disabled={disabled} type="submit" {...props}>{children}</PrimaryButton>
    : secondary ? <SecondaryButton disabled={disabled} type="submit" {...props}>{children}</SecondaryButton>
     :  <DownloadButton disabled={disabled} type="submit" {...props}>{children}</DownloadButton>
};

export default Button;
