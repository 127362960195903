import * as React from 'react';
import { User } from '../types/User';
import Button from './form/Button';
import styled from 'styled-components';

import { Navbar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';

// import { Button } from 'react-bootstrap';


interface TopBarProps {
  logout: () => void;
  user: User | null;
  children?: any;
}

const LogoutButton = styled(Button)`
  align-self: center;
  margin-right: 1rem;
  margin: auto;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 2.5rem;
  transition: all 0.2s ease 0s;
  width: auto;
  padding: 0px 1.5rem;
  background: transparent;
  border: 1px solid rgb(216, 216, 216);
  color: rgb(255, 255, 255);
  float: inherit;
`;

const Header: React.SFC<TopBarProps> = (props: TopBarProps) => {
  const { logout, user  } = props;
  const logo: string = require("../images/Ferritico_wordmark_final.svg").default;


  return (  

    <Navbar className="navbar navbar-expand-lg fixed-top fHeader navbar-dark" expand="md">
      <Container>
    <Navbar.Brand href="/dashboard">
    {/* <img src="/static/media/Ferritco_wordmark_final_500w.png" width="144px" />  */}
    <img src={logo} alt="logo" width="144px" />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link href="https://www.ferritico.com/company">Support</Nav.Link>
        <NavDropdown title="Modules" id="basic-nav-dropdown">
          <NavDropdown.Item href="/dashboard">
            Dashboard
          </NavDropdown.Item>
          <NavDropdown.Item href="/dashapi">
            API Dashboard
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/cct" className="nav-white">Low Alloy CCT</NavDropdown.Item>
          <NavDropdown.Item href="/batchcct" className="nav-white"> Low Alloy Batch CCT</NavDropdown.Item>
          <NavDropdown.Item href="/hacct" className="nav-white"> High Alloy CCT</NavDropdown.Item>
          <NavDropdown.Item href="/hardenability" className="nav-white">Hardenability</NavDropdown.Item>
          <NavDropdown.Item href="/ttt" className="nav-white">TTT</NavDropdown.Item>
          <NavDropdown.Item href="/tempering" className="nav-white">Tempering</NavDropdown.Item>
          <NavDropdown.Item href="/thermal" className="nav-white">Thermal properties</NavDropdown.Item>
          <NavDropdown.Item href="/grainsize" className="nav-white">Austenite grain growth</NavDropdown.Item>
          <NavDropdown.Item href="/equilibrium" className="nav-white">Equilibrium calculations</NavDropdown.Item>
          <NavDropdown.Item href="/corr" className="nav-white" >Corrosion</NavDropdown.Item>
          <NavDropdown.Item href="/di" className="nav-white" >DIMT</NavDropdown.Item>
          <NavDropdown.Item href="/sfe" className="nav-white" >SFE</NavDropdown.Item>
          <NavDropdown.Item href="/embrittlement" className="nav-white">Embrittlement</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      {user &&
            <LogoutButton onClick={logout} secondary light width="auto">
              Log out
            </LogoutButton>}
    </Navbar.Collapse>
    </Container>
  </Navbar>


  // <nav className="navbar navbar-expand-lg fixed-top fHeader bg-transparent">
  //   <div className="container">
  //   <a className="navbar-brand" href="#">
  //     <img src="/static/media/Ferritico_wordmark_final.accc3842.svg" width="190 px" />
  //   </a>
  //   <button className="navbar-toggler navbar-light" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
  //   <span className="navbar-toggler-icon" />
  // </button>
  //   <div className="collapse navbar-collapse" id="navbarNav">
  //   <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
  //     <li className="nav-item active">
  //       <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
  //     </li>
  //     <li className="nav-item">
  //       <a className="nav-link" href="#">Link</a>
  //     </li>
  //     <li className="nav-item">
  //       <a className="nav-link disabled" href="#">Disabled</a>
  //     </li>
  //     <li className="nav-item">
  //     {user &&
  //           <LogoutButton onClick={logout} secondary light width="auto">
  //             Log out
  //           </LogoutButton>}
  //     </li>
  //   </ul>
  //   </div>
  //   </div>
  // </nav>

  );
};

export default Header;
