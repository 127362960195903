import {  Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as R from 'ramda';
import React, { useState, } from "react";
import styled from 'styled-components';
import Button from './form/Button';
import { colors } from '../shared/themeStyles';
import { SteelValuesArbCR } from '../types/Calculation';
import {DataEditor, GridColumn, GridCellKind, Item, GridCell} from "@glideapps/glide-data-grid";

const ContentWrapper = styled.div`
  flex-basis: 100%; /* 313/960 */
  padding: 1.5rem 1rem;
  background-color: ${colors.backgroundDarker};
`;


const ModalCloseIcon=styled.span`
    cursor: pointer;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    float:right;
    opacity:0.5;
   margin: -15px 15px -15px 15px;
    &:hover,
    &:focus {
    opacity:0.75;
  }
`
const ModalHeader=styled.div`
    border-bottom: 1px solid #e5e5e5;
    padding: 5px;
`

const ModalBody=styled.div`
padding: 15px;
`
const ModalFooter=styled.div`
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
`

const SelectWrapper = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
    margin-right: 1rem;

  > select {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > input {
    width: 160px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    border: solid 1px ${colors.backgroundDark};
    cursor: pointer;
  }
  > button {
    margin: 0px auto;
    width: 130px;
  }
`;
// const ButtonWithMargin = styled.button<{ light?: boolean,float?:string}>`
// border-radius: 3px;
// cursor: pointer;
// display: block;
// font-size: 14px;
// height: 2.5rem;
// transition: all 0.2s ease 0s;
// width: '100%;
// padding: 0 1.5rem;
// background: transparent !important;
// border: 1px solid rgb(216, 216, 216) !important;
// // border: solid 1px ${colors.backgroundDark};
// color: ${ p => p.light ? colors.white : colors.accent };
// float: ${p => p.float || 'inherit'};
// &:hover,
// &:focus {
//   box-shadow: 0 0 4px ${p => p.light ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.35)'};
// }
// `;


const ModalBox = styled.div`

position: fixed;
z-index: 1;
padding-top: 100px;
left: 0;
top: 0;
width: 100%; 
height: 100%; 
overflow: auto; 
background-color: rgb(0,0,0); 
background-color: rgba(0,0,0,0.4); 
transition: opacity .15s linear;
opacity: 1;
`;

const ModalContent = styled.div`
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  background-color: #fefefe;
  border: 1px solid #888;
  margin: auto;
  width: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
`;

const SectionTitle = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  width: 100%;
`;

const ProductTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2.5rem;
  width: auto;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 2px solid lightgray;
`;

const ErrorMessage = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  color: red;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

const SubText = styled.span`
  text-transform: uppercase;
  display: block;
  font-size: 9px;
  margin-bottom: 1rem;
`;

const InputsWrapper = styled.div`
  flex-wrap: wrap;
`;


interface FormValues extends SteelValuesArbCR {
  aus_temp: number;
  aus_time: number;
  crs: number[];
  crsstring:string;
}


const formInputValueRanges = {
  Al: [0, 15],
  At: [0, 0],
  B: [0, 15],
  C: [0.001, 15],
  Co: [0, 15],
  Cr: [0, 20],
  Cu: [0, 15],
  Mn: [0, 15],
  Mo: [0, 15],
  N: [0, 15],
  Nb: [0, 15],
  Ni: [0, 15],
  P: [0, 15],
  S: [0, 15],
  Si: [0, 15],
  Ti: [0, 15],
  V: [0, 15],
  W: [0, 15],
  aus_temp: [800, 1200],
  aus_time: [1, 10000],
  crs: [0.001, 100],
  crs_end: [0.001, 1000],
  crs_explicit: [false, false],
  crs_start: [0.001, 1000],
  is_aus_time: [false, false],
  is_time: [false, false],
  save_name: [false, false]
  
};



const data: FormValues[] = [
  {
    "Al": 0,
    "At": 0,
    "B": 0,
    "C": 0,
    "Co": 0,
    "Cr": 0,
    "Cu": 0,
    "Mn": 0,
    "Mo": 0,
    "N": 0,
    "Nb": 0,
    "Ni": 0,
    "P": 0,
    "S": 0,
    "Si": 0,
    "Ti": 0,
    "V": 0,
    "W": 0,
    "aus_temp": 900,
    "aus_time": 3600,
    "crsstring": '1,2,4,8,10',
    "crs": [0]
  }
]



type InputRange = [number, number];


const validateTotalLimit = (values: SteelValuesArbCR[]) => {
  
  let sum:any[] =[];
  let anyOneZero = false;
  values.forEach((element,i) => { 
    for (let key in element) {
      if(key !== "aus_temp" && key !== "average_option" && key !== "aus_time" && key !== "crs" && key !== "crsstring"){
        sum[i] += element[key];
      }
    }
    if(sum[i] === 0){
      anyOneZero = true;
    }
  });
  
    if (anyOneZero) {
    return {
      composition: 'Total chemical composition weight must be greater than 0 for every row'
    };
  } else {
    return {};
  }

};



const makeElementInputRangeError = ([name, value]: [string, number]) => {
  return [
    "",
    `"${name}" needs a value between ${formInputValueRanges[name][0]} and ${formInputValueRanges[name][1]}`
  ];
};

const isWithinRange = (value: number, [minValue, maxValue]: InputRange) => {
  return minValue === maxValue || value >= minValue && value <= maxValue;
};



const isElementInputValid = ([name, value]: [string, number]) =>  {
  return isWithinRange(value, formInputValueRanges[name]);
};

const validateInputRangeErrors = (values: SteelValuesArbCR[]) => {


  let anyOneOutside = false;
  let outsideName ="";

  values.forEach((element,i) => { 
    for (let key in element) {
      let within = true;
      if( key !== "average_option" && key !== "crs" && key !== "crsstring"){
        within = isElementInputValid([key, element[key]] )
        if (!within){
          
          anyOneOutside = true;
          outsideName =key;
        }
      }
    }
  });
  
    if (anyOneOutside) {
      return [
        '',
        `"${outsideName}" needs a value between ${formInputValueRanges[outsideName][0]} and ${formInputValueRanges[outsideName][1]}`
      ];
  } else {
    return {};
  }

  return 
  values.forEach((element,i) => { 
  R.pipe(
    R.toPairs,
    R.reject<[string, any], 'array'>(isElementInputValid),
    R.map(makeElementInputRangeError),
    R.fromPairs
  )(element);
  });





};

const validate = (values: SteelValuesArbCR[]) => {
  const compositionErrors = validateTotalLimit(values);
  const inputRangeErrors = validateInputRangeErrors(values);

  return {
    ...compositionErrors,
    ...inputRangeErrors
  };
};


interface Props {
  onSubmitForm: (steelValues: any[]) => Promise<any>;
  fetching: boolean;
  parentCallback: any;
}

interface State {
  displaySecondaryelements: boolean;
  error: string | null;
  showModal: boolean;
  displayCrInputField: boolean;
  displayAusTimeInputField: boolean;
  valuesToLoad:FormValues[];
}

class GridViewCCT extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { valuesToLoad:data, displaySecondaryelements: false, error: null, showModal: false, displayCrInputField: false, displayAusTimeInputField: false };
  }
 

  
  resetInputs = (event: React.MouseEvent<HTMLElement>) => {
    window.location.reload();
  };

 
  getGrid = () => {


    

  const columns: GridColumn[] = [
    {
      title: "Al",
      id: "Al",
      group: "Input "
  },
  {
      title: "B",
      id: "B",
      group: "Input "
  },
  {
      title: "C",
      id: "C",
      group: "Input "
  },
  {
      title: "Co",
      id: "Co",
      group: "Input "
  },
    {
      title: "Cr",
      id: "Cr",
      group: "Input "
  },
  {
      title: "Cu",
      id: "Cu",
        group: "Input "
  },
  {
      title: "Mn",
      id: "Mn",
      group: "Input "
  },
  {
      title: "Mo",
      id: "Mo",
      group: "Input "
  },
  {
    title:"N",
    id: "N",
    group: "Input "
  },
  {
    title:"Nb",
    id: "Nb",
    group: "Input "
  },
  {
    title:"Ni",
    id: "Ni",
    group: "Input "
  },
  {
    title:"P",
    id: "P",
    group: "Input "
  },
  {
    title:"S",
    id: "S",
    group: "Input "
  },
  {
    title:"Si",
    id: "Si",
    group: "Input "
  },
  {
    title:"Ti",
    id: "Ti",
    group: "Input "
  },
  {
      title: "V",
    id: "V",
    group: "Input "
  },
  {
    title: "W",
    id: "W",
    group: "Input "
  },
  {
    title: "Aus_temp (°C)",
    id: "aus_temp",
    group: "Input "
  },
  {
    title: "Aus_time (s)",
    id: "aus_time",
    group: "Input "
  },
  {
    title: "Cooling rates (°C/s)",
    id: "crs",
    group: "Input "
  }
]


const initialIndexes: string[] = ["Al", "B", "C", "Co", "Cr", "Cu", "Mn", "Mo","N", "Nb", "Ni", "P", "S", "Si", "Ti", "V", "W", "aus_temp", "aus_time", "crsstring"];


const [indexes] = useState(initialIndexes);


const onRowAppended = React.useCallback(() => {
  let newData: any = {};
  indexes.forEach((index) => {
    if (index === "aus_temp") {
    newData[index] = 900;
    }else if( index ==="aus_time"){
      newData[index] = 3600;
    }else if(index === "crsstring"){
      newData[index] = '1,2,4,8,10';
    }else{
      newData[index] = 0;
    }
  });
  newData["At"] = 0;
  const tempNewData = [...this.state.valuesToLoad, newData];
  this.setState({valuesToLoad:tempNewData});
  
}, [this.state.valuesToLoad, indexes]);

const onCellEdited = React.useCallback((cell: Item, newValue: GridCell) => {
  if (newValue.kind !== GridCellKind.Number && newValue.kind !== GridCellKind.Text) {
      // we only have text cells, might as well just die here.
      
      return;
  }
  const [col, row] = cell;
  const key = indexes[col];
  
  if(newValue.data){
  this.state.valuesToLoad[row][key] = newValue.data;
  }else{
    this.state.valuesToLoad[row][key] = 0;
  }
  
}, []);
  
const getContent = React.useCallback((cell: Item): GridCell => {
  const [col, row] = cell;  
  const dataRow = this.state.valuesToLoad[row];
  
  const d = dataRow[indexes[col]]
  if(d){
  if (col === 19 || col === 20) {
  return {
      kind: GridCellKind.Text,
      allowOverlay: true,
      readonly: false,
      displayData: d,
      data: d,
  };
} else {
  return {
    kind: GridCellKind.Number,
    allowOverlay: true,
    readonly: false,
    displayData: d.toString(),
    data: d,
};
}
  }else{
    
    return {
      kind: GridCellKind.Number,
      allowOverlay: true,
      readonly: false,
      displayData: '0',
      data: 0,
  };

  }
}, []);

  return (<>
  <DataEditor getCellContent={getContent} onCellEdited={onCellEdited} onRowAppended={onRowAppended}
    onPaste={true} getCellsForSelection={true} columns={columns} rows={this.state.valuesToLoad.length} />
       
  </>
  )
   
}

 
  
  hideModal= (event: React.MouseEvent<HTMLElement>) => {
    this.setState({showModal:false})
  }

  render() {
    const {valuesToLoad, showModal} = this.state;
    const { onSubmitForm, fetching } = this.props;
    
    return (
      <div id='portal' className="col-md-12 mb-5">
      <ContentWrapper>

        <ProductTitle>CCT Batch Simulation</ProductTitle>
        <SectionTitle>Simulation input</SectionTitle>
        <SubText>Maximum 1.000 simulations (Σ Rows * Cooling Rates)</SubText>
        
        
        
        
        <Formik
        enableReinitialize={true}
        validate={validate}  
        initialValues={valuesToLoad}
          onSubmit={(
            values: FormValues[],
            { setSubmitting }: FormikActions<any[]>
          ) => {

            
            const keycrs = "crs";
            const keycrsstring = "crsstring";
            const avgclosed = 'average_option';
 
values = valuesToLoad;


            values.forEach((element,i) => {
              let sum = 0;

              if( typeof element[keycrsstring] === 'string'){
                
                this.state.valuesToLoad[i][keycrs] = element[keycrs];
                
              element[keycrs] = element[keycrsstring].split(',').map(Number);
              
              }
              
              for (let key in element) {
                
                if(key !== "aus_temp" && key !== "average_option" && key !== "aus_time" && key !== "crs" && key !== "crsstring"){
              sum += element[key];
              }
            }
              element[avgclosed] = 'avg_all';

              if(sum <= 0){
                this.setState({showModal: true})
                
              }

            })
            
            const nullsToZero = (val: any) => (!val ? 0 : val);
            // @ts-ignore
            let formattedValues:SteelValuesArbCR[] = R.map(nullsToZero, values);
            
            formattedValues = R.omit(['save_name' ], formattedValues);
            
            

            
            this.props.parentCallback(formattedValues);  
            onSubmitForm(formattedValues).then(() => {
              setSubmitting(false);
            });
          
          }}
          render={(props: any,) => (


            <Form>
              
              
              
              <this.getGrid />
              
              
              

              <>
    
      
              </>
      
              <SelectWrapper className="mb-5 mt-2">
<button id="greybutton"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  this.resetInputs(event)
                } >
                Reset grid
              </button>

              </SelectWrapper>

   

              <InputsWrapper className="mb-2 mt-5">
              <Button   disabled={fetching}>
                {fetching ? 'Loading...' : 'Simulate'}
              </Button>
              
              {props.errors &&
                R.values(props.errors).map((error: string) => (
                  <ErrorMessage key={error}>{error}</ErrorMessage>
                ))}
                </InputsWrapper>



            </Form>
          )}

        />

<ModalBox style={showModal ? {display:'block'} : { display: 'none' }} >
  <ModalContent>
    <ModalHeader>
      <h4>Please add chemical composition<ModalCloseIcon onClick={this.hideModal} >&times;</ModalCloseIcon></h4>
    </ModalHeader>
    <ModalBody>One or more lines of simulation input does not include chemical composition. The total sum of alloying elements must be greater than 0 to run simulation. </ModalBody>
    <ModalFooter>
      <button id="greybutton" onClick={this.hideModal}>Close</button>
    </ModalFooter>              
  </ModalContent>
</ModalBox>


      </ContentWrapper>
      </div>
    );
  }
}

export default GridViewCCT;
