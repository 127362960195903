import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import ChemicalCompositionThermal from '../components/ChemicalCompositionThermal';
import SingleCalculationResultsThermalPlot from '../components/SingleCalculationResultsThermalPlot';
import { initFetchSingleCalculation } from 'src/redux/modules/calculationThermal';
import {
  SteelValuesThermal,
  SingleCalculationResultsThermal
} from 'src/types/Calculation';


interface Props {
  steelValues:SteelValuesThermal;
  fetching: boolean;
  singleCalcResults: SingleCalculationResultsThermal | null;
  fetchSingleCalculation: (steelValues: SteelValuesThermal) => Promise<any>;
}

class SingleCalculationThermal extends React.Component<Props> {
  state = {steelValues:[]}  
  callbackFunction = (childData:SteelValuesThermal) => {
    this.setState({steelValues: childData})
    }
  
  public render() {
    const { fetchSingleCalculation, singleCalcResults, fetching } = this.props;
    
    return (
  <div className="row">
      <div className="col-md-4">
   <div className="card mb-4 box-shadow cardgrey">
    <div className="card-body">
        <ChemicalCompositionThermal
          onSubmitForm={fetchSingleCalculation}
          parentCallback = {this.callbackFunction}
          fetching={fetching}
        />
         </div>
    </div>
    </div>
    <div className="col-md-8">
     <div className="card mb-4 box-shadow cardgrey">
      <div className="card-body">
        <SingleCalculationResultsThermalPlot
          fetching={fetching}
          steelValues={this.state.steelValues}
          results={singleCalcResults}
        />
       </div>
      </div>
    </div>
  </div>
  );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculationThermal; // Bad type
  return { singleCalcResults, fetching };
};

// dispatch updates the state of an app
const mapDispatchToProps = (dispatch: any) => ({
  fetchSingleCalculation: (steelValues: SteelValuesThermal) =>
    dispatch(initFetchSingleCalculation(steelValues)) // initFetchSingleCalculation as an action creator
});

const SingleCalculationContainerThermal = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCalculationThermal);

export default SingleCalculationContainerThermal;
