/*
 * Used to reduce boilerplate when creating simple redux-loop side-effects.
 * runLoopFetch() can be directly provided with the success & fail action-types,
 * which removes necessity to create actionCreator functions for each one.
 */

import { Cmd } from 'redux-loop';

type FetchFn = (...args: any[]) => Promise<object>;

const actionCreator = (type: string) => {
  return (payload: object) => ({ type, payload });
};

export const runLoopFetch = (
  fetchFn: FetchFn,
  successAction: string,
  failAction: string,
  args?: any[],
) => {
  return Cmd.run(fetchFn, {
    args,
    // What is return from fetchFn is passed to successAction as argument. 
    // This is a new action which is automatically dispatched.
    failActionCreator: actionCreator(failAction), 
    successActionCreator: actionCreator(successAction),
  });
};

// Cmd.run returnd the return value from fetchFunction (as payload?) and an action