import * as React from 'react';
import styled from 'styled-components';

import { colors, breakpoints } from '../shared/themeStyles';
import Button from './form/Button';
import auth0Client from '../Auth';

import logo from '../images/Ferritico_wordmark_final.svg';
import backgroundFooterImage from '../images/Sign-up-background.svg';

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  color: ${colors.white};

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`;

const TryContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.accent};

  @media (max-width: ${breakpoints.sm}px) {
    flex: 0;
  }
`;

const LogoContainer = styled.a`
  height: 4rem;
  display: flex;
  margin-bottom: 8rem;
  align-items: center;

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 1rem;
  }
`;

const Logo = styled.img`
  height: 2.3rem;
  margin-left: 2rem;
`;

const BackgroundImage = styled.img`
  justify-self: flex-end;
  width: 70%;
  margin-top: auto;
`;

const TryContentWrapper = styled.div`
  margin: 0 20% 0 8rem;
  align-self: center;

  @media (max-width: ${breakpoints.sm}px) {
    margin: 0 2rem;
    text-align: center;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 12rem 6rem 2rem 6rem;
  background-color: ${colors.accentDark};
  align-items: center;
  text-align: center;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    padding: 2rem;
    flex: 1;
  }
`;

const StyledTopText = styled.p`
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
`;
const StyledtopTextLeft = styled.p`
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  text-align: left;
  padding-top: 1rem;

  @media (max-width: ${breakpoints.sm}px) {
    text-align: center;
  }
`;



const SmallTextContainer = styled.div`
  justify-self: flex-end;
  margin-top: auto;

  @media (max-width: ${breakpoints.sm}px) {
    margin-top: 1rem;
  }
`;

const SmallText = styled.p`
  font-size: 14px;
  margin: 0;
  justify-self: flex-end;
  align-self: flex-end;
`;

const LoginPage: React.SFC = () => {
  return (
    <PageContainer>
      <TryContainer className="login_box">
        <LogoContainer href="https://www.ferritico.com/" target="_blank">
          <Logo src={logo} />
        </LogoContainer>
        <TryContentWrapper>
          <StyledtopTextLeft>Try ferritico</StyledtopTextLeft>
          
          <p>
          We're now offering a trial version of our SaaS, giving you the opportunity to experience our steel simulation modules firsthand. Explore your options:</p>
<ul>
<li>Sign up and run the trial version to get a sneak peek into our suite of steel simulation modules.</li>
<li>Alternatively, contact our support team to gain access to the full suite and enjoy a comprehensive experience.</li>
</ul>
          <p>
            Contact
            us at contact@ferritico.com
          </p>
        </TryContentWrapper>
        <BackgroundImage src={backgroundFooterImage} />
      </TryContainer>
      <LoginContainer>
        <StyledTopText>Log in or sign up</StyledTopText>
        <p>
          To log in to an existing account or to create a new user account for
          an existing customer.
        </p>
        <Button width="10rem" onClick={auth0Client.signIn}>
          Sign up / Sign in
        </Button>
        <SmallTextContainer>
          <SmallText>Have questions?</SmallText>
          <SmallText>contact@ferritico.com</SmallText>
        </SmallTextContainer>
      </LoginContainer>
    </PageContainer>
  );
};

export default LoginPage;
