import * as React from 'react';
import Spinner from 'react-spinkit';
import * as Plotly from 'plotly.js';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';
import { SingleCalculationResultsTempering } from '../types/Calculation';
import Button from './form/Button';
import CollapseDiv from './Collapse';

const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
`;

// const ResultsWrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// `;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 64px;

  h3 {
    min-height: 14px;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 14px;
  }
`;

const ResultStatus = styled.div`
  font-size: 40px;
`;

const ResultsTable = styled.div`
  margin-bottom: 64px;
`;

const ResultsTableHead = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
`;

const ResultsTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const ResultsTableCol = styled.div`
  flex: 1;
`;

const renderHardness = ( hardness: any, index: number) => {
  const hrc = hardness[index];
  const hardnessUnit = hrc.toString().replace('m', '');

  return `${parseFloat(hardnessUnit).toFixed(2)} `;
};

interface Props {
  fetching: boolean;
  steelValues:any;
  results: SingleCalculationResultsTempering | null;
}

/* const renderResponsePerc = (perc: string | number) => {
  const normalizedPercAmount = perc.toString().replace('%', '');

  return `${normalizedPercAmount} %`;
}; */

const renderContent = (props: Props) => {
  function exportToTxt(){
    if (props.results && !props.fetching) {
      let data ='Composition: \n'+JSON.stringify(props.steelValues).slice(1,-1);;
      data = data +'\n\n\n\nHardness\nTemperature  \tHRC\n';
      const temps = props.results.Result.data.temp
      const hrcs=props.results.Result.data.hrc
      temps.forEach((element,i) => {
        data = data + parseFloat(element).toFixed(2) + '\t'+ parseFloat(hrcs[i]).toFixed(2)+'\t'+'\n'
      });

      const file = new Blob([data], { type: 'data:application/vnd.ms-word;charset=utf-8' });

      if ((window.navigator as any).msSaveOrOpenBlob){ // IE10+
        (window.navigator as any).msSaveOrOpenBlob(file, 'my_data.doc');
    }
  else { // Others
      const link = document.createElement('a');
      const url = URL.createObjectURL(file);
      link.href = url;
      link.download = 'my_data.doc';
      document.body.appendChild(link);
      link.click();
      setTimeout(e => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);  
      }, 0); 
      }
  }
}
  if (props.results && !props.fetching) {

    let divs = document.getElementById('tempdivs');
    if (divs !== null) {
    divs.style.display = "block";
    }

    const data =JSON.stringify(props.steelValues).slice(1,-1);
    const strarray = data.split(','); 
    const hardness = props.results.Result.data.hrc;

    return (
      <>

<div className="row">
        <div className="col-md-12 col-sm-12"><Header>Input         
  </Header></div>
  </div>

<div className="row">
  
  <div className="col-md-12 col-sm-12 mt-md-3 compoverflow">
   <ResultsTable id="inputcomp">
     <ResultsTableHead className="inputcomptable">
       <ResultsTableCol className="inputcompcell">Al</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">B</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">C</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Co</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Cr</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Cu</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Mn</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Mo</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">N</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Nb</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Ni</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">P</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">S</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Si</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Ti</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">V</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">W</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">HRC_0</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Time</ResultsTableCol>
     </ResultsTableHead>
     <ResultsTableRow>
     {
     strarray.map((dataInput: any) => (
        
       dataInput.split(':')[0] !=='"At"' && dataInput.split(':')[0] !=='"hrc"' && dataInput.split(':')[0] !=='"Temp"' && isNaN(dataInput) && dataInput.slice(-1) !==']' && dataInput.includes(':')?
         <ResultsTableCol>
           {dataInput.split(':')[1]}
         </ResultsTableCol>
         :
         ''
       
     ))}
     </ResultsTableRow>
   </ResultsTable>
   </div>
 
 </div>


      <div className="row">
        <div className="col-md-6 col-sm-12"><Header>Results         
  </Header></div>
    <div className="col-md-6 col-sm-12 md-3 mb-5">
    <Button width="45%" float="right" height="2rem" download={true} onClick={exportToTxt}>Export</Button>
    </div>
  </div>
  

        {/* <Placeholder>
          {props.results && props.results.Result.plot_image ? (
            <ResultsWrapper>
              <img
                style={{ width: '100%' }}
                src={`data:image/png;base64, ${props.results.Result.plot_image}`}
              />
            </ResultsWrapper>
          ) : null}
        </Placeholder> */}
        
        <CollapseDiv divcontent="Tempering Table" icon="table">
        <div>
        <div className="mt-md-3">
          <ResultsTable>
            <ResultsTableHead>
              <ResultsTableCol>Temp (°C)</ResultsTableCol>
              <ResultsTableCol>Hardness (HRC)</ResultsTableCol>
            </ResultsTableHead>
            {props.results.Result.data.temp.map((temps: any, index: number) => (
              
              <ResultsTableRow>
                <ResultsTableCol>
                  {parseFloat(temps).toFixed(2)}
                </ResultsTableCol>
                <ResultsTableCol>
                  {renderHardness(hardness, index || 0)}
                </ResultsTableCol>
              </ResultsTableRow>
            ))}
          </ResultsTable>
          </div>
        </div>
        </CollapseDiv>
      </>
    );
  } else {

    let divs = document.getElementById('tempdivs');
    if (divs !== null) {
    divs.style.display = "none";
    }
    
    return (
      <>
        <Header>Results</Header>
        <Placeholder>
          {props.fetching ? (
            <>
              <ResultStatus>
                <Spinner fadeIn="none" name="circle" />
              </ResultStatus>
              <h3>Simulating</h3>
            </>
          ) : (
            <>
              <ResultStatus>-</ResultStatus>
              <h3>Fill in your values</h3>
            </>
          )}
        </Placeholder>
      </>
    );
  }
};

const renderPlotly = (props: Props) =>{

  if (props.results && !props.fetching) {
  let tempData: any[];
  tempData = [];  


  let tempTrace = {};
  tempTrace = {
    x: props.results.Result.data.temp,
    y: props.results.Result.data.hrc,
    type: 'scatter',
    
    name: 'HRC',
    line: {
      color: '#D4213F',
      dash: 'dot',
      width: 2
    }
  };
  tempData.push(tempTrace);


  var tempLayout = {
    xaxis: { title: "Temperature °C"},
    yaxis: { title: "Hardness HRC" },
    showlegend: true,
    legend: {   
      y: -0.3,
      "orientation": "h" as const,
    },
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    // title: " Hardness ",
    
  };

  Plotly.newPlot("tempDiv", tempData, tempLayout)


  }
}

const SingleCalculationResultTempering = (props: Props) => {
  let finalHtml = <ContentWrapper>{renderContent(props)}</ContentWrapper> ; 
  renderPlotly(props);
  return finalHtml;
};

export default SingleCalculationResultTempering;
