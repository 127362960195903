import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
import ChemicalCompositionTempering from '../components/ChemicalCompositionTempering';
import SingleCalculationResultTemperingPlot from '../components/SingleCalculationResultsTemperingPlot';
import { initFetchSingleCalculation } from 'src/redux/modules/calculationTempering';
import CollapseDiv from '../components/Collapse';
import {
  SteelValuesTempering,
  SingleCalculationResultsTempering
} from 'src/types/Calculation';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

interface Props {
  steelValues:SteelValuesTempering;
  fetching: boolean;
  singleCalcResults: SingleCalculationResultsTempering | null;
  fetchSingleCalculation: (steelValues: SteelValuesTempering) => Promise<any>;
}

class SingleCalculationTempering extends React.Component<Props> {
  state = {steelValues:[]}  
  callbackFunction = (childData:SteelValuesTempering) => {
    this.setState({steelValues: childData})
  }
  
  public render() {
    const { fetchSingleCalculation, singleCalcResults, fetching } = this.props;
    
    return (
  <div className="row">
      <div className="col-md-4">
   <div className="card mb-4 box-shadow cardgrey">
    <div className="card-body">
        <ChemicalCompositionTempering
          onSubmitForm={fetchSingleCalculation}
          parentCallback = {this.callbackFunction}
          fetching={fetching}
        />
         </div>
    </div>
    </div>
    <div className="col-md-8">
     <div className="card mb-4 box-shadow cardgrey">
      <div className="card-body">
        <SingleCalculationResultTemperingPlot
          fetching={fetching}
          steelValues={this.state.steelValues}
          results={singleCalcResults}
        />

<ContentWrapper id="tempdivs" className="hidedivs pt-0 mt-0">

<CollapseDiv divcontent="Tempering Diagram" icon="diagram">         
      <div id="tempDiv"></div>
      </CollapseDiv>
      
      </ContentWrapper>

       </div>
      </div>
    </div>

  </div>
  );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculationTempering; // Bad type
  return { singleCalcResults, fetching };
};

// dispatch updates the state of an app
const mapDispatchToProps = (dispatch: any) => ({
  fetchSingleCalculation: (steelValues: SteelValuesTempering) =>
    dispatch(initFetchSingleCalculation(steelValues)) // initFetchSingleCalculation as an action creator
});

const SingleCalculationContainerTempering = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCalculationTempering);

export default SingleCalculationContainerTempering;
