import { loop, Loop } from 'redux-loop';

import { HttpError } from '../utils/HttpError';
import { runLoopFetch } from '../utils/loop';
import {
  SingleCalculationResultsCCTArbCR,
  SteelValuesArbCR,
  JobId
} from '../../types/Calculation';
import auth0Client from 'src/Auth';

export const BATCH_CALC_FETCH_START = 'BATCH_CALC_CCT_ARBCR_FETCH_START';
export const BATCH_CALC_FETCH_SUCCESS = 'BATCH_CALC_CCT_ARBCR_FETCH_SUCCESS';
export const BATCH_CALC_FETCH_FAILURE = 'BATCH_CALC_CCT_ARBCR_FETCH_FAILURE';
export const BATCH_CALC_POLL_SUCCESS =
  'BATCH_CALC_CCT_ARBCR_POLL_SUCCESS';
export const BATCH_CALC_POLL_FAILURE =
  'BATCH_CALC_CCT_ARBCR_POLL_FAILURE';

type CalculationAction =
  | { type: typeof BATCH_CALC_FETCH_START; steelValues: SteelValuesArbCR[] }
  | {
      type: typeof BATCH_CALC_FETCH_SUCCESS;
      payload: JobId[];
    }
    | { type: typeof BATCH_CALC_POLL_SUCCESS; 
      payload: SingleCalculationResultsCCTArbCR[];}
  | { type: typeof BATCH_CALC_FETCH_FAILURE; }
  | { type: typeof BATCH_CALC_POLL_FAILURE };

export interface CalculationState {
  singleCalcResults: SingleCalculationResultsCCTArbCR[] | null;
  fetching: boolean;
}

const initialState: CalculationState = {
  fetching: false,
  singleCalcResults: null
};



const fetchBatchCalculation = async (
  steelValues: SteelValuesArbCR[]
): Promise<JobId[]> => 
{
  
  let jobIterate:JobId;
  let i = Object.keys(steelValues).length;
  
  let jobs:JobId[] = [];

  while(i--){ 
  steelValues[i]['crs'] = steelValues[i]['crsstring'].split(',').map(Number);
  
  const res = await fetch(`/api/calculation/singleCCTArbCR`, {
    body: JSON.stringify(steelValues[i]),
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${auth0Client.getIdToken()}`,
      'content-type': 'application/json'
    },
    method: 'POST'
  });

 

  jobIterate = await res.json();
  jobs.push(jobIterate) ;

  }
  return jobs;
};


const wait = (ms:number) => new Promise(res => setTimeout(res, ms));

const fetchBatchResult = async (
  jobid: JobId[]
): Promise<SingleCalculationResultsCCTArbCR[]> => {
  
  let jobIterate:SingleCalculationResultsCCTArbCR;
  let i = Object.keys(jobid).length;
  let results:SingleCalculationResultsCCTArbCR[] = [];


  while(i--){
  const res = fetchSingleResult(jobid[i], 0);



jobIterate = await res;
results.push(jobIterate) ;
  

}

  return results;
};



const fetchSingleResult = async (
  jobid: JobId, count: number
): Promise<SingleCalculationResultsCCTArbCR> => {
  
  const jid = Number(jobid.id);
  const res = await fetch(`/api/calculation/singleCCTArbCRResult/`+jid, {
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${auth0Client.getIdToken()}`,
      'content-type': 'application/json'
    },
    method: 'GET'
  });

  if (!res.ok) {
    throw new HttpError({ status: res.status, message: res.statusText });
  }
  if (res.status !== 200) {
    if (count++ < 80) {
      await wait(2000);
      return fetchSingleResult(jobid, count);
    } else {
      throw new Error('max retries reached');
    };
  }
  
  return res.json();
};

/**
 * Redux-action creator.
 *
 * @param steelValues the alloy components etc.
 */
export const initFetchBatchCalculation = (steelValues: SteelValuesArbCR[]) => ({
  steelValues,
  type: BATCH_CALC_FETCH_START
});

/**
 * Redux-reducer.
 */
export function calculation(
  state: CalculationState = initialState,
  action: CalculationAction
): CalculationState | Loop<CalculationState, any> {
  switch (action.type) {
    case BATCH_CALC_FETCH_START:
      return loop(
        { ...state, fetching: true },
        runLoopFetch(
          fetchBatchCalculation,
          BATCH_CALC_FETCH_SUCCESS,
          BATCH_CALC_FETCH_FAILURE,
          [action.steelValues]
        )
      );

      case BATCH_CALC_FETCH_FAILURE:
        return { ...state, fetching: false, singleCalcResults: null };
  
      case BATCH_CALC_FETCH_SUCCESS:
        return loop(
          { ...state, fetching: true },
          runLoopFetch(
            fetchBatchResult,
            BATCH_CALC_POLL_SUCCESS,
            BATCH_CALC_POLL_FAILURE,
            [action.payload, 0]
          )
        );
  
      case BATCH_CALC_POLL_SUCCESS:
        // What is in ...state? isn't it just fetching and singleCalcResults?
        return { ...state, fetching: false, singleCalcResults: action.payload };
  
      case BATCH_CALC_POLL_FAILURE:
        return { ...state, fetching: false, singleCalcResults: null };
  
    default:
      return state;
  }
}
