import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../redux/modules';
//import auth0Client from 'src/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFireAlt, faCogs, faExchangeAlt, faHeartBroken } from '@fortawesome/free-solid-svg-icons'
import CollapseDivDash from '../components/CollapseDivDash';


interface Props {
  fetching: boolean;

}


class Dashboard extends React.Component<Props, never> {
  public render() {
    const {  } = this.props;

    return (
      
      <>
      <div className="row">
        <div className="col-md-6">
     <div className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body">
      
      
  <FontAwesomeIcon icon={faExchangeAlt} color="orange" className="dashicon"/>  
  <CollapseDivDash divcontent="Phase Transformations">
  <div className="row">
        <div className="col-md-6">
        <a href="/hacct" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body btn-dashboard">
      High Alloy Continuous Cooling Transformation
      </div>
    </a>
    </div>
        <div className="col-md-6">
     <a href="/ttt" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body btn-dashboard">
      Isothermal Transformation
      </div>
    </a>
    </div>
    </div>
  <div className="row">
        <div className="col-md-6">
        <a href="/cct" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body btn-dashboard">
      Low Alloy Continuous Cooling Transformation
      </div>
    </a>
    </div>
        <div className="col-md-6">
     <a href="/batchcct" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body btn-dashboard">
      Low Alloy Batch Continuous Cooling Transformation
      </div>
    </a>
    </div>
    </div>
      <div className="row">
        <div className="col-md-6">
     <a href="/tempering" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body btn-dashboard">
      Tempering
      </div>
    </a>
    </div>
        <div className="col-md-6">
     <a href="/hardenability" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body btn-dashboard">
      Hardenability
      </div>
    </a>
    </div>
    </div>
      <div className="row">
        {/* <div className="col-md-6">
     <a href="/tempering" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body btn-dashboard">
      API Key Bearer ${auth0Client.getIdToken()}
      </div>
    </a>
    </div> */}
        
    </div>
      </CollapseDivDash>
      
    </div>
    </div>
    </div>


    <div className="col-md-6">
     <div className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body">
      
  <FontAwesomeIcon icon={faFireAlt} color="orange" className="dashicon"/>  
  <CollapseDivDash divcontent="Heat Treatment">
      <div className="row">
      <div className="col-md-6">
        <a href="/thermal" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="btn-dashboard card-body">
      Thermal Properties
      </div>
    </a>
    </div>
        <div className="col-md-6">
     <a href="/equilibrium" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body btn-dashboard">
      Equilibrium Calculations
      <i className="ml-4 lb-sm">beta</i>
      </div>
    </a>
    </div>
    </div>
    <div className="row">
        <div className="col-md-6">
        <a href="/grainsize" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="btn-dashboard card-body">
      Austenite grain growth
      </div>
    </a>
    </div>
    </div>
    
      </CollapseDivDash>
      </div>
      </div>
    </div>
    </div>
    

        <div className="row">
        <div className="col-md-6">
     <div className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body">
      
      
  <FontAwesomeIcon icon={faCogs} color="orange" className="dashicon"/>  
      <CollapseDivDash divcontent="Miscellaneuous Properties">
      <div className="row">
        
        <div className="col-md-6">
        <a href="/sfe" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="btn-dashboard card-body">
      Stacking Fault Energy
      </div>
    </a>
    </div>
    
        <div className="col-md-6">
        <a href="/di" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="btn-dashboard card-body">
      Deformation Induced Martensitic Transformation
      <i className="ml-4 lb-sm">beta</i>
      </div>
    </a>
    </div>
        
    </div>
      </CollapseDivDash>
    </div>
    </div>
    </div>
    <div className="col-md-6">
     <div className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="card-body">
      <FontAwesomeIcon icon={faHeartBroken} color="orange" className="dashicon"/>
      <CollapseDivDash divcontent="Degradation">
      <div className="row">
        <div className="col-md-6">
        <a href="/corr" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="btn-dashboard card-body">
      Corrosion
      <i className="ml-4 lb-sm">beta</i>
      </div>
    </a>
    </div>
        <div className="col-md-6">
        <a href="/embrittlement" className="card mb-4 box-shadow cardgrey modulecenter text-decoration-none">
      <div className="btn-dashboard card-body">
      Embrittlement
      <i className="ml-4 lb-sm">beta</i>
      </div>
    </a>
    </div>
    </div>
     
      </CollapseDivDash>
      </div>
      </div>
    </div>
    </div>
    
    </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { singleCalcResults, fetching } = state.calculation;
  return { singleCalcResults, fetching };
};



const DashboardContainer = connect(mapStateToProps)(
  Dashboard
);

export default DashboardContainer;