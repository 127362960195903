import * as React from 'react';
import Spinner from 'react-spinkit';
import { colors } from 'src/shared/themeStyles';
import styled from 'styled-components';
import * as Plotly from 'plotly.js';
import { SingleCalculationResultsTTT } from '../types/Calculation';
import Button from '../components/form/Button'; 
import CollapseDiv from './Collapse';


const ContentWrapper = styled.div`
  flex: 1;
  padding: 1.5rem 2rem;
  color: ${colors.accentDark};
`;

const Header = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ResultsTable = styled.div`
  margin-bottom: 64px;
`;

const ResultsTableHead = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
`;

const ResultsTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const ResultsTableCol = styled.div`
  flex: 1;
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 64px;

  h3 {
    min-height: 14px;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 14px;
  }
`;

const ResultStatus = styled.div`
  font-size: 40px;
`;


interface Props {
  fetching: boolean;
  steelValues:any;
  results: SingleCalculationResultsTTT | null;
}


const renderContent = (props: Props) => {

  
  function parseForZero(tttData: string){
  
    if(parseFloat(tttData) === 0){
      return ' -'
    }else{
      return parseFloat(tttData).toFixed(2);
    }

  }

  function exportToTxt(){
    if (props.results && !props.fetching) {
      let data ='Composition: \n'+JSON.stringify(props.steelValues).slice(1,-1);;
      data = data +'\n\n\n\nTTT data:\nTemp  \tF_s  \t\tP_s  \t\tP_f  \t\tB_s  \t\tB_f  \n';
      const Ts = props.results.Result.Ts
    const FeS = props.results.Result.FeS
    const PS = props.results.Result.PS
    const Pf = props.results.Result.PF
    const Bs = props.results.Result.BS
    const Bf = props.results.Result.BF
    Ts.forEach((element,i) => {
      data = data + parseFloat(element).toFixed(2) + ' \t'+ parseForZero(FeS[i])+'\t'+' \t'+ parseForZero(PS[i])+'\t'+' \t'+ parseForZero(Pf[i])+'\t'+' \t'+ parseForZero(Bs[i])+'\t'+' \t'+ parseForZero(Bf[i])+'\t'+'\n'
    }); 

    data = data +'\n\n\n\nTTT phase fractions(%): \nTemp  \tF_10  F_50  F_90  P_10  P_50  P_90  B_10  B_50  B_90 \n';
      
    const F10 = props.results.Result.F_10p
    const F50 = props.results.Result.F_50p
    const F90 = props.results.Result.F_90p
    const P10 = props.results.Result.P_10p
    const P50 = props.results.Result.P_50p
    const P90 = props.results.Result.P_90p
    const B10 = props.results.Result.B_10p
    const B50 = props.results.Result.B_50p
    const B90 = props.results.Result.B_90p
    Ts.forEach((element,i) => {
      data = data + parseForZero(element) + ' \t'+ parseForZero(F10[i])+'\t'+parseForZero(F50[i])+'\t'+parseForZero(F90[i])+'\t'+parseForZero(P10[i])+'\t'+parseForZero(P50[i])+'\t'+parseForZero(P90[i])+'\t'+parseForZero(B10[i])+'\t'+parseForZero(B50[i])+'\t'+parseForZero(B90[i])+'\t'+'\n'
    }); 
      
      const file = new Blob([data], { type: 'data:application/vnd.ms-word;charset=utf-8' });

      if ((window.navigator as any).msSaveOrOpenBlob){ // IE10+
        (window.navigator as any).msSaveOrOpenBlob(file, 'my_data.doc');
    }
  else { // Others
      const link = document.createElement('a');
      const url = URL.createObjectURL(file);
      link.href = url;
      link.download = 'my_data.doc';
      document.body.appendChild(link);
      link.click();
      setTimeout(e => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);  
      }, 0); 
      }
  }
}



  if (props.results && !props.fetching) {
/*     const evalData = JSON.parse(props.results.export_file);
    console.log(evalData); */

    let divs = document.getElementById('tttdivs');
    if (divs !== null) {
    divs.style.display = "block";
    }

    const data =JSON.stringify(props.steelValues).slice(1,-1);
    const strarray = data.split(','); 

    

    
    return (
      <>
<div className="row">
        <div className="col-md-12 col-sm-12"><Header>Input         
  </Header></div>
  </div>

<div className="row">
  
  <div className="col-md-12 col-sm-12 mt-md-3 compoverflow">
   <ResultsTable id="inputcomp">
     <ResultsTableHead className="inputcomptable">
       <ResultsTableCol className="inputcompcell">Al</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">B</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">C</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Co</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Cr</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Cu</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Mn</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Mo</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">N</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Nb</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Ni</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">P</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">S</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Si</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Sn</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Ti</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">V</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">W</ResultsTableCol>
       <ResultsTableCol className="inputcompcell">Aus temp</ResultsTableCol>
     </ResultsTableHead>
     <ResultsTableRow>
     {
     strarray.map((dataInput: any) => (
        
       dataInput.split(':')[0] !=='"At"' && dataInput.split(':')[0] !=='"As"' && dataInput.split(':')[0] !=='"Zr"' && dataInput.split(':')[0] !=='"function"'?
         <ResultsTableCol>
           {dataInput.split(':')[1]}
         </ResultsTableCol>
         :
         ''
       
     ))}
     </ResultsTableRow>
   </ResultsTable>
   </div>
 
 </div>


      <div className="row">
        <div className="col-md-6 col-sm-12"><Header>Results         
  </Header></div>
  {  <div className="col-md-6 col-sm-12 md-3 mb-5">
  <Button width="45%" float="right" height="2rem" download={true} onClick={exportToTxt}>Export</Button>
  </div> } 
  </div>

  <CollapseDiv divcontent="TTT & Hardness" icon="staticImg">
        <Placeholder>
          {props.results && props.results.Result.plot_image ? (
            <ResultsWrapper>
              <img
                style={{ width: '100%' }}
                src={`data:image/png;base64, ${props.results.Result.plot_image}`}
              />
            </ResultsWrapper>
          ) : null}
        </Placeholder>
        </CollapseDiv>








      </>
    );
  } else {

    let divs = document.getElementById('tttdivs');
    if (divs !== null) {
    divs.style.display = "none";
    
    }

    return (
      <>



        <Header>Results</Header>

     

        <Placeholder>
          {props.fetching ? (
            <>
              <ResultStatus>
                <Spinner fadeIn="none" name="circle" />
              </ResultStatus>
              <h3>Simulating</h3>
            </>
          ) : (
            <>
              <ResultStatus>-</ResultStatus>
              <h3>Fill in your values</h3>
            </>
          )}
        </Placeholder>
      </>
    );
  }
};

const renderPlotly = (props: Props) =>{

  if (props.results && !props.fetching) {
  let tttData: any[];
  tttData = [];  


  let c_fs:any = []
  let t_fs:any = []
  
  const fes_s = props.results.Result.FeS;

  props.results.Result.Ts.forEach((element,i) => {
    if(parseFloat(fes_s[i]) !== 0){
      c_fs[i] = Math.pow(10,parseFloat(fes_s[i]))
      t_fs[i] = parseFloat(element)
    }
    
  }); 


    let FsTrace = {};
    FsTrace = {
      x: c_fs,
      y: t_fs,
      type: 'scatter',
      
      name: 'FeS',
      line: {
        color: '#001E44',
        width: 2
      }
    };
    tttData.push(FsTrace);


    let c_ps:any = []
  let t_ps:any = []
  
  const ps_s = props.results.Result.PS;

  props.results.Result.Ts.forEach((element,i) => {
    if(parseFloat(ps_s[i]) !== 0){
      c_ps[i] = Math.pow(10,parseFloat(ps_s[i]))
      t_ps[i] = parseFloat(element)
    }
    
  });
console.log(ps_s);
console.log(c_ps);
    let PsTrace = {};
    PsTrace = {
      x: c_ps,
      y: t_ps,
      type: 'scatter',
      
      name: 'Ps',
      line: {
        color: '#2E05E9',
        width: 2
      }
    };
    tttData.push(PsTrace);


    
    let c_pe:any = []
  let t_pe:any = []
  
  const pe_s = props.results.Result.PF;

  props.results.Result.Ts.forEach((element,i) => {
    if(parseFloat(pe_s[i]) !== 0){
      c_pe[i] = Math.pow(10,parseFloat(pe_s[i]))
      t_pe[i] = parseFloat(element)
    }
    
  });

    let PeTrace = {};
    PeTrace = {
      x: c_pe,
      y: t_pe,
      type: 'scatter',
      
      name: 'Pf',
      line: {
        color: '#838FEC',
        width: 2
      }
    };
    tttData.push(PeTrace);


    let c_bs:any = []
  let t_bs:any = []
  
  const bs_s = props.results.Result.BS;

  props.results.Result.Ts.forEach((element,i) => {
    if(parseFloat(bs_s[i]) !== 0){
      c_bs[i] = Math.pow(10,parseFloat(bs_s[i]))
      t_bs[i] = parseFloat(element)
    }
    
  });

    let BsTrace = {};
    BsTrace = {
      x:  c_bs,
      y: t_bs,
      type: 'scatter',
      
      name: 'Bs',
      line: {
        color: '#D4213F',
        width: 2
      }
    };
    tttData.push(BsTrace);


    
    let c_bf:any = []
  let t_bf:any = []
  
  const bf_s = props.results.Result.BF;

  props.results.Result.Ts.forEach((element,i) => {

    if(parseFloat(bf_s[i]) !== 0){
      c_bf[i] = Math.pow(10,parseFloat(bf_s[i]))
      t_bf[i] = parseFloat(element)
    }
    
  });


    let BfTrace = {};
    BfTrace = {
      x: c_bf,
      y: t_bf,
      type: 'scatter',
      
      name: 'Bf',
      line: {
        color: '#D28994',
        width: 2
      }
    };
    tttData.push(BfTrace);
    
    
        
    let c_f10:any = []
  let t_f10:any = []
  
  const f10_s = props.results.Result.F_10p;

  props.results.Result.Ts.forEach((element,i) => {
    if(parseFloat(f10_s[i]) !== 0){
      c_f10[i] = Math.pow(10,parseFloat(f10_s[i]))
      t_f10[i] = parseFloat(element)
    }
    
  });

    let F10Trace = {};
    F10Trace = {
      x: c_f10,
      y: t_f10,
      type: 'scatter',
      
      name: 'F10',
      line: {
        color: '#001E44',
        dash: 'dot',
        width: 2
      }
    };


    tttData.push(F10Trace);
    
    
    let c_f50:any = []
  let t_f50:any = []
  
  const f50_s = props.results.Result.F_50p;

  props.results.Result.Ts.forEach((element,i) => {
    if(parseFloat(f50_s[i]) !== 0){
      c_f50[i] = Math.pow(10,parseFloat(f50_s[i]))
      t_f50[i] = parseFloat(element)
    }
    
  });
    let F50Trace = {};
    F50Trace = {
      x: c_f50,
      y: t_f50,
      type: 'scatter',
      
      name: 'F50',
      line: {
        color: '#001E44',
        dash: 'dot',
        width: 2
      }
    };
    tttData.push(F50Trace);
    
    
    let c_f90:any = []
  let t_f90:any = []
  
  const f90_s = props.results.Result.F_90p;

  props.results.Result.Ts.forEach((element,i) => {
    if(parseFloat(f90_s[i]) !== 0){
      c_f90[i] = Math.pow(10,parseFloat(f90_s[i]))
      t_f90[i] = parseFloat(element)
    }
    
  });
    
    
    let F90Trace = {};
    F90Trace = {
      x: c_f90,
      y: t_f90,
      type: 'scatter',
      
      name: 'F90',
      line: {
        color: '#001E44',
        dash: 'dot',
        width: 2
      }
    };
    tttData.push(F90Trace);


        
    let c_p10:any = []
  let t_p10:any = []
  
  const p10_s = props.results.Result.P_10p;

  props.results.Result.Ts.forEach((element,i) => {
    if(parseFloat(p10_s[i]) !== 0){
      c_p10[i] = Math.pow(10,parseFloat(p10_s[i]))
      t_p10[i] = parseFloat(element)
    }
    
  });

    let P10Trace = {};
    P10Trace = {
      x: c_p10,
      y: t_p10,
      type: 'scatter',
      
      name: 'P10',
      line: {
        color: '#2E05E9',
        dash: 'dot',
        width: 2
      }
    };
    tttData.push(P10Trace);
    
    
    let c_p50:any = []
    let t_p50:any = []
    
    const p50_s = props.results.Result.P_50p;
  
    props.results.Result.Ts.forEach((element,i) => {
      if(parseFloat(p50_s[i]) !== 0){
        c_p50[i] = Math.pow(10,parseFloat(p50_s[i]))
        t_p50[i] = parseFloat(element)
      }
      
    });

    let P50Trace = {};
    P50Trace = {
      x: c_p50,
      y: t_p50,
      type: 'scatter',
      
      name: 'P50',
      line: {
        color: '#2E05E9',
        dash: 'dot',
        width: 2
      }
    };
    tttData.push(P50Trace);
    
    
    let c_p90:any = []
    let t_p90:any = []
    
    const p90_s = props.results.Result.P_90p;
  
    props.results.Result.Ts.forEach((element,i) => {
      if(parseFloat(p90_s[i]) !== 0){
        c_p90[i] = Math.pow(10,parseFloat(p90_s[i]))
        t_p90[i] = parseFloat(element)
      }
      
    });
    
    let P90Trace = {};
    P90Trace = {
      x: c_p90,
      y: t_p90,
      type: 'scatter',
    
      name: 'P90',
      line: {
        color: '#2E05E9',
        dash: 'dot',
        width: 2
      }
    };
    tttData.push(P90Trace);


    let c_b10:any = []
    let t_b10:any = []
    
    const b10_s = props.results.Result.B_10p;
  
    props.results.Result.Ts.forEach((element,i) => {
      if(parseFloat(b10_s[i]) !== 0){
        c_b10[i] = Math.pow(10,parseFloat(b10_s[i]))
        t_b10[i] = parseFloat(element)
      }
      
    });

    let B10Trace = {};
    B10Trace = {
      x: c_b10,
      y: t_b10,
      type: 'scatter',
      
      name: 'B10',
      line: {
        color: '#D4213F',
        dash: 'dot',
        width: 2
      }
    };
    tttData.push(B10Trace);

    
    let c_b50:any = []
    let t_b50:any = []
    
    const b50_s = props.results.Result.B_50p;
  
    props.results.Result.Ts.forEach((element,i) => {
      if(parseFloat(b50_s[i]) !== 0){
        c_b50[i] = Math.pow(10,parseFloat(b50_s[i]))
        t_b50[i] = parseFloat(element)
      }
      
    });
    
    let B50Trace = {};
    B50Trace = {
      x: c_b50,
      y: t_b50,
      type: 'scatter',
      
      name: 'B50',
      line: {
        color: '#D4213F',
        dash: 'dot',
        width: 2
      }
    };
    tttData.push(B50Trace);

    
    let c_b90:any = []
    let t_b90:any = []
    
    const b90_s = props.results.Result.B_90p;
  
    props.results.Result.Ts.forEach((element,i) => {
      if(parseFloat(b90_s[i]) !== 0){
        c_b90[i] = Math.pow(10,parseFloat(b90_s[i]))
        t_b90[i] = parseFloat(element)
      }
      
    });

    let B90Trace = {};
    B90Trace = {
      x:  c_b90,
      y: t_b90,
      type: 'scatter',
      
      name: 'B90',
      line: {
        color: '#D4213F',
        dash: 'dot',
        width: 2
      }
    };
    tttData.push(B90Trace);



/*     let MsTrace = {};
    MsTrace = {
      x: props.results.Result.,
      y: props.results.Result.Ts,
      type: 'scatter',
      
      name: 'Bf',
      line: {
        color: '#D28994',
        width: 2
      }
    };
    tttData.push(BfTrace); */


    var tttLayout = {
      xaxis: { title: "Time sec", type: "log" as const, range: [-1, 4]},
      yaxis: { title: "Temperature °C"},
      showlegend: true,
      legend: {   
        y: -0.3,
        "orientation": "h" as const,
      },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      // title: " Hardness ",
      
    };
  
    Plotly.newPlot("tttDiv", tttData, tttLayout)

    
  }
}



const SingleCalculationResultTTT = (props: Props) => {
  let finalHtml = <ContentWrapper>{renderContent(props)}</ContentWrapper> ; 
  renderPlotly(props);
  return finalHtml;
};

export default SingleCalculationResultTTT;
