import { loop, Loop } from 'redux-loop';

import { HttpError } from '../utils/HttpError';
import { runLoopFetch } from '../utils/loop';
import { SingleCalculationResults, SteelValues } from '../../types/Calculation';
import auth0Client from 'src/Auth';

export const SINGLE_CALC_FETCH_START = 'SINGLE_CALC_FETCH_START';
export const SINGLE_CALC_FETCH_SUCCESS = 'SINGLE_CALC_FETCH_SUCCESS';
export const SINGLE_CALC_FETCH_FAILURE = 'SINGLE_CALC_FETCH_FAILURE';

type CalculationAction =
  | { type: typeof SINGLE_CALC_FETCH_START; steelValues: SteelValues }
  | {
      type: typeof SINGLE_CALC_FETCH_SUCCESS;
      payload: SingleCalculationResults;
    }
  | { type: typeof SINGLE_CALC_FETCH_FAILURE };

export interface CalculationState {
  singleCalcResults: SingleCalculationResults | null;
  fetching: boolean;
}

const initialState: CalculationState = {
  fetching: false,
  singleCalcResults: null
};

const fetchSingleCalculation = async (
  steelValues: SteelValues
): Promise<SingleCalculationResults> => {
  const res = await fetch(`/api/calculation/single`, {
    body: JSON.stringify(steelValues),
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${auth0Client.getIdToken()}`,
      'content-type': 'application/json'
    },
    method: 'POST'
  });

  if (!res.ok) {
    console.log(res);
    throw new HttpError({ status: res.status, message: res.statusText });
  }

  return res.json();
};

/**
 * Redux-action creator.
 *
 * @param steelValues the alloy components etc.
 */
export const initFetchSingleCalculation = (steelValues: SteelValues) => ({
  steelValues,
  type: SINGLE_CALC_FETCH_START
});

/**
 * Redux-reducer.
 */
export function calculation(
  state: CalculationState = initialState,
  action: CalculationAction
): CalculationState | Loop<CalculationState, any> {
  switch (action.type) {
    case SINGLE_CALC_FETCH_START:
      return loop(
        { ...state, fetching: true },
        runLoopFetch(
          fetchSingleCalculation,
          SINGLE_CALC_FETCH_SUCCESS,
          SINGLE_CALC_FETCH_FAILURE,
          [action.steelValues]
        )
      );

    case SINGLE_CALC_FETCH_SUCCESS:
      // What is in ...state? isn't it just fetching and singleCalcResults?
      return { ...state, fetching: false, singleCalcResults: action.payload };

    case SINGLE_CALC_FETCH_FAILURE:
      return { ...state, fetching: false, singleCalcResults: null };

    default:
      return state;
  }
}
