import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import auth0Client from '../Auth';

class AuthCallback extends React.Component<RouteComponentProps<any>, any> {
  async componentWillMount() {
    const { history } = this.props;

    try {
      await auth0Client.handleAuthentication();
      history.replace('/');
    } catch (e) {
      history.replace('/');
    }
  }

  render() {
    return <div />;
  }
}

const AuthCallbackWithRouter = withRouter(AuthCallback);

export default AuthCallbackWithRouter;
